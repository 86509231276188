// Adding localization to vue app
import Vue from "vue";
import VueI18n from "vue-i18n";
import { se, en, dk, no } from "./ValidateMessages";

Vue.use(VueI18n);

const messages = {
	en: {
		categoryName: "Category Name",
		prices_given_ink_vat: "Prices Given Including VAT",
		apply_prices_given_ink_vat:
			"If set to True, VAT will be included in Article Price.",
		current: "Previous: ",
		discounted: "Discounted",
		discountedValue: "Discount Value",
		discountType: "Discount Type",
		discountAmount: "Amount",
		discountPercentage: "Percentage",
		fields: {
			username: "Username",
			emailAddress: "E-mail Address",
			email: "Email",
			phone_number: "Phone Number",
			password: "Password",
			domain: "Domain",
			firstName: "First Name",
			lastName: "Last Name",
			companyName: "Company Name",
			repeatPassword: "Repetera lösenordet",
			confirmNewPassword: "Confirm Password",
		},
		validation: en.messages,
		defaultLang: "English",
		onboardHeader: "Välkommen till B2B Portal",
		welcomeEntry: "One stop shop för Grossister och Återförsäljare",
		serviceStart: "Do you want to start up the service",
		customerPortal: "Till Kundportal",
		wouldConnect: "Önskar ni starta upp tjänsten",
		onBoardSeller: "Move on",
		onBoardBuyer: "Återförsäljare",
		toDestination: "B2B portal",
		connectNow: "Ja, installera appen",
		signUpText:
			"Skapar upp konto och förbereder för authentisering, ett ögonblick",
		proceedText:
			"The next step is to choose the subscription type and create an account so you can get started right away",
		codeAlreadyUse: "The authorization code is already in use",
		username: "Username",
		loginHeader: "Customer Portal",
		forgetPassword: "Forgot Password",
		resetPassword: "Reset Password",
		newPassword: "New Password",
		confirmNewPassword: "Confirm Password",
		login: "Login here",
		loginSuccess: "Login successful, welcome",
		loginError: "Unable to login with provided credentials",
		fieldRequired: "All fields are required",
		emailAddress: "Email Address",
		password: "Password",
		customerAlready: "Already a Customer",
		resettingPassword: "Resetting",
		passwordGreater: "Password should be greater than 6",
		passwordNotMatch: "Password do not match",
		accountCreated: "Account created successfully",
		serverError: "Oops...Error from server",
		repeatPassword: "Repeat Password",
		setDomain: "Set Domain for your Portal",
		createAccount: "Create your account to get started",
		signUp: "Sign Up",
		notCustomer: "Not a Customer",
		creatingAccount: "Creating Account...",
		inviteCode: "Invite Code",
		invite: "Invite",
		accept: "Accept",
		reject: "Reject",
		acceptStatement: "Accept user request?",
		rejectStatement: "Reject user request?",
		articles: "Articles",
		confirmPassword: "Confirm Password",
		chooseShop: "Choose a Shop",
		dashboard: "Dashboard",
		orderHistory: "Order History",
		invoiceHistory: "Invoice History",
		orders: "Order History",
		customerName: "Customer Name",
		orderNumber: "Order Number",
		orderType: "Order Type",
		type: "Type",
		orderDate: "Order Date",
		orderStatus: "Order Status",
		orderSummary: "Total",
		reference: "Reference",
		fileManagement: "File management",
		productImageUpload: "Product Image Upload",
		search: "Search",
		subscription: "Subscription",
		show: "Showing",
		invoice_: "Invoice",
		invoice: "Invoice History",
		invoiceNumber: "Invoice Number",
		invoiceDate: "Invoice Date",
		invoiceStatus: "Invoice Status",
		invoiceSummary: "Invoice Summary",
		product: "Product",
		youHave: "You have",
		itemsInCart: "item(s) in your cart",
		subTotal: "Subtotal",
		checkout: "Checkout",
		checkoutCustomization: "Checkout Customization",
		logout: "Logout",
		brand: "Brand",
		totalOrdered: "Total Ordered",
		remove: "Remove",
		totalAmountIncVat: "Total Amount Inc Vat",
		totalAmountExVat: "Total Amount Ex Vat",
		vatAmount: "Vat Amount",
		deliveryDetails: "Delivery Details",
		deliveryName: "Delivery Name",
		deliveryAddress: "Delivery Address",
		deliveryZipCode: "Delivery Zipcode",
		deliveryCity: "Delivery City",
		deliveryCountry: "Delivery Country",
		email: "Email",
		ourReference: "Our Reference",
		ourOrderNumber: "Our Order Number",
		message: "Message",
		sameBillingAddress: "Use same as Billing Address",
		placeOrder: "Place Order",
		pleaseWait: "Please wait",
		disposableQty: "Quantity",
		itemRemove: "item remove from cart!",
		itemAdd: "item(s) added to cart!",
		orderConfirmation: "Order Confirmation",
		thankYouOrder: "Thank you for your order!",
		proceedOrder:
			"will proceed to process the order and an confirmation is sent to",
		date: "Date",
		amount: "Amount",
		seller: "Seller",
		buyer: "Buyer",
		customerSettings: "Customer Settings",
		syncCustomers: "Sync Customers",
		users: "Users",
		companySettings: "Company Settings",
		totalOrderToday: "Total Orders Today",
		totalOrderMonth: "Total Orders this Month",
		totalCustomerVisit: "Total Customers visited Today",
		averageOrderAmount: "Average Order Amount",
		totalRevenue: "Total B2B Revenue",
		today: "Today",
		revenueSales: "Revenue Sales",
		bestSellingArticle: "Best selling articles",
		totalInvitedCustomers: "Total Invited Customers",
		totalPublishArticle: "Total Published Articles",
		totalSalesMonth: "Total Sales this Month",
		customerHighestPurchase: "Customers with highest purchases",
		articleSetting: "Article Settings",
		articleNumber: "Article#",
		description: "Description",
		price: "Price",
		priceListSelection: "Select Pricelist",
		thumnails: "Thumnails",
		inviteCustomer: "Invite Customers",
		number: "Numbers",
		organizationNumber: "Organization Number",
		category: "Category",
		status: "Status",
		notInvited: "Not Invited",
		pendingInvitation: "Pending",
		waitingInvite: "Waiting",
		accepted: "Accepted",
		confirmed: "Confirmed",
		createUserCategory: "Create User Category",
		customerCategoryName: "Customer Category Name",
		accountNumber: "Account Number",
		costCenter: "Cost Center",
		addUser: "Add Users",
		firstName: "Firstname",
		lastName: "Lastname",
		addBtn: "Add",
		adding: "Adding",
		manageUser: "Manage Users",
		userSetting: "User Settings",
		companyName: "Company Name",
		companyNumber: "Company Number",
		zipCode: "Zip Code",
		city: "City",
		phoneNumber: "Phone Number",
		billingAddress: "Billing Address",
		invoiceEmail: "Invoice Email",
		shopDomain: "Shop Domain URL",
		brandName: "Brand Name",
		invoiceName: "Invoice",
		selectPricelist: "Select Pricelist",
		postInvoice: "Post Invoice to Fortnox",
		chooseCompanyLogo: "Choose Company Logo",
		applyCustomerPricelist: "Apply Customer Specific Pricelist",
		choose: "Customer specific",
		update: "Update",
		updating: "Updating",
		updateShopUsers: "Update Shop Users",
		inviteEndCustomer: "Invite End Customer",
		customerEmail: "Customer Email",
		customerNumber: "Customer Number",
		selectCategory: "Select Category",
		inviting: "Inviting",
		createCustomerCategory: "Create Customer Category",
		selectCostCenter: "Select Cost Center",
		create: "Create",
		creating: "Creating",
		uploadLogo: "Upload Logo",
		chooseFile: "Choose a file",
		cropping: "Cropping",
		crop: "Crop",
		finishUpload: "Finished Upload",
		updateProductImage: "Update Product Images",
		articleDetail: "Article Details",
		quantity: "Quantity",
		dropImageFiles: "Drop file here or",
		clickToUpload: "click to upload",
		fileFormat: "files with a size less than 500kb",
		maximumImages: "Maximum of 4 images",
		uploading: "Uploading",
		updateArticle: "Update Articles",
		UpdateArticleCategory: "Update articles category",
		articleDescription: "Article Description",
		articleName: "Article Name",
		articleVideoURL: "Product Video URL",
		updateCustomerInfo: "Update Customer information",
		articleImage: "Article Image",
		removeUser: "Are you sure you want to remove",
		continue: "Continue",
		warning: "Warning",
		successfully: "successfully",
		updatedSuccess: "Updated successfully",
		CustomerSpecific: "Customer specific",
		removeSuccessful: "removed successfully",
		updateUserSuccess: "Updated User successfully",
		enterValidPrice: "Please enter a valid price",
		enterValidQty: "Please enter a valid quantity",
		articleUpdateSuccess: "Article information updated successfully",
		removeImage: "Are you sure you want to remove this image",
		imageRemoveSuccess: "Image removed successfully",
		yes: "Yes",
		no: "No",
		cancel: "Cancel",
		cancelPurchase: "Cancel purchase",
		undoSelection: "Undo Selection",
		invalidOrganizationNumber: "Invalid organization number",
		customerInfoUpdated: "Customer information updated successfully",
		invitedSuccessful: "Successfully invited a Customer",
		customerCategoryCreated: "Created customer category successfully",
		invalidFormat: "Invalid file format",
		maximumFour:
			"Maximum is 4. Add more pictures by deleting existing ones",
		fourOnly: "Upload only 4 pictures only",
		imageRequired: "Image field required",
		uploadSuccessful: "Uploaded article images successfully",
		setUpAccount: "Setup Account",
		invitedTo: "You were invited to join",
		settingUp: "Setting up",
		cartEmpty: "Your cart items are empty",
		orderedSuccessfully: "You've ordered items successfully",
		pieces: "pcs",
		errorPlacingOrder: "Oops, there is a problem placing order",
		costPrice: "Cost Price",
		salesPrice: "Sales Price",
		vatRate: "Vat rate",
		margin: "Margin",
		checkEmail: "Please check your email for instructions",
		invalidUser: "Invalid User",
		resetSuccessful: "Password reset successfully. Please login",
		selectFile: "Select File",
		optional: "Optional",
		selectSubscription: "Select Your Subscription",
		subscriptionSuccess: "Your subscription has been set successfully",
		next: "Next",
		currentSubscription: "Current Subscription",
		monthlyCost: "Monthly Cost",
		notAvailable: "Not Available",
		massAssign: "Mass Assign",
		bulkUpdate: "Bulk Update",
		fieldValue: "Field value cannot be longer than 50 Characters",
		download: "Download",
		invalidCellValue: "Invalid value",
		invitedUserSuccess: "Successfully invited Employee",
		successCompanyupdated: "Company information updated successfully",
		notify: "Notification",
		noNotify: "No Current Notifications",
		notFoundText: "Oh no! Page Not Found",
		invalidAccount: "Invalid account",
		backHome: "Back to homepage",
		back: "Back",
		filter: "Filter By",
		markRead: "Mark as read",
		more: "more",
		less: "less",
		save: "Save",
		connecting: "Connecting",
		addWebshop: "Add Webshop",
		addProductCategory: "Add Product Category",
		webshop: "Webshop",
		updatedWebshop: "Webshop updated successfully",
		noWebshop: "Webshop not found",
		unAuthorizedShopify: "Shopify account yet to be authorized",
		shopName: "Shop Name",
		note: "Note",
		approachingLowStockQty: "Approaching low stock quantity",
		itemOutOfStock: "The product is not currently available for purchase",
		criticalLowStockQty: "Critical low stock quantity",
		syncStockFromSupplier: "Sync stockquantity from supplier to webshop",
		placeAutomaticOrders:
			"Place automatic orders with supplier when low on stock",
		addWebshopFor: "Add Webshop for",
		wellStocked: "Well Stocked",
		sufficientQty: "Sufficient Qty",
		webshopProduct: "Webshop Product",
		productCategoryName: "Product Category Name",
		productCategorySuccess: "Product Category added successfully",
		productCategory: "Product Category",
		allProductCategory: "Get All Products",
		assignSelectedProducts: "Assign Selected Product(s)",
		assignSuccess: "Product(s) assigned successfully",
		assignProduct: "Assign Product(s)",
		updateAssignProduct: "Assign Product(s)",
		assignProgress: "You are about to assign 1 products to",
		thresholdQty: "Threshold Stock Quantity",
		relevantCustomer: "Relevant Customer",
		metricsOverview: "Metric Oversight",
		productManager: "Product Manager",
		view: "View",
		supplier: "Supplier",
		isEqualTo: "is equal to",
		isGreaterThan: "is greater than",
		isBetween: "is between",
		isLessThan: "is less than",
		clear: "Clear",
		done: "Done",
		supplierMatch: "SupplierMatch",
		supplierQuantity: "SupplierQuantity",
		unavailable: "Unavailable",
		name: "Name",
		totalMonth: "Total Orders this Month",
		averageMargin: "Average Profit Margin",
		averagePerOrder: "Average Profit Per Order",
		selectParentCategory: "Select Parent Category",
		addSubCategory: "Add Sub Category",
		subCategoryName: "Sub Category Name",
		subCategorySuccess: "Sub category added",
		addCategory: "Add Category",
		source: "Source",
		productWithImage: "Product with image",
		bankIdAuth: "Bank ID Authentication",
		bankIDStatement: "Sign with BankID to approve purchase of amount",
		validBankIDFormat: "Valid format example",
		connectShopify: "Connect to Shopify",
		connectFortnox: "Connect to Fortnox",
		settings: "Settings",
		billing: "Billing",
		myAccount: "Account Info",
		amountDue: "Amount Due",
		dueDate: "Due Date",
		file: "File",
		paid: "Paid",
		unPaid: "Unpaid",
		requireBankIDforAllOrders: "Require BankID for all orders",
		requireBankIDThresholdAmount:
			"Require BankID only for orders above certain amount",
		applyCustomerSpecificBasedMaxCredit:
			"Apply Specific Customer max credit",
		applyGeneralCreditforAllCustomers: "Apply General max credit",
		applyStockThreshold: "Apply Stock threshold",
		orderAmount: "Order Amount",
		verifyBankId: "Verifying Bank ID",
		connectedSupplier: "Connected Supplier",
		connectIntegration: "Connected Webshop",
		selectCategoryBelow: "Select Category below",
		nonConnectedSupplier: "Non-Connected Supplier",
		maxCreditLimit: "Max Credit Limit",
		priceManagement: "Price Management",
		categoryManagement: "Category Management",
		parentCategory: "Parent Category",
		childCategory: "Child Category",
		selectCategoryParent: "Please select category parent",
		removeParentCategory: "Remove Parent Category",
		removeChildCategory: "Remove Child Category",
		totalOrderAmount: "Total Order Amount",
		customerManagement: "Customer Management",
		totalConnectedSuppliers: "Total Connected Suppliers",
		totalOrderAmountMonth: "Total orders amount/month",
		totalProducts: "Total Products",
		customerCategory: "Customer Category",
		syncingCustomers: "Syncing Customers",
  		syncingDescription: "This process may take up to 2 minutes. Please wait...",
		customerCategoryManagement: "Customer Category Management",
		customerCategoryList: "Customer Category List",
		maxLimitErrorMessage:
			"Your purchase amoust is above the max credit your supplier has allowed and therefore the order may not be place. Please contact your supplier",
		selectCompany: "Please select company",
		shopNameRequire: "Please input shop name",
		invalidUpdateResponse: "Invalid response update",
		clickToEdit: "Click to edit",
		appLogs: "Application Logs",
		logInitiator: "Initiator",
		logStatus: "Log Status",
		logs: "Logs",
		priceNotAvailable: "Price not available",
		productVideo: "Product Video",
		relevantArticle: "Relevant",
		selectPricelistIndicator: "Select pricelist with indicator",
		updatePriceList: "Please set a price list",
		relevantArticleCustomers: "Present only for Customers",
		searchCustomers: "Search Customers",
		select: "Select",
		setRelevantArticles: "Set Wholesale Relevant Articles",
		setVisible: "Visible",
		setHidden: "Hidden",
		selectCustomers: "Select Customers",
		userCustomization: "Customization",
		inviteEmailSubject: "Invite Email Subject",
		menuColor: "Menu Color",
		InviteEmailHeader: "Invite Email Header",
		buttonColor: "Button Color",
		inviteEmailContent: "Invite Email Content",
		inviteEmailSubjectEn: "Invite Email Subject English",
		InviteEmailHeaderEn: "Invite Email Title English",
		inviteEmailContentEn: "Invite Email Content English",
		linkColor: "Link color",
		pricelistUpdated: "Price list updated",
		customization: "Customization",
		invited: "Invited",
		connected: "Connected",
		companyLogo: "Company Logo",
		storeName: "Store Name",
		requestAccess: "Request Access",
		viewDetails: "View Details",
		logType: "Log Type",
		startDate: "Start Date",
		endDate: "End Date",
		logError: "Error",
		logInfo: "Info",
		noDescription: "No description",
		personalNumber: "Person Number",
		reConnectNow: "Re-connect to Fortnox",
		invalidProductURL: "Invalid URL",
		skipTour: "Skip Tour",
		previousTour: "Previous",
		nextTour: "Next",
		finishedTour: "Finished",
		dashboardTourMenu:
			"Dashboard provides an overview of customer, article and sales statistics",
		articleTourMenu:
			"Under articles, you can manage your products which are made visible to resellers",
		articleTourTableAction: "Upload product images right here.",
		articleTourTableProductImg:
			"Click on the pictures to see in larger view and also delete",
		articleTourTableRelevantProduct:
			"Easily set the products that are to be made visible to resellers and are relevant to B2B the portal. It is also possible to make certain products visible only to specific customers",
		articleTourTableCheckAll:
			'By selecting an article and then clicking on "Update Article" you can edit visibility, sales accounts, video link and more',
		articleTourProductCategory:
			"Easily create categories and subcategories for all products",
		articleTourPriceManagement:
			"Click on Price Management to overlook profit margins, update prices, filter prices by price list and mass edit prices in the finance program.",
		invoiceTourMenu:
			"If you have chosen to receive wholesale orders as orders in the accounting software, you will find those below Order history, otherwise under Billing history",
		customerTourMenu: "Configure settings for your resellers right here",
		relevantCustomerTour:
			"Set which customers are relevant for the B2B portal",
		customerTourTableAction:
			"Click Edit to set specific customer categories, revenue accounts and cost center at customer level. This will then be reflected in the order/invoice as created in the accounting app",
		customerTourTopCategory: "Click here to create customer categories",
		userTourMenu: "Invite and delete users easily via this page",
		companySettingTourMenu:
			"Under account settings you can configure the app based on desired behaviors and also customize the content of the reseller invitation email",
		companySettingTourCustomization:
			"Compose your own text for ÅF invitation email and if you wish you can customize the portal's color palette.",
		companySettingTourSetting:
			"Configure the app based on your needs, set customer-specific credit limits, select if BankID signing shall be required in order placement, if custom customer price lists are to be applied and which threshold value shall be used for stock availability.",
		companySettingTourBilling:
			"Under Billing, you will find your billing history",
		companySettingTourAccount:
			"Here you will find your account information",
		subscriptionTourMenu:
			"If you want to download or upgrade your subscription, you do it easily under Subscriptions page",
		logsTourMenu:
			"Event Log provides an overview of user history. You can choose to display the events within a certain date range, for one certain message type or for a specific user.",
		buyerDashboardTourMenu:
			"In the Lobby you will find all affiliated suppliers and enter their store. You can also find other suppliers in B2B portal, and request access to become a reseller.",
		buyerOrdersTourMenu:
			"If your supplier has chosen to create orders in the Finance program, you will find orders under Order History, otherwise under Billing History.",
		buyerInvoiceTourMenu:
			"If your supplier has chosen to create orders in the Finance program, you will find orders under Order History, otherwise under Billing History.",
		buyerUsersTourMenu:
			"Invite other users easily, such as the purchasing manager or other employees",
		buyerIntegrationTourMenu:
			"Under Integration, you can integrate with your webshop or the accounting app, in order to to be able to synchronize your warehouse with your suppliers. There is also a function to set up automatically order placement when the item balance becomes low",
		buyerIntegrationProductTourMenu:
			"Your connected integrations and the Product Tracking page can be found here",
		buyerSubscriptionTourMenu:
			"If you want to download or upgrade your agreement, you do it easily under Subscriptions",
		buyerLogTourMenu:
			"Event Log provides overview of who has done what in the program. You can choose to display the events within a certain date range, for a certain message type or for a specific user",
		blog: "Posts",
		createBlog: "Create Posts",
		editBlog: "Edit Post",
		allPost: "All Posts",
		blogTitle: "Title",
		blogContent: "Content",
		blogTag: "Tags",
		billingDetail: "Billing Details",
		publishDate: "Publish Date",
		publishEndDate: "Publish End Date",
		createdDate: "Created Date",
		blogList: "Post List",
		massDelete: "Mass Delete",
		addBlogImage: "Please add a post image",
		addBlogCategory: "Please select a category",
		addBlogDatePublish: "Please add publish date ",
		publishDatePast: "Publish date is in the past",
		publishEndDatePast:
			"Publish End Date must be greater than publish date",
		publish: "Publish",
		publishing: "Publishing",
		preview: "Preview",
		addNewCategory: "Add New Categories",
		uploadBlogImage: "Upload Post Image",
		dateRange: "Date Range",
		execute: "Execute",
		published: "Published",
		updateCategory: "Update category",
		deleteBlogSuccess: "Blog post deleted successfully",
		noBlogPost: "You are yet to create a blog post",
		updateBtn: "Update",
		updateProductCategory: "Update Category",
		updateSubProductCategory: "Update Sub-Category",
		blogPreviewTitle: "Preview panel",
		postDeleted: "Post deleted",
		noPostSeleted: "No blog post selected",
		categories: "Categories",
		archives: "Archives",
		showMore: "show more",
		showLess: "show less",
		readMore: "Read more",
		goToStore: "Go to store",
		january: "January",
		february: "February",
		march: "March",
		april: "April",
		may: "May",
		june: "June",
		july: "July",
		august: "August",
		september: "September",
		october: "October",
		oct: "oct",
		november: "November",
		december: "December",
		order: "Order",
		updateBlogCategory: "Update blog category",
		faqz: "FAQ",
		faqzFullName: "Frequestly asked question",
		inviteEmailSubjectHelpText:
			"Subject the title of the ÅF e-mail invitation",
		inviteEmailHeaderHelpText:
			"The title of the ÅF e-mail invitation (eg welcome as a customer)",
		inviteEmailContentHelpText: "The text for the ÅF e-mail invitation",
		menuColorHelpText: "Color for the side menu",
		linkColorHelpText: "Link color",
		buttonColorHelpText: "Button color",
		brandNameHelpText: "Brand name",
		storeDescriptionHelpText: "Store description visible to ÅF",
		postInvoiceToFortnoxHelpText:
			"If the box is booked, invoices are created in the financial program. If it is not booked, Orders are created in the financial program.",
		applyStockThresholdHelpText:
			"Shows products that are not available in the store for the customer if the stock number in your finance program is lower than the specified number.",
		applyCustomerPricelistHelpText:
			"Applies staffed customer price lists in the web shop based on the price list that is linked to the customer in the finance program.",
		require_bank_id_for_for_orders_above_certain_amountHelpText:
			"Require BankID signing for all purchases over a certain amount.",
		applyCustomerSpecificBasedMaxCreditHelpText:
			"Apply credit limit for customers. The credit limit amount is set under Customer Settings and prevents the customer from placing orders over a certain amount per month with an invoice.",
		applyGeneralCreditforAllCustomersHelpText:
			"Apply general credit limit with the same amount for all customers",
		requireBankIDforAllOrdersHelpText:
			"Require BankID signing for all purchases regardless of order amount",
		chooseCompanyLogoHelpText: "Upload company logo",
		CompanyNameHelpText: "Upload company logo",
		cityHelpText: "City",
		phoneNumberHelpText: "Phone number",
		companyNumberHelpText: "Organization number",
		emailHelpText: "Email to contact person",
		invoiceEmailHelpText: "Invoice mailing",
		bankGiroHelpText:
			"Your bank transfer number that appears on the invoice",
		IBANHelpText: "Your IBAN number that appears on the invoice",
		plusgiroHelpText: "Your plusgiro number that appears on the invoice",
		shopNameHelpText: "Store name",
		woo_consumer_keyHelpText:
			"API keys that you download inside Woocommerce, under Advanced settings",
		woo_consumer_secretHelpText:
			"Remember to specify both Read / Write rights",
		approaching_low_stock_quantityHelpText:
			"Stock balance number too low balance. The number you enter here will follow the link to notify you when your stock balance reaches a low point",
		critical_low_stock_quantityHelpText:
			"Stock balance number for critically low balance. The number you enter here will follow the link to notify you when your stock balance reaches a critical low.",
		sufficient_stock_quantityHelpText:
			"Stock balance number for sufficient balance. The number you enter here will follow the link to notify you when your stock balance is at an acceptable level.",
		syncStockFromSupplierHelpText:
			"Sync your warehouse with the supplier's warehouse.",
		placeAutomaticOrdersHelpText:
			"Place automatic orders when the stock balance reaches a low level.",
		billingAddressHelpText: "Billing address",
		invalidCredential: "system could not validate your credentials",
		title: "Title",
		send_invoices_to_nox_finance: "Send invoices via Nox Finance",
		send_to_noxHelpText:
			"If so, invoices will be created in Fortnox and immediately thereafter sent via Fortnox Finance. The delivery method is based on what is preset on the customer card in Fortnox",
		finallyDelivered: "FINALLY DELIVERED",
		finallyPaid: "FINALLY PAID",
		NotDelivered: "NOT DELIVERED",
		storeDescription: "Store description",
		removeBlogCategory: "“Are you sure you want to delete category",
		blogAddedSuccess: "Successfully added blog post",
		blogUpdatedSuccess: "Successfully updated blog post",
		priceExcludeTax: "*Prices are stated ex VAT",
		unSelectedTermsCondition:
			"Kindly indicate that you agree to our terms and conditions",
		privacyPolicy: "Privacy and Legal",
		general: "GENERALLY",
		trademarks: "Trademarks",
		certainDisclaimer: "Certain disclaimers",
		confidentialInformation: "Confidential information",
		globalAvailability: "Global availability",
		linkTosite: "Linking to this site",
		thirdPartyWebsite: "Third-party websites",
		thirdPartyProductServices: "Third-party products and services",
		collectionTechnicalInformation:
			"Collection and use of technical information",
		useOfCookie: "Use of cookies",
		jurisdiction: "Jurisdiction",
		disclaimerOfGuarranty: "DISCLAIMER OF WARRANTY",
		limitationLiability: "LIMITATION OF LIABILITY",
		loginRequest: "Session expired, Login again",
		definitions: "DEFINITIONS",
		definitionsContent:
			"Below is a list of some words that are used throughout the Agreement. The words shall have the meaning given below.",
		connectionPoint: "The connection point",
		connectionPointContent:
			"The point where our Services are made available to you.",
		userHeader: "User",
		userHeaderContent:
			"The person to whom you have given access to the Services. Users must be employed by you, or otherwise work on your behalf, for example a consultant or accounting economist",
		agreement: "Agreement",
		agreementContent:
			"This agreement includes appendices and sub-appendices.",
		customerData: "Customer data",
		customerDataContent:
			"Data that you in any way, yourself or through Users, submit to us or that we otherwise receive through your use of the Services.",
		customerAgreement: "Customer with Agency Agreement",
		customerAgreementContent:
			"Customer who has entered into an agreement with an accounting firm or similar company for financial services based on the Services.",
		systemAdministrator: "System administrator",
		systemAdministratorContent:
			"The person who has subscribed to the Services on your behalf, or who has otherwise been appointed by you" +
			"System administrator. A customer with an Agency Agreement can have his own system administrator.",
		serviceOrServices: "The Service or Services",
		serviceOrServicesContent:
			"Cloud-based subscription services for integration of web applications and automation of manual processes in current accounting. The service includes delivery of standardized integrations. Tailor-made and customer-specific integrations are not included in this service, but can be ordered as a separate service.",
		conclusionOrder: "CONCLUSION AND ORDER OF THE AGREEMENT",
		conclusionOrderContent:
			"Agreements regarding the Service must be entered into in writing either by e-mail, via order on the website,\n" +
			"www.bridgero.se, or via App marketplace \n" +
			'The day the Customer completes the order constitutes the "Contract Day".\n' +
			"The customer's minimum commitment per month according to the Agreement shall be calculated as the sum of the monthly fees\n" +
			"which the Customer must pay for the total number of licenses that the Customer ordered at the conclusion of the Agreement.\n" +
			"The customer's minimum commitment per month appears in the price list published on the website and\n" +
			"cannot be changed during the binding period of the Agreement.\n" +
			"Ordering additional / new services (including licenses for users and subscriptions in addition\n" +
			"The Customer's minimum commitment) or Products after the Agreement has been entered into shall be made in this way\n" +
			"The supplier instructs.",
		rightOfwithdrawal: "RIGHT OF WITHDRAWAL",
		rightOfwithdrawalContent:
			"Downloading and using digital products is classified as a service that cannot be returned then\n" +
			"it is a so-called direct consumption. The digital verification process corresponds to a seal\n" +
			"of a physical commodity.",
		delivery: "DELIVERY",
		deliveryContent:
			"The configuration and activation of the service is delivered in the form of a customer portal where the customer is responsible for" +
			"follow instructions and configure their settings." +
			"If the Customer considers that there are errors or deficiencies in the delivery of the Service, the Customer must in writing" +
			"inform the Supplier thereof and ensure that relevant written documentation and error description is" +
			"Delivery to the Supplier no later than ten (10) days from the Delivery Date. The supplier must then" +
			"and take the necessary corrective action within a reasonable time. If the Customer has not reported an error or defect in" +
			"in accordance with the above, the Service is considered delivered flawlessly." +
			"Errors or changes after the aforementioned time are handled as specified in point 16" +
			"(Troubleshooting) below. If delivery can not take place and this is due to the Customer not fulfilling theirs" +
			"commitments under the Agreement, the Supplier is entitled to invoice all fees from the Date of the Agreement.",
		deliveryGuarranty: "DELIVERY GUARANTEE",
		deliveryGuarrantyContent1:
			"• If the Delivery Date has not taken place within ninety (90) days from the Date of the Agreement, the Customer is entitled to demand a deduction in an amount corresponding to fifty (50) percent of the fixed fee paid by the unusable Service.",
		deliveryGuarrantyContent2:
			"If the Delivery Date has not taken place within one hundred and twenty (120) days from the Date of the Agreement, the Customer has the right to terminate the Agreement. If the Agreement is terminated, all benefits shall be returned and all conditions shall be considered regulated, e.g. with the meaning that no additional compensation other than any fees paid by the Customer shall be refunded to the Customer.",
		deliveryGuarrantyContent3:
			"•The Delivery Guarantee in this section only applies if the delay as above is solely due to circumstances attributable to the Supplier. This is not considered to be the case, for example",
		deliveryGuarrantyContent4:
			"- The delay is wholly or partly attributable to a third party other than the Supplier or if the delay is wholly or partly attributable to the Customer, which may be the case if the Customer affects or changes the date of Delivery Date or the Customer has not fulfilled its obligations under the Agreement, such as installation and activation of the service.",
		deliveryGuarrantyContent5:
			"- Information in the Agreement or in Customer Data Information is incorrect and the Supplier cannot receive correct information (confirmed) by the Customer before the planned installation Force Majeure is available",
		customerObligation: "CUSTOMERS OBLIGATIONS",
		customerObligationContent1:
			"• The customer must ensure that all information stated regarding contact information, billing information\n" +
			"or credit card information is accurate and undertakes to update this information regularly.",
		customerObligationContent2:
			"• The Customer shall be responsible for the activities conducted by the Customer and its members and shall" +
			"ensure compliance with national law.",
		customerObligationContent3:
			"The customer is responsible for continuously checking imported data to the finance program and\n" +
			"keep up to date its software settings for software usage",
		customerObligationContent4:
			"• The customer is responsible for setting settings in the finance program in accordance with instructions\n" +
			"provided by B2B Portal and Activation of Delivered Service.",
		customerObligationContent5:
			"• Customer agrees not to reproduce, duplicate, copy, sell, resell or exploit\n" +
			"any part of the B2B Portal, use of Automate More, or access to the B2B Portal. Resale of B2B Portal is only permitted with the written consent of\n" +
			"Connectivo AB and a reseller agreement from Connectivo AB (which Connectivo AB reserves\n" +
			"the right to terminate at any time and which may include certain conditions).",
		customerObligationContent6:
			"• Den som undertecknar detta avtal genom köp av tjänst garanterar att han/hon är ansvarig för att se\n" +
			"till att tillstånd finns för att binda Kunden genom ett sådant utförande.\n",
		customerObligationContent7:
			"The customer is responsible for keeping user information, passwords and SIM cards with associated codes\n" +
			"well protected so the use of unauthorized persons is prevented",
		customerObligationContent8:
			"The Customer is responsible for informing affected users at the Customer that the Supplier may come\n" +
			"to send information about the Supplier's products and services",
		responsibiltyClaims: "Responsibility for any claims made against us",
		responsibiltyClaimsContent:
			"If anyone else makes a claim against us because of you or your Users' use of the Services\n" +
			"or third party services, you shall indemnify us by compensating us for the damage or loss we\n" +
			"suffered due to the claim. Examples of such claims are claims for compensation for trespassing on third parties\n" +
			"intellectual property rights such as copyright or trademark. We shall also have the right to pass on the right\n" +
			"to make such a request and thereby have someone else make the request.",
		customerDataInformation: "CUSTOMER DATA INFORMATION",
		customerDataInformationContent:
			"The Supplier processes Customer Data Information to provide and ensure the operation of\n" +
			"The service according to the Agreement, to fulfill an obligation according to law or other statute and for\n" +
			"billing. The Supplier may use Customer Data to fulfill the assignment, for statistical purposes,\n" +
			"to improve and develop our Services and for marketing.\n" +
			"The Customer shall, at the Supplier's request, provide the customer data information that the Supplier needs\n" +
			'to provide the Service ("Customer Data Information").\n' +
			"The Customer is responsible for ensuring that all Customer Data information is correct and that Customer Data is free from\n" +
			"viruses, trojans, worms or other software or code that may harm the Services.\n" +
			"The Customer shall also compensate the Supplier for all damage caused directly or indirectly due to\n" +
			"the content of Customer Data or the use of Customer Data in the Services is contrary to applicable\n" +
			"legislation.\n" +
			"As a customer, you can always extract Customer Data from the Services. In connection with you terminating the Agreement, it is\n" +
			"up to you to extract all Customer Data from the Services that you may need for future use.",
		priceAndPayment: "PRICES AND PAYMENT",
		priceAndPaymentContent:
			"Unless the parties agree otherwise, the prices for the Services as at the time\n" +
			"entered into by the parties Avralet has been published on the Automate More website. The same applies to\n" +
			"extension of the Agreement.\n" +
			"The service's fixed fees are charged monthly or annually, either via card payment or invoice.\n" +
			"Invoice payment terms are 15 days, unless the Parties agree otherwise.\n" +
			"In the event of payment after the due date, interest on arrears will be charged in accordance with law. The supplier also owns\n" +
			"right to compensation for reminder fee and collection costs. Objection to invoice must be\n" +
			"Delivery to the supplier no later than ten (10) days after the invoice date. Objections must be made in writing\n" +
			"stating the reasons why the Customer objects to the invoice. The supplier has the right to participate\n" +
			"immediate effect terminate The agreement on payment of invoice has not been made thirty (30) days after\n" +
			"due date.",
		priceAndPaymentContent2:
			"Each integration is comprised of two (2) types of costs - a license fee and a recurring one\n" +
			"monthly fee for the cloud service.\n" +
			"In connection with the purchase of integration, it is the customer's responsibility to ensure that the correct level package is chosen in this way\n" +
			"that the service covers the customer's monthly order volume. Connectivo AB reserves the right to i\n" +
			"gradually invoice the customer in cases where the customer exceeds the order volume covered by the selected level package.",
		priceAndPaymentContent3:
			"We have the right to increase the price, with direct effect, if the increase is due to circumstances we cannot\n" +
			"check, for example, change of exchange rate, tax or other circumstance of financial\n" +
			"significance that affects the cost of providing the Services.\n" +
			"We also have the right to increase the price in other cases, but then we must contact you within thirty (30) days\n" +
			"before raising the price to inform you of the change. The new price does not take effect until the next one\n" +
			"payment period begins. You have the opportunity to cancel until the day before the price increase takes effect\n" +
			"The agreement with us. If no notice is given by you, you are deemed to have approved the new pricing.",
		agreementTimeAndTermination:
			"AGREEMENT TIME AND TERMINATION OF THE AGREEMENT",
		agreementTimeAndTerminationContent:
			"The customer chooses at the time of purchase whether he wishes with a lock-in period or without a lock-in period\n" +
			"the agreement. Unless otherwise agreed, the binding period is 12 months and is automatically extended by one year\n" +
			"if the agreement is not terminated by either party no later than 30 days before the end of the agreement period.\n" +
			"The agreement must be terminated in writing one (1) month before the agreement expires. If the Agreement is not terminated\n" +
			"the Agreement is extended by twelve (12) months at a time, with a notice period of one (1) month before\n" +
			"each new twelve-month period.\n" +
			"In addition to what is otherwise stated in this Agreement, we both have the right to terminate with immediate effect\n" +
			"The agreement on the other of us has:",
		agreementTimeAndTerminationContent1:
			"has breached the Agreement and does not rectify it within thirty (30) days of receiving the other\n" +
			"the party's written notice of breach of contract, or",
		agreementTimeAndTerminationContent2:
			"initiates liquidation proceedings, applies for or goes bankrupt, cancels payments or\n" +
			"otherwise may be feared to be heading for insolvency.",
		supportTroubleShooting: "SUPPORT / TROUBLESHOOTING",
		supportTroubleShootingContent:
			"The Service includes, in addition to access to the service portal which is accessed via www.bridgero.se, technical\n" +
			"and administrative support via email and telephone which intends to assist the Customer with questions regarding\n" +
			"The service, assist with the necessary support from the Supplier's suppliers, simpler\n" +
			"changes / configurations and additions / deletions in the Service and troubleshooting in the Error Service\n" +
			"found in connection with the troubleshooting.\n" +
			"You can contact us with questions related to how our Services work and should be used. We\n" +
			"can not, however, offer support in matters such as accounting or questions of a technical nature\n" +
			"which is not about the Service.\n" +
			"You can contact us through the contact information available on our website. Current opening hours for\n" +
			"support can be found on our website.\n" +
			"The service does not include support which, among other things, but not exclusively means: troubleshooting in the Customer's,\n" +
			"third party or other supplier's software, support regarding Customer's equipment, configuration\n" +
			"not provided by the Provider, support not related to the Service, training, anymore\n" +
			"telephone meetings, counseling or consultation. Such support is charged in accordance with the Supplier's\n" +
			"the current standard price list.",
		supportTroubleShootingContent2:
			'"Error" means that the Customer can not use the Service in accordance with the Agreement and that the error is due to\n' +
			"The supplier.\n" +
			"The supplier shall remedy the fault free of charge within a reasonable time. By Error is not meant, for example, but not\n" +
			"exclusively, deficiencies such as:",
		supportTroubleShootingContent3:
			"is of minor importance to the Customer or which does not prevent the Customer from using the Service,\n" +
			"caused by a third party or by circumstances beyond the Supplier's control.",
		supportTroubleShootingContent4:
			"refers to other than the Service, for example regarding services, products and deliveries that take place through\n" +
			"another supplier.",
		supportTroubleShootinfContent5:
			"Error reports are handled by the Supplier's support. Error reports are made by e-mail or\n" +
			"phone calls. It is the Customer's responsibility to assist in troubleshooting and provide feedback within 48 hours\n" +
			"hours with necessary information for continuous search. If feedback is not provided, it closes\n" +
			"the case and is considered closed without responsibility for the Supplier.\n" +
			"16.8. Error reports can be made around the clock, all days of the year at info@bridgero.se.\n" +
			"Error reports that are received at a time other than on a weekend weekday between 08.00-17.00 are considered received\n" +
			"next weekend weekday at 08.00.",
		responsibilityAccountingData: "RESPONSIBILITY FOR ACCOUNTING DATA",
		responsibilityAccountingDataContent:
			"The Supplier is responsible for the further development of the Service and decides alone which improvements,\n" +
			"changes and technical adjustments to be made in the Service. Automate More Integrations\n" +
			"and its functionality is based on continuous exploration, machine learning and replication of\n" +
			"processes such as current accounting performed by accounting consultants.\n" +
			"The service is a software program developed for the purpose of imitating and largely automating the work\n" +
			"accounting consultants perform. The position does not completely replace an accounting consultant but is only\n" +
			"complementary.\n" +
			"The supplier does not guarantee that the interpretation of transaction data will always be completely correct, but\n" +
			"The customer is responsible for the final check of the accuracy of the accounting.\n" +
			"Subject to the limitations set forth in this Agreement, the Supplier shall be responsible for\n" +
			"correction of incorrect bookings due to its negligence. In case of errors or deficiencies that may\n" +
			"attributed to the Supplier, he undertakes to act to correct such errors without undue delay.\n" +
			"In the event that there is an error in the Services that causes the processing of Customer Data to lead to one\n" +
			"incorrect result, we undertake that, as soon as the circumstances require with regard to the type of\n" +
			"errors and other circumstances, at no extra cost to you, make a new processing of Customer Data.\n" +
			"The customer is not entitled to a reduction of payment, debit or other penalties in the event of\n" +
			"malfunctions or error reporting that may occur.\n" +
			"Fault reporting must be given by the customer in accordance with the instructions announced by the Supplier from\n" +
			"from time to time and within a reasonable time for the discovery of the error. In the absence of intent or gross negligence\n" +
			"by the Supplier, he otherwise assumes no responsibility for errors or deficiencies in services included therein",
		modificationTermsAndCondition: "MODIFICATION OF TERMS AND CONDITIONS",
		modificationTermsAndConditionContent:
			"The Supplier further reserves the right to change the terms of this Service Agreement, including, but\n" +
			"not limited to, the prices that the Supplier has from time to time. The customer must be informed\n" +
			"about such changes by e-mail or through the information made available on B2B\n" +
			"Portal website one (1) month before the entry into force. If the customer does not accept the change, have\n" +
			"The Customer within thirty (30) calendar days of sending the Email or, where applicable,\n" +
			"thirty (30) calendar days from the change are published on the B2B Portal website, right to say\n" +
			"terminate the agreement with immediate effect. If the agreement is not terminated by the Customer within the said time,\n" +
			"The customer is considered to have approved the new terms.",
		transferOfTerms: "TRANSFER OF CONTRACTS",
		transferOfTermsContent:
			"The Customer is not entitled to transfer or pledge without the Supplier's prior written consent\n" +
			"or otherwise dispose of or grant security rights regarding their rights and / or\n" +
			"obligations under the Agreement. The supplier has the right to transfer and / or otherwise dispose of\n" +
			"their rights and / or obligations under the Agreement.",
		marketing: "MARKETING",
		marketingContent:
			"We may market services to you that we or any of our partners provide to us.\n" +
			"The marketing can take place in the Services, by e-mail or in another way.",
		agentsAndSubContractors: "AGENTS AND SUBCONTRACTORS",
		agentsAndSubContractorContent:
			"The Supplier has the right to hire subcontractors and partners to develop the Services\n" +
			"and fulfill their obligations under the Agreement.",
		intellectualProperty: "INTELLECTUAL PROPERTY RIGHTS",
		intellectualPropertyContent:
			"All intellectual property rights in the Service, as well as changes and variations thereof, are and shall be\n" +
			"continue to be the Supplier's property. You may not copy, modify or otherwise manage\n" +
			"software or other material belonging to the Services.\n" +
			"In the case of intellectual property infringement that depends on the Customer, the following applies:\n" +
			"The customer must defend the Supplier if claims are directed or an action is brought against the supplier for infringement due to\n" +
			"the customer or the person using the Services in violation of this Agreement. This then takes place on the customer's own\n" +
			"expense. The Customer shall also reimburse the Supplier for all the costs and damages incurred by the Supplier\n" +
			"through settlement or they may be required to pay.",
		messages: "MESSAGES",
		messagesContent:
			"Messages under the Agreement are provided to the Address specified in the Agreement by the Supplier and the Customer, respectively,\n" +
			"unless messages are advantageously provided in another way, for example by telephone or e-mail.\n" +
			"Notices shall be deemed to have been received by the receiving Party five (5) days after dispatch\n" +
			"by letter and three (3) days after dispatch by e-mail and directly by telephone. The parties shall\n" +
			"promptly inform each other of address changes.",
		forceMajure: "FORCE MAJEURE",
		forceMajureContent:
			"The Supplier shall not be liable for failure to fulfill the Agreement if this is caused by one\n" +
			"circumstance which was beyond the Supplier's control and which the Supplier could not reasonably have\n" +
			"is expected to have taken into account or taken into account at the time of the conclusion of the Agreement and its consequences\n" +
			"Nor could the supplier reasonably have avoided or overcome, such as war, fire,\n" +
			"flood, thunder, fire, terrorist attack, hacker attack, occupation, lock-out,\n" +
			"import restrictions, sanctions or the like, or errors or delays in delivery from\n" +
			"subcontractor due to circumstances beyond his control.",
		applicableLaw: "APPLICABLE LAW AND DISPUTE RESOLUTION",
		applicableLawContent:
			"The rights and obligations of the parties in the interpretation and application of the Agreement shall be determined in\n" +
			"in accordance with Swedish law. Disputes that arise in connection with the Agreement shall be finally settled through\n" +
			"arbitration proceedings administered by the West Swedish Chamber of Commerce's Arbitration Institute.\n" +
			"However, an action concerning overdue unpaid claims is subject to litigation before a general court.\n" +
			"The rules on simplified arbitration shall apply, if not the West Swedish Chamber of Commerce\n" +
			"Arbitration council taking into account the severity of the dispute, the value of the subject matter of the dispute and others\n" +
			"circumstances determine that the rules of ordinary arbitration shall apply. In the latter case\n" +
			"the Arbitration Council shall also decide whether the arbitral tribunal shall consist of one or three arbitrators.",
		termsFooter:
			"The seat of the arbitration shall be Gothenburg, Sweden. The language of the proceedings must be Swedish.",
		termsFooterContent: "Revised January 2021.",
		authenticationInProcess: "Authentication in Process",
		maxImageUploadMessage: "The image upload must be 5000 x 3000 px",
		maxArticleImageUploadMessage:
			"The image upload must be max dimension 1500 x 1500 px",
		maxImageUploadMessageError:
			"Image upload must be of dimension 5000 by 3000 px",
		maxArticleImageUploadMessageError:
			"Image upload must be of dimension 1500 by 1500 px",
		maxCreditLimitError: "max credit may not be null.",
		categoryRequired: "Category may not be null.",
		subscriptionSuccessMessage:
			"Thank you, feel free to log in to your account now",
		emailVerificationMessage: "Verifying your email....",
		emailError:
			"system could not verify your email,kindly make sure you click the right link",
		errorTag: "error fetching blog tag",
		proceed: "Proceed",
		pending: "PENDING",
		completed: "COMPLETED",
		info: "INFO",
		error: "ERROR",
		display_store_to_all_buyers: "Display store to all buyers",
		apply_display_store_to_all_buyers: "Display store to all buyers",
		archive: "Archive",
		show_more: "show more",
		show_less: "show less",
		error_processing_request: "Error processing your request",
		max_order_limit_exceeded: "You have reached your max order limit",
		order_confirmation_text: "Order confirmation text",
		order_confirmation_text_en: "Orderbekräftelsetext Engelska",
		order_confirmation_text_help_text: "Order confirmation text",
		bulk_article_upload: "Bulk articles upload",
		excel_file_format_text: "Only xlxs, xlx excel format is supported",
		article_upload_in_progress: "Article upload in-progress",
		article_excel_document_required: "Excel document upload is required",
		upload_bulk_article_excel: "Upload bulk article (excel)",
		export_product_data: "Export article (excel)",
		download_excel_template: "Download article template (excel)",
		articleLongDescription: "Article long description",
		requestToBuyerTitle: 'Request to Become Buyer',
		companyName: 'Company Name',
		enterCompanyName: 'Enter your company name',
		organizationNumber: 'Organization Number',
		enterOrgNumber: 'Enter your organization number',
		address: 'Address',
		enterAddress: 'Enter your address',
		city: 'City',
		enterCity: 'Enter your city',
		zipCode: 'ZIP Code',
		enterZipCode: 'Enter ZIP code',
		email: 'Email',
		enterEmail: 'Enter your email address',
		phone: 'Phone',
		enterPhone: 'Enter your phonenumber',
		billingEmail: 'Billing Email',
		enterBillingEmail: 'Enter billing email address',
		contactName: 'Contact Name',
		enterContactName: 'Enter contact person name',
		message: 'Message',
		enterMessage: 'Enter additional message (optional)',
		submitting: 'Submitting...',
		submitRequest: 'Submit Request',
		fieldRequired: 'Please fill in all required fields',
		invalidEmail: 'Please enter a valid email address',
		invalidBillingEmail: 'Please enter a valid billing email address',
		requestSubmitted: 'Request submitted successfully',
		serverError: 'An error occurred. Please try again later',
		role: "Role",
		event: "Event",
		fieldTitle: "Field Title",
		fieldMap: "Field Map",
		required: "Required",
		fieldTitleHelpText:
			"Add fields that the customer can fill in when placing an order. Name the field and then select which reference field in the finance program the value is to be sent to",
		fieldMapHelpText:
			"These fields are available in the Financial Program for reference",
		requiredHelpText:
			"Check if you want the field to be mandatory for the customer to fill in when ordering",
		companyConfigurationSuccess: "Company checkout configuration saved",
		companyEmailConfigurationSuccess: "Company email configuration saved",
		accountingFieldAlreadyExist: "Accounting field already exist",
		selectAccountField: "Select account field",
		discountedPrice: "Discount Price",
		discount: "Discount",
		purchase_price: "Purchase Price",
		updateSuccess: "Data updated successfully",
		allowCustomText: "Add extra text fields for item when ordering",
		articleCustomTextTitle: "Title for extra text fields",
		allowCustomTextHelpText:
			"The purpose of this feature is to provide the buyer with an additional " +
			"input field when they  add the product to the Cart",
		additionProductItem: "Additional items",
		itemUpdated: "Item updated",
		campaignScheduleDate: "Campaign schedule date",
		emptyUploadedZip: "Uploaded zip file is empty",
		uploadExcelFile: "Kindly upload excel file",
		add: "Add",
		customerDeliveryAddress: "Customer Delivery Address",
		addDeliveryAddress: "Add Delivery Address",
		editDeliveryAddress: "Update Delivery Address",
		locationName: "Location name",
		streetAddress: "Street Address",
		country: "Country",
		addressUpdated: "Address updated successfully",
		use_accounting_customer_number_for_identifier:
			"Use Accounting Customer number for identifier",
		use_accounting_customer_number_for_identifier_text:
			"Use Accounting Customer number for identifier",
		emailSettings: "Email Settings",
		sellerBackendUrl: "Server Url",
		sellerFrontendUrl: "Server Frontend Url",
		emailHost: "Email host",
		emailUsername: "Email Username",
		emailPassword: "Email Password",
		emailPort: "Email Port",
		useSSL: "Use SSL",
		useTLS: "Use TLS",
		connectionType: "Connection Type",
		connectionTypeHelpText:
			"This indicate the smtp connection type to use in sending email",
		emailFrom: "From Email",
		emailSetting: "Email Setting",
		estimatedDeliveryDay: "Indicate the estimated delivery days",
		articleDescriptionError: "Kindly indicated article description",
		articleSalesPriceError: "Kindly indicated article sales price",
		zipTextMsg: "File must be in .zip format",
		zipTextDimensionMsg: "Images must be 400px by 400 px",
		clickUploadZipText: "Click to upload image zip",
		ShopOwner: "Owner",
		BuyerEmployee: "Employee",
		EndUserCustomer: "Customer",
		ShopOwnerEmployee: "Employee",
		excelReport: "EXCEL REPORT",
		checkoutMessage: "Here you can add a message for the seller",
		processingOrder: "Processing Order",
		Delivery: "Delivery",
		days: "days",
		estimatedDeliveryDate: "Estimated delivery date",
		viewProductDetail: "Product Details",
		buyerNote: "Buyer Note",
		internalNote: "Internal Note",
		customerEmptyError: "Välj kunder",
		deliveryAddressOptions: "Delivery address options",
		savedAddress: "Saved Addresses",
		enableMinimumQty: "Enable minimum order quantity",
		minimumOrderQty: "Minimum order quantity",
		minimumOrderQtyError: "Minimum order quantity must be greater than 1",
		minimumOrderAmount: "Minimum order amount",
		customerMininmumOrderQtyError: "Minimum order quantity must be ",
		customerMinimumOrderAmountError: "Minimum order amount must be ",
		tasksHandle: "Tasked handled",
		actions: "Actions",
		categoryTree: "Category Tree",
		minimum_order_amount: "Minimum order amount",
		minimum_order_amount_text: "Minimum order amount",
		minimum_order_amount_error:
			"Total order amount is below minimum order amount of",
		addFaq: "Add Faq",
		question: "Question",
		answer: "Answer",
		faqSuccess: "Faq successfully created",
		faqCategorySuccess: "Faq Category successfully created",
		editFaq: "Update FAQ",
		removeFaq: "Remove Faq",
		cancelled: "CANCELLED",
		cancelling: "Cancelling",
		supplierInfoText:
			"Here you will find all suppliers which you are currently connected to and those \n" +
			"which also sell on B2B portal. You can click on a non-connected seller for more information about their " +
			"offerings and request to become a re-seller. To visit a Sellers webshop " +
			"and welcome page, click on the containers below\n",
		invalidExcelHeaderError:
			"Kindly follow the column headers as given in template",
		invalidFields: "invalid fields",
		emptyEntry: "Yet to add an entry",
		childCategorySuccess: "Child category added",
		emptyNodeSelected: "Empty Child Category Selected",
		subcategoryAssignedSuccess: "Sub Category assigned successfully",
		categoryTreeModification: "Click the X to delete the category",
		selectChildCategory: "Select Child Category",
		categoryStructure: "Category structure",
		removeSubCategory: "Remove Sub Category",
		assignArticleCategory: "Assign Category to Article",
		assign: "Assign",
		articleCategoryAssign: "Article category successfully assigned",
		articleCategoryAssignError: "Error assigning category to article",
		additionalSubCategory: "Additional Sub Category",
		tags: "Tags",
		postStatus: "Post status",
		renderOnlyCustomerSpecificArticle:
			"Render only customer specific articles",
		renderOnlyCustomerSpecificCategories:
			"Render only customer specific categories",
		renderOnlyCustomerSpecificReferenceField:
			"Render only reference fields on Checkout",
		availableOnlyForSpecificCustomer:
			"Make this category visible for only some customers",
		customerSpecificCategoryText:
			"Make category visible to specific customers only",
		customerSpecificCheckoutText:
			"Make reference fields visible for specific customers only",
		categorySpecificUpdate: "Customer specific entry updated",
		setCustomer: "Set Customer",
		applyTo: "Visibility",
		allCustomer: "All Customers",
		applyToCustomerText: "Only specific customer",
		customerSpecific: "Customer Specific",
		showCustomerResalePrice: "Show consumer resale price",
		consumer_resale_field_title: "Consumer resale display title",
		consumer_resale_price: "Consumer resale price",
		permissionDenied: "Permission denied",
		productArticleCategoryAssign: "Product Category Assigning",
		productEmpty: "No product currently available",
		searchEmpty: "Your search does not match any product",
		filterEmpty: "Your filter does not match any product",
		hide_stock_quantity: "Hide stock quantity",
		allowBackOrder: "Preorder/BackOrder Article",
		allowBackOrderHelpText: "If checked, customer is able to purchase product regardless of stockquantity. Stockquantity will not be visible to customer",
		preOrderItem: "PreOrder/BackOrder Item",
		buyProduct: "Buy",
		b2bOrderNumber: "B2B order number",
		delete: "Delete",
		self_allowed_create_delivery_addresses:
			"Allowed to create delivery addresses",
		is_default_address: "Set as default address",
		apply_customer_specific_user_profiles:
			"Apply customer specific user profiles",
		address: "Address",
		apply_customer_currency_on_orders: "Apply customer currency on orders",
		apply_english_on_all_templates: "Set default communication language English",
		no_company_text:
			"Select a store in order to access to access this resources",
		not_allowed_setting:
			"Your supplier does not enable accessing your delivery address,kindly contact your supplier",
		activeFilter: "Active Filters",
		removeAllCustomerFromArticle:
			"Are you sure to remove all customers from this article",
		customerSelectionEmpty: "Select one or more customers to assign",
		totalImageToUpload: "Total images to upload ",
		// estimatedTime: 'Estimated upload time:',
		outOfStock: "Product out of stock",
		estimatedTime: "Estimated upload time:",
		specification: "Product Specifications",
		all: "All",
		blogDataSuccess: "Blog information was updated",
		articlePdf: "Article Document (pdf)",
		clickUploadPdf: "Click to upload (pdf)",
		pdfSizeText: "pdf files with a size less than 10mb",
		fileToLarge: "File too large",
		fileExceedLimit: "File Upload Exceed Limit",
		adminEmailAddress: "Admin email address",
		send_order_notifications_to_admin:
			"Send notification to admin on order",
		disable_customer_order_conf_emails:
			"Disable Order Confirmations to Buyer",
		show_standard_price_additional_to_customer_specific_price:
			"Show standard price additional to customer specific price",
		standard_price: "Standard Price",
		relevant: "Relevant",
		assignProductBlog: "Recommended products",
		searchProduct: "Search article",
		linkedArticle: "Linked Article",
		linkedArticleHelpText:
			"By linking products to your blogpost, they will be included and highlighted in the blogpost",
		sendAsNewsLetter: "Send as Email Newsletter Also",
		recommendedProduct: "Recommended Products",
		recommendedAccessories: "Recommend Accessories",
		accessories: "Accessories",
		customerInviteReminder: "Customer Invite Reminder Notification",
		customerInviteReminderHelpText:
			"Ability to remind your customer / buyer to accept invitation",
		once_in_a_week: "Once in a week",
		twice_in_a_week: "Twice in a week",
		three_time_in_a_week: "Three times in a week",
		dont_send_reminder: "Do not send reminder",
		profit_margin: "Profit Margin",
		totalSubscription: "Total Subscription",
		totalSupplier: "Total Supplier",
		totalBuyer: "Total Buyers",
		totalUser: "Total Users",
		logo: "Logo",
		buyers: "Buyers",
		companies: "Companies",
		notifyMe : "Notify Me",
		submitting: "Submitting...",
		outOfStock: "Out of Stock",
		buyerNote: "Meddelande från kund",
		internalNote: "Intern anteckning",
		notConnectedToCompany: "Not connected to any company",
		settingUpAccount: "Please wait... Setting up account",
		accessBuyerDashboard: "Access buyer dashboard",
		permissionDeniedResource:
			"You currently do not have permission to access this page",
		allow_seller_place_order: "Allow seller place an order on your behalf",
		settingUpdated: "Setting updated successfully",
		productDownload: "Download Product List",
		orderHelpText: "Edit Order",
		orderManagement: "Order Management",
		deliveryDate: "Delivery Date",
		updateOrder: "Update Order",
		orderDetail: "Order Information",
		orderRows: "Order Rows",
		customerDetail: "Customer Detail",
		deliveryInformation: "Delivery Address",
		invoiceType: "Invoice",
		createOrder: "Create Order",
		account: "Account",
		vatIncluded: "Vat Included",
		yourOrderNumber: "Your Order Number",
		yourReference: "Your Reference",
		paymentTerms: "Payment Terms",
		priceList: "Price List",
		currency: "Currency",
		project: "Project",
		vatType: "Vat Type",
		emptyRows: "Order / Invoice must contain one or more rows",
		cancelOrderInvoiceErrorText:
			"Canceled order / invoice can not be updated",
		cancelOderInvoiceHelpText: "Cancel order or Invoice",
		cancelSuccessful: "Cancelled Successfully",
		unittypes: "Unittypes",
		addNewUnitType: "Create Unit types",
		updateUnitType: "Update Unit types",
		unit: "Unit",
		removeUnitType: "Remove Unit Type",
		unitUpdated: "Unit updated successfully",
		unitCreated: "Unit created successfully",
		productInUnit: "Does this product come in a bundle/package?",
		productPackageText: "How many units per package",
		productPdf: "Product information sheet",
		blogVisibilty: "Visibility",
		generalSetting: "General Settings",
		stockManagement: "Stock Management",
		notifications: "Notifications",
		fullLogo: "Company logo large size",
		halfLogo: "Company logo small size",
		invalidDimension: "Invalid dimension for the uploaded image",
		generalMaxCreditError: "Max credit must be greater than 0",
		adminEmailAddressError: "Admin email address is required",
		customEmailRuleHelpText:
			"Choose if you want the customer to be reminded of the invitation after the initial invitation has been sent",
		sendEmailToAdminHelpText:
			"Tick if you want to receive an email notification every time your customer places an order",
		dontSendOrderConfirmationToBuyerText:
			"Tick if you do not want the customer to receive an order confirmation via email",
		allow_seller_to_see_your_orders: "Allow seller to see their orders",
		othersInformation: "Other Information",
		productRecommendations: "Product Recommendations",
		priceInformation: "Price Information",
		productInformation: "Product Information",
		newsletterDate: "Date to send newsletter",
		openBlogRecommendedProduct: "Linked Product",
		searchArticles: "Search Article",
		linkedArticleField: "Choose which articles to link to the post",
		previewEmail: "Preview Email",
		profitMargin: "Profit Margin",
		sellerAllowedToPlaceOrder: "Seller allowed to place order",
		sellerAllowedToSeeOrder: "Seller allowed to see orders",
		stockThresholdQuantity: "Stock threshold quantity",
		notification: "Notification",
		productVariations: "Variations",
		selectRule: "Select Rule",
		textSchedule: "Email letter was sent out at: ",
		sync_only_webshop_articles: "Synchronize only webshop articles",
		connect: "Connect",
		stockpoint: "Stock Point",
		allow_seller_send_employee_invite:
			"Allow seller to send employee invite",
		articleVariations: "Article Variations",
		variations: "Variations",
		readNotification: "Read Notifications",
		unreadNotification: "Unread Notifications",
		notificationEmpty: "No notification available",
		microsoft: "Microsoft 365",
		other_mail_server: "Other mail server",
		mail_server: "Mail Server",
		authorize_microsoft_365: "Authorize microsoft 365 mail server",
		sync_only_corporate_customers: "Sync only corporate customers",
		import_all_orders_from_accounting: "Import all orders from accounting",
		import_all_orders_from_start_date: "Import all orders from start date",
		RequestBecomeBuyer: "Request to become buyer",
		createSuccess: "Created successfully",
		createOrderOrInvoiceCopyText:
			"Kindly confirm, to create a duplicate of this",
		createArticle: "Create Article",
		deleteSuccess: "Deleted successfully",
		deleteArticle: "Delete Article",
		createCustomer: "Create Customer",
		otherInformation: "Other information",
		customerInformation: "Customer information",
		updateCustomer: "Update Customer",
		customerAddress: "Billing address",
		copyOrder: "Copy Order",
		copyInvoice: "Copy Invoice",
		editCustomer: "Edit Customer",
		loading: "Loading",
		whiteLabelSection: {
			fullImageTextCaption:
				"Max dimension of 200px by 200px, file format jpg/png",
			halfImageTextCaption:
				"Max dimension of 100px by 100px, file format jpg/png",
			favIconTextCaption:
				"Max dimension of 16px by 16px, file format jpg/png",
			favIcon: "Favico",
		},
		revenue: "Revenue",
		hideDetails: "Hide Details",
		productDetail: "Product Details",
		hide_sales_price: "Hide sales price",
		hide_delivery_time: "Hide delivery time",
		hide_discounted_price: "Hide discounted price",
		hide_recommended_price: "Hide recommended price",
		hideDetailsHelpText:
			"Här kan du styra och dölja viss produktinformation på produktsidan. För att styra vilken info, gå till inställningar sidan. Default kommer att dölja priser enbart.",
		defaultBlogCountRow: "Default count view",
		pinAsTopPost: "Pin as top post",
		bannerBackgroundColor: "Banner Background Color",
		toWebshop: "To Webshop",
		buyer_sidebar_menu_colorHelpText:
			"Buyer side bar menu background color",
		buyer_sidebar_menu_color: "Buyer Menu Color",
		documentDownloadInProgress: "Document download in progress",
		pinCategory:
			"Do you want to set articles in  {category_name} as default presentation for products page?",
		unPinCategory:
			"Do you want to remove articles in this {category_name} as default presentation for products page?",
		clearCategoryFilter: "Clear Category Filters",
		recommended: "recommended",
		viewAll: "View All",
		draft: "Draft",
		article: "",
		categoryUpdated: "Category successfully updated",
		notificationUpdated: "Notification successfully updated",
		favIconText: "",
		blogPostUpdated: "Blog Post Updated Successfully",
		unpinBlog: "You are about to unpin this blog post",
		productImages: "Article Images",
		vat: "Vat",
		total: "Total",
		delivered: "Delivered",
		processing: "Processing",
		myProfile: "My Profile",
		buyerDetails: "Buyer Details",
		orderItems: "Order Items",
		action: "Action",
		handled: "Handled",
		received: "Received",
		markAsRead: "Mark as read",
		markAll: "Mark all",
		notificationNowMarkAsRead: "Notification now marked as read",
		videoGuides: "Video guides",
		noSubscriptionSelected:
			"Kindly select a valid subscription before proceeding with account creation",
		backToFortnoxConnect: "back to fortnox connect",
		subscriptionNotSelectedByCustomer:
			"Ops, system could not validate your account creation setup because you are yet to select a subscription, Navigate back to the our pricing page to select a subscription",
		integrations: "Integrations",
		yet_to_connect_to_integration:
			"You are yet to be connected to an accounting integration, Kindly click button below to get started",
		getStarted: "Get started",
		connectedBuyers: "Connected buyers",
		accessRequest: "Access Request",
		"status": {
			"processing": "Processing",
			"received": "Received",
			"delivered": "Delivered"
		}
	},
	se: {
		categoryName: "Kategori Namn",
		prices_given_ink_vat: "Angivna priser inklusive moms",
		apply_prices_given_ink_vat:
			"Om satt till True, kommer moms att inkluderas i artikelpriset.",
		current: "Tidigare: ",
		discounted: "Rabatterat",
		discountedValue: "Rabattvärde",
		discountType: "Rabatttyp",
		discountAmount: "Belopp",
		discountPercentage: "Procentsats",
		fields: {
			username: "Användarnamn",
			email: "E-Post",
			password: "Lösenord",
			domain: "",
			newPassword: "Nytt lösenord",
			firstName: "Förnamn",
			lastName: "Efternamn",
			setDomain: "",
			companyName: "Företag",
			phone_number: "Telefonnummer",
			repeatPassword: "Repetera lösenordet",
			confirmNewPassword: "Bekräfta nytt lösenord",
		},
		markAsRead: "Markera som läst",
		markAll: "Markera alla",
		validation: se.messages,
		defaultLang: "Sweden",
		_processing: "Hanteras",
		handled: "Hanteras",
		received: "Mottagen",
		onboardHeader: "Välkommen till B2B Portal",
		welcomeEntry: "One stop shop for Grossister & Återförsäljare",
		enterAccountInfo: "Ange kontouppgifter",
		toLoginPage: "Till inloggningssidan",
		serviceStart: "Önskar ni starta upp tjänsten",
		customerPortal: "Till Kundportalen",
		wouldConnect: "Redo att koppla på B2B portal",
		onBoardSeller: "Gå vidare",
		onBoardBuyer: "Återförsäljare",
		toDestination: "B2B portal",
		action: "Åtgärd",
		connectNow: "Ja, installera appen",
		signUpText: "Önskar ni starta upp tjänsten",
		proceedText:
			"Nästa steg är att välja prenumerationstyp och skapa ett konto så att ni kan komma igång direkt",
		codeAlreadyUse: "API nyckeln är redan förbrukad",
		username: "Användarnamn",
		password: "Lösenord",
		loginHeader: "Kundportal",
		forgetPassword: "Glömt lösenordet",
		companyInformation: "Företagsuppgifter",
		addressInformation : "Adress",
		RequestBecomeBuyer: "Ansök om att bli kund",
		resetPassword: "Återställ lösenord",
		newPassword: "Nytt lösenord",
		confirmNewPassword: "Bekräfta nytt lösenord",
		login: "Logga in",
		invoice: "Faktura",
		apply_english_on_all_templates: "Sätt engelska som språk vid kommunikation",
		loginSuccess: "Kontoinloggning lyckades",
		loginError: "Ogiltiga inloggningsuppgifter",
		fieldRequired: "Alla fält är obligatoriska",
		emailAddress: "E-postadress",
		customerAlready: "Redan en kund",
		resettingPassword: "Sparar",
		passGreater: "Lösenordet bör vara större än 6",
		passwordNotMatch: "Lösenorden matchar inte",
		accountCreated: "Kontot har skapats",
		serverError:
			"Hoppsan, något gick fel vid anslutningen..försök igen om en liten stund.",
		repeatPassword: "Upprepa lösenord",
		setDomain: "",
		createAccount: "Skapa konto",
		signUp: "Skapa konto",
		notCustomer: "Inte en kund ännu",
		creatingAccount: "Skapar konto",
		inviteCode: "Inbjudan ID",
		invite: "Bjud in",
		accept: "Acceptera",
		reject: "Avvisa",
		acceptStatement: "Godkänn ÅF-ansökan",
		rejectStatement: "Neka ÅF-ansökan",
		articles: "Artiklar",
		confirmPassword: "Bekräfta lösenord",
		chooseShop: "Välj butiksnamn",
		dashboard: "Lobby",
		orderHistory: "Beställningshistorik",
		invoiceHistory: "Fakturahistorik",
		orders: "Order",
		customerName: "Kund",
		orderNumber: "Ordernummer",
		orderType: "ordertyp",
		type: "Typ",
		orderDate: "Orderdatum",
		orderStatus: "Orderstatus",
		orderSummary: "Order översikt",
		reference: "Kundreferens",
		fileManagement: "Filhantering",
		productImageUpload: "Ladda upp bilder",
		search: "Sök",
		subscription: "Prenumeration",
		show: "Visa",
		invoiceName: "Faktura",
		invoiceNumber: "Fakturanummer",
		invoiceDate: "Fakturadatum",
		invoiceStatus: "Fakturastatus",
		invoiceSummary: "Överblick",
		product: "Produkter",
		youHave: "Du har",
		itemsInCart: "artiklar i kassan",
		subTotal: "Totalt",
		checkout: "Gå till Kassa",
		checkoutCustomization: "Kassaanpassning",
		logout: "Logga ut",
		brand: "Varumärke",
		totalOrdered: "Totalt beställda",
		remove: "Ta bort",
		totalAmountIncVat: "Inklusive moms",
		totalAmountExVat: "Exklusive moms",
		vatAmount: "Moms",
		deliveryDetails: "Leveransuppgifter",
		deliveryName: "Namn",
		deliveryAddress: "Leveransadress",
		deliveryZipCode: "Postnummer",
		deliveryCity: "Stad",
		deliveryCountry: "Land",
		email: "E-Post",
		ourReference: "Vår Referens",
		yourReference: "Er Referens",
		ourOrderNumber: "Er ordernummer",
		message: "Meddelande",
		sameBillingAddress: "Använd samma adress som fakturaadress",
		allowBackOrder: "Förhandsbeställning/Restorder Artikel",
		allowBackOrderHelpText: "Om markerad kan kunden köpa produkten oavsett lagersaldo. Lagersaldot kommer inte att vara synligt för kunden",
		preOrderItem: "Förhandsbeställning/Restorder Vara",
		buyProduct: "Köp",
		placeOrder: "Lägg order",
		pleaseWait: "Ett ögonblick..",
		disposableQty: "Antal",
		itemRemove: "Ta bort",
		itemAdd: "Produkter tillagda",
		orderConfirmation: "Orderbekräftelse",
		thankYouOrder: "Tack för din order!",
		proceedOrder:
			"kommer att hantera ordern och en e-postbekräftelse är skickad till ",
		date: "Datum",
		order: "Order",
		amount: "Belopp",
		seller: "Grossist",
		buyer: "Återförsäljare",
		customerSettings: "Kunder",
		users: "Användare",
		companySettings: "Kontoinställningar",
		totalOrderToday: "Antal ordrar idag",
		totalOrderMonth: "Antal ordrar denna månad",
		totalCustomerVisit: "Antal besökare",
		averageOrderAmount: "Ordervärde i genomsnitt",
		totalRevenue: "Total bruttoförsäljning",
		today: "Idag",
		revenueSales: "",
		bestSellingArticle: "Mest sålda artiklar",
		totalInvitedCustomers: "Antal inbjudna ÅF",
		totalPublishArticle: "Totalt artiklar",
		totalSalesMonth: "Försäljning/månad",
		customerHighestPurchase: "ÅF med högst inköpssumma",
		articleSetting: "Artiklar inställningar",
		articleNumber: "Artikelnummer",
		description: "Benämning",
		price: "Pris",
		priceListSelection: "Prislista",
		thumnails: "Produktbilder",
		inviteCustomer: "Bjud in ÅF",
		number: "Nummer",
		organizationNumber: "Orgnummer",
		category: "Kategori",
		status: "Status",
		notInvited: "Ej inbjuden",
		pendingInvitation: "Inbjuden",
		waitingInvite: "Inväntar svar",
		accepted: "Inbjudan accepterad",
		confirmed: "Ansluten",
		createUserCategory: "Skapa kundkategorier",
		customerCategoryName: "Kundkategori",
		accountNumber: "Försäljningskonto",
		costCenter: "Kostnadsställe",
		addUser: "Lägg till användare",
		firstName: "Förnamn",
		lastName: "Efternamn",
		addBtn: "Lägg till",
		adding: "Skapar",
		manageUser: "Administerera användare",
		userSetting: "Användar Inställningar",
		companyName: "Företag",
		companyNumber: "Orgnummer",
		zipCode: "Postnummer",
		city: "Stad",
		phoneNumber: "Telefonnummer",
		billingAddress: "Fakturaadress",
		billingDetail: "Fakturauppgifter",
		invoiceEmail: "E-post fakturautskick",
		shopDomain: "Butiksnamn",
		brandName: "Varumärke",
		syncCustomers: "Synka Kunder",
		selectPricelist: "Prislista",
		postInvoice: "Skapa kundfakturor i ekonomiprogrammet vid orderläggning",
		chooseCompanyLogo: "Välj företagslogga",
		applyCustomerPricelist: "Applicera stafflade kundprislistor",
		choose: "Kundspecifik",
		update: "Uppdatera",
		updating: "Uppdaterar...",
		updateShopUsers: "Uppdatera användare",
		inviteEndCustomer: "Bjud in ÅF",
		customerEmail: "Email",
		customerNumber: "Kundnummer",
		selectCategory: "Välj kategori typ",
		inviting: "Bjud in",
		createCustomerCategory: "Skapa kund kategori",
		selectCostCenter: "Kostnadsställe",
		create: "Skapa",
		creating: "Skapa",
		uploadLogo: "Ladda upp logga",
		chooseFile: "Välj fil",
		crop: "Beskär",
		cropping: "Beskär",
		finishUpload: "Spara",
		updateProductImage: "Uppdatera bild",
		articleDetail: "Artikeldetaljer",
		quantity: "Antal",
		dropImageFiles: "", // Släpp bilden här
		clickToUpload: "",
		fileFormat: "FilformatMax 4 bilder",
		maximumImages: "Max 4 bilder",
		uploading: "Laddar upp",
		updateArticle: "Uppdatera artikel",
		articleDescription: "Artikelbeskrivning",
		articleName: "Artikelnamn",
		articleVideoURL: "Artikelvideo URL",
		updateCustomerInfo: "Uppdatera kunduppgifter",
		articleImage: "Bild",
		removeUser: "Ta bort användare",
		continue: "Fortsätt",
		warning: "Varning",
		removeSuccessful: "Borttagen",
		updateUserSuccess: "Uppdaterad",
		enterValidPrice: "Ange pris",
		enterValidQty: "Ange antal",
		articleUpdateSuccess: "Artikel uppdaterad",
		removeImage: "Ta bort bild",
		imageRemoveSuccess: "Borttagen",
		yes: "Ja",
		no: "Nej",
		cancel: "Avbryt",
		addToCart: "Köp",
		cancelPurchase: "Avbryt köp",
		undoSelection: "Avmarkera",
		invalidOrganizationNumber: "Ogiltigt org nummer",
		customerInfoUpdated: "Kunduppgifter är uppdaterade",
		invitedSuccessful: "Inbjudan skickad",
		customerCategoryCreated: "Kund kategori skapad",
		invalidFormat: "Ogiltigt format",
		maximumFour: "max 4 siffror",
		fourOnly: "enbart 4 siffror",
		imageRequired: "Bild krävs",
		uploadSuccessful: "Uppladdad och klar",
		setUpAccount: "Skapa konto",
		invitedTo: "Du har blivit inbjuden till",
		settingUp: "Skapa",
		cartEmpty: "Kassan är tom",
		orderedSuccessfully: "Beställning lagd",
		pieces: "styck",
		errorPlacingOrder: "Ett fel uppstod, försök om en liten stund",
		costPrice: "Inköpspris",
		salesPrice: "Pris ex moms",
		vatRate: "Moms",
		margin: "Marginal",
		checkEmail: "Mejl skickat!",
		invalidUser: "Ogiltig e-mail",
		resetSuccessful: "Klart",
		selectFile: "Välj fil",
		optional: "Valfritt",
		selectSubscription: "Välj Avtalstyp",
		subscriptionSuccess: "Tack, din prenumeration är nu uppdaterad",
		next: "Nästa",
		currentSubscription: "Nuvarande Avtal",
		monthlyCost: "Månadskostnad",
		notAvailable: "Inte tillgänglig",
		massAssign: "Masshantera",
		bulkUpdate: "Mass redigera",
		fieldValue: "Värdet får ej vara längre än 50 tecken",
		download: "Ladda ner",
		invalidCellValue: "Ogiltigt värde",
		invitedUserSuccess: "Inbjudan skickad",
		successCompanyupdated: "Företagsuppgifter är nu uppdaterade",
		notify: "Notifieringar",
		noNotify: "Inga aktuella meddelanden",
		notFoundText: "Åh nej! Sidan hittas inte",
		invalidAccount: "Ogiltigt konto",
		backHome: "Tillbaka till startsidan",
		back: "Tillbaka",
		filter: "Filter",
		markRead: "Markera som läst",
		more: "Visa",
		less: "Dölj",
		save: "Spara",
		connecting: "Ansluter",
		addWebshop: "Lägg till webbutik",
		addProductCategory: "Skapa Produktkategori",
		updateProductCategory: "Uppdatering kategori",
		updateSubProductCategory: "Uppdatering underkategori",
		successfully: "Uppdaterad",
		updatedSuccess: "Uppdaterad",
		webshop: "Webbutiker",
		updatedWebshop: "Sparat",
		shopName: "Butiksnamn",
		note: "Anteckning",
		approachingLowStockQty: "Lågt saldo",
		criticalLowStockQty: "Kritiskt låg saldo",
		syncStockFromSupplier: "Synka leverantörens lager",
		placeAutomaticOrders: "Skapa automatisk inköpsorder",
		addWebshopFor: "Integrera webbutik",
		wellStocked: "God saldo",
		sufficientQty: "Tillräcklig saldo",
		webshopProduct: "Webbutik",
		productCategoryName: "Kategoribenämning",
		productCategorySuccess: "Kategori skapad",
		productCategory: "Produktkategorier",
		allProductCategory: "Visa alla produkter",
		assignSelectedProducts: "Kategorisera artiklar",
		assignSuccess: "Kategorisering slutförd",
		assignProduct: "Kategorisera",
		assignProgress: "Artikeln är kategoriserad",
		thresholdQty: "Saldo gräns",
		relevantCustomer: "Relevanta kunder",
		metricsOverview: "Statistik",
		productManager: "Produkthantering",
		phone: 'Telefon',
		enterPhone: 'Ange ditt telefonnummer',
		view: "Visa",
		itemOutOfStock: "Varan är ej tillänglig för beställning",
		supplier: "Leverantörer",
		isEqualTo: "Är lika med",
		isGreaterThan: "Är större än",
		isBetween: "Är emellan",
		isLessThan: "Är lägre än",
		clear: "Rensa",
		done: "Spara",
		supplierMatch: "Match Leverantörsartikel",
		supplierQuantity: "Leverantör Kvantitet",
		unavailable: "Ej i lager",
		name: "Namn",
		totalMonth: "Totalt denna månad",
		averageMargin: "Genomsnitt vinstmarginal",
		averagePerOrder: "Genomsnitt vinst/order",
		selectParentCategory: "Välj huvudkategori",
		addSubCategory: "",
		subCategoryName: "Titel underkategori",
		subCategorySuccess: "Underkategori tillagd",
		addCategory: "Spara",
		source: "Källa",
		productWithImage: "Produkt med bilder",
		bankIdAuth: "Bank ID Autentisering",
		bankIDStatement: "Signera med BankId för att godkänna köpet på",
		validBankIDFormat: "Giltigt format",
		connectShopify: "Anslut till Shopify",
		connectFortnox: "Anslut till Fortnox",
		settings: "Inställningar",
		billing: "Betalningar",
		myAccount: "Kontouppgifter",
		amountDue: "Belopp",
		dueDate: "Förfallodatum",
		file: "Filer",
		paid: "Betald",
		unPaid: "Obetald",
		requireBankIDforAllOrders: "Kräv BankID signering för alla ordrar",
		requireBankIDThresholdAmount:
			"Kräv BankID signering enbart för ordrar över angiven summa",
		applyCustomerSpecificBasedMaxCredit: "Sätt kundspecifik kreditgräns",
		applyGeneralCreditforAllCustomers:
			"Sätt generell kreditgräns på angiven summa för alla kunder",
		CustomerSpecific: "Kundspecifik",
		applyStockThreshold: "Tröskelvärde för lagersaldo",
		orderAmount: "Ordersumma",
		verifyBankId: "Startar BankID signering",
		connectedSupplier: "Anslutna leverantörer",
		connectIntegration: "Anslutna Webbutiker",
		selectCategoryBelow:
			"Välj & klicka bland nedan kategorival för artikeln",
		nonConnectedSupplier: "Ej Anslutna leverantörer",
		maxCreditLimit: "Max Kreditgräns",
		priceManagement: "Produktpriser",
		"You will be notified when this article is back in stock" : "Tack! Du får en mejlavisering när artikeln är åter i lager",
		categoryManagement: "Produktkategorier",
		syncingCustomers: "Synkar kunder med Fortnox",
  		syncingDescription: "Databehandlingen kan ta upp till 2 minuter, var god dröj...",
		parentCategory: "Huvudkategori",
		childCategory: "Underkategori",
		selectCategoryParent: "Välj huvudkategori",
		removeParentCategory: "Radera huvudkategori",
		removeChildCategory: "Radera kategori ",
		totalOrderAmount: "Total Ordersumma",
		customerManagement: "Kundhantering",
		totalConnectedSuppliers: "Antal anslutna grossister",
		totalOrderAmountMonth: "Total ordersumma/mån",
		totalProducts: "Antal produkter",
		customerCategory: "Kundkategorier",
		customerCategoryManagement: "Kundkategori",
		customerCategoryList: "Kategori lista",
		maxLimitErrorMessage:
			"Ni har överskridit er kreditgräns, vänligen kontakta er leverantör",
		selectCompany: "Välj bolag",
		shopNameRequire: "Butiksnamn",
		invalidUpdateResponse: "Hoppsan, något gick fel",
		clickToEdit: "Redigera",
		appLogs: "Händelselogg",
		logInitiator: "Användare",
		logStatus: "Status",
		logs: "Händelselogg",
		priceNotAvailable: "Pris ej tillgänglig",
		productVideo: "Produkt Videor",
		relevantArticle: "Relevanta artiklar",
		selectPricelistIndicator: "Prislista",
		updatePriceList: "Prislista",
		relevantArticleCustomers:
			"Synliggör artikeln för enbart specifika kunder",
		searchCustomers: "Sök kunder",
		select: "Välj",
		setRelevantArticles: "Sök artiklar",
		setVisible: "Synlig",
		setHidden: "Dold",
		selectCustomers: "Välj kund",
		userCustomization: "Konfigurering",
		menuColor: "Meny färg",
		buttonColor: "Knapp färg",
		inviteEmailSubject: "E-post inbjudan ämnesfält",
		InviteEmailHeader: "E-post inbjudan titel",
		inviteEmailContent: "E-post inbjudan text",
		inviteEmailSubjectEn: "E-post inbjudan ämnesfält Engelska",
		InviteEmailHeaderEn: "E-post inbjudan titel Engelska",
		inviteEmailContentEn: "E-post inbjudan text Engelska",
		linkColor: "Länk färg",
		pricelistUpdated: "Prislista uppdaterad",
		customization: "Konfigurering",
		invited: "Inbjuden",
		connected: "Ansluten",
		companyLogo: "Företagslogga",
		storeName: "Butiksnamn",
		requestAccess: "Begär tillgång",
		viewDetails: "Se detaljer",
		logType: "Händelsetyp",
		startDate: "Startdatum",
		endDate: "Slutdatum",
		logError: "Felmeddelande",
		logInfo: "Info",
		noDescription: "Ingen beskrivning",
		personalNumber: "Fortsätt",
		reConnectNow: "Anslut till Fortnox",
		invalidProductURL: "Ogiltig url",
		skipTour: "Hoppa över guiden",
		previousTour: "Föregående",
		nextTour: "Nästa",
		finishedTour: "Klart!",
		dashboardTourMenu:
			"Här hittar du en enkel översikt över kund, artikel och försäljningsstatistik",
		articleTourMenu:
			"Under artiklar kan ni administrera era produkter som ska synliggöras för ÅF",
		articleTourTableAction: "Ladda upp produktbilder gör ni här.",
		articleTourTableProductImg:
			"Klicka in på bilderna för att se i större vy och även radera",
		articleTourTableRelevantProduct:
			"Ställ in enkelt de produkter som ska synliggöras för ÅF och är relevanta för B2B portalen. Det går även bra att synliggöra vissa produkter enbart för specifika återförsäljare",
		articleTourTableCheckAll:
			"Genom att bocka för en artikel och sedan klicka på “Uppdatera Artikel” kan man redigera den såsom länka till video, kategorisera o.s.v",
		articleTourProductCategory:
			"Skapa Artikel kategorier med huvud och underkategorier så att ÅF kan enkelt navigera och hitta rätt.",
		articleTourPriceManagement:
			"Klicka på Prishantering för att överse vinst marginaler, uppdatera priser, filtrera priser per prislista och mass redigera priser i ekonomiprogrammet.",
		invoiceTourMenu:
			"Om ni valt att ordrar ska skapas upp i Ekonomiprogrammet hittar ni ÅF ordrar under Beställningshistorik, annars under Faktureringshistorik",
		customerTourMenu:
			"Konfigurera inställningar för era kunder för Fakturainställningar och Kundvy",
		relevantCustomerTour:
			"Ställ in vilka kunder som är relevanta för ÅF portalen",
		customerTourTableAction:
			"Klicka på Redigera för att ställa in specifika kundkategorier, intäktskonton och kostnadsställe på kundnivå. Detta kommer sedan reflekteras i ordern/kundfakturan som skapas upp i ekonomiprogramme",
		customerTourTopCategory:
			"Klicka här för att skapa kundkategorier och dess förinställningar",
		userTourMenu: "Bjud in och radera användare enkelt via denna sida",
		companySettingTourMenu:
			"Under kontoinställningar kan ni konfigurera appen utifrån önskade beteenden och även skräddarsy innehållet i ÅF inbjudan mejlet",
		companySettingTourCustomization:
			"Komponera egen text för ÅF inbjudan mejl och om ni så önskar kan ni skräddarsy portalens färgpalett.",
		companySettingTourSetting:
			"Konfigurera appen utifrån era behov, t.ex sätt kundspecifika kreditgränser, välj om BankID signering ska krävas, om stafflade kundprislistor ska appliceras samt vilket tröskelvärde ska användas för lagertillgänglighet.",
		companySettingTourBilling:
			"Under Betalningar ser ni era avtalsfakturor för tjänsten",
		companySettingTourAccount:
			"Här hittar ni era kontouppgifter och kan ställa in de IBAN, Bankgiro, Plusgiro som ska synliggöras för ÅF i Fakturan.",
		subscriptionTourMenu:
			"Önskar ni ner eller uppgradera ert avtal så gör ni det enkelt under Prenumerations vyn",
		logsTourMenu:
			"I Händelselogg ser du när och vem som har gjort vad i programmet. Du kan välja att visa händelserna inom ett visst datumintervall, för ett visst meddelandetyp eller för en specifik användare.",
		buyerDashboardTourMenu:
			"I Lobbyn hittar du alla anslutna leverantörer och kan klicka in i butiken för att börja handla från vald leverantör. Du kan även hitta leverantörer som du ej är ansluten tilloch ansöka om att få bli ÅF.",
		buyerOrdersTourMenu:
			"Om er leverantör valt att ordrar ska skapas upp i Ekonomiprogrammet hittar ni ordrar under Beställningshistorik, annars under Faktureringshistorik.",
		buyerInvoiceTourMenu:
			"Om er leverantör valt att ordrar ska skapas upp i Ekonomiprogrammet hittar ni ordrar under Beställningshistorik, annars under Faktureringshistorik.",
		buyerUsersTourMenu:
			"Bjud in andra användare enkelt, t.ex inköpsansvarig eller andra anställda",
		buyerIntegrationTourMenu:
			"Under Integration kan ni integrera med webbutiker eller ekonomiprogrammet för att kunna synkronisera ert lager med er leverantörs.Det finns även en funktion att ställa in automatisk orderläggning när artikelsaldot blir lågt",
		buyerIntegrationProductTourMenu:
			"Era anslutna integrationer och Produkt spårnings sidan hittar ni här",
		buyerSubscriptionTourMenu:
			"Önskar ni ner eller uppgradera ert avtal så gör ni det enkelt under Prenumerations vyn",
		buyerLogTourMenu:
			"I Händelselogg ser du när och vem som har gjort vad i programmet. Du kan välja att visa händelserna inom ett visst datumintervall, för ett visst meddelandetyp eller för en specifik användare",
		blog: "Inlägg",
		createBlog: "Skapa inlägg",
		editBlog: "Redigera blogg",
		allPost: "Alla inlägg",
		blogTitle: "Titel",
		blogContent: "Innehåll",
		blogTag: "Taggar",
		publishDate: "Startdatum",
		publishEndDate: "Slutdatum",
		createdDate: "Skapad",
		blogList: "Alla inlägg",
		massDelete: "Radera",
		addBlogImage: "Lägg till Bild",
		addBlogCategory: "Lägg till Kategori",
		addBlogDatePublish: "Lägg till publicerings datum",
		publishDatePast: "Datum kan ej vara bak i tid",
		publishEndDatePast:
			"Publiceringsslutdatum måste vara högre än publiceringsdatumet",
		publish: "Publicera",
		publishing: "Publicerar",
		preview: "Förhandsvisa",
		addNewCategory: "Skapa kategori",
		uploadBlogImage: "Ladda upp bild",
		dateRange: "Datum intervall",
		execute: "Utför",
		published: "Publicerad",
		updateCategory: "Uppdatera kategori",
		deleteBlogSuccess: "Blogginlägget har tagits bort",
		noBlogPost: "Du har ännu inte skapat ett blogginlägg",
		noWebshop: "",
		unAuthorizedShopify: "",
		updateBtn: "Spara",
		blogPreviewTitle: "Förhandsgranskning",
		postDeleted: "Inlägget är raderat",
		noPostSeleted: "Inget blogginlägg har valts",
		categories: "Kategorier",
		archives: "Arkiv",
		showMore: "visa mer",
		showLess: "visa mindre",
		readMore: "Läs mer",
		goToStore: "Till Webbshop",
		january: "Januari",
		february: "Februari",
		march: "Mars",
		april: "April",
		may: "Maj",
		june: "Juni",
		july: "Juli",
		august: "Augusti",
		september: "September",
		october: "Oktober",
		oct: "Okt",
		november: "November",
		december: "December",
		updateBlogCategory: "Uppdatera bloggkategori",
		faqz: "FAQ",
		faqzFullName: "Vanliga frågor",
		inviteEmailSubjectHelpText: "Ämnes titlen för ÅF e-post inbjudan",
		inviteEmailHeaderHelpText:
			"Titlen för ÅF e-post inbjudan (T.ex välkommen som kund)",
		inviteEmailContentHelpText: "Texten för ÅF e-post inbjudan",
		menuColorHelpText: "Färg för sidomenyn",
		linkColorHelpText: "Länk färg",
		buttonColorHelpText: "Knapp färg",
		brandNameHelpText: "Varumärkes namn",
		storeDescriptionHelpText: "Butiksbeskrivning som syns för ÅF",
		postInvoiceToFortnoxHelpText:
			"Om rutan är inbokad skapas fakturor i ekonomiprogrammet. Om den är ej inbokad skapas Ordrar i ekonomiprogrammet.",
		applyStockThresholdHelpText:
			"Visar produkter som ej tillgängliga i butiken för kunden om lagerantalet i ert ekonomiprogram är lägre än angiven antal..",
		applyCustomerPricelistHelpText:
			"Tillämpar stafflade kundprislistor i webbshopen baserat på prislistan som är kopplad till kunden i ekonomiprogrammet.",
		require_bank_id_for_for_orders_above_certain_amountHelpText:
			"Kräv BankID signering för alla köp över en viss summa.",
		applyCustomerSpecificBasedMaxCreditHelpText:
			"Tillämpa kreditgräns för kunder. Kreditgräns summan sätt under Kundinställningar och förhindrar att kunden kan lägga order över en viss summa per månad med faktura.",
		applyGeneralCreditforAllCustomersHelpText:
			"Tillämpa generell kreditgräns med samma summa för alla kunder",
		requireBankIDforAllOrdersHelpText:
			"Kräv BankID signering för alla köp oavsett order summa",
		chooseCompanyLogoHelpText: "Ladda upp företagslogga",
		CompanyNameHelpText: "Företagsnamn",
		cityHelpText: "Stad",
		phoneNumberHelpText: "Telefonnummer",
		companyNumberHelpText: "Organisationsnummer",
		emailHelpText: "E-post till kontaktperson",
		invoiceEmailHelpText: "E-post för fakturautskick",
		bankGiroHelpText: "Ert bankgiro nummer som syns på fakturan",
		IBANHelpText: "Ert IBAN nummer som syns på fakturan",
		plusgiroHelpText: "Ert plusgiro nummer som syns på fakturan",
		shopNameHelpText: "Butiksnamn",
		woo_consumer_keyHelpText:
			"API nycklar som ni hämtar inne i Woocommerce, under Avancerade inställningar",
		woo_consumer_secretHelpText:
			"Tänk på att ange både Läs/Skriv rättigheter",
		approaching_low_stock_quantityHelpText:
			"Lagersaldo antal för lågt saldo. Den siffra ni anger här kommer kopplingen följa för att avisera er om när ert lagersaldo når en låg punkt",
		critical_low_stock_quantityHelpText:
			"Lagersaldo antal för kritisk lågt saldo. Den siffra ni anger här kommer kopplingen följa för att avisera er om när ert lagersaldo når en kritisk låg punkt.",
		sufficient_stock_quantityHelpText:
			"Lagersaldo antal för tillräckligt saldo. Den siffra ni anger här kommer kopplingen följa för att avisera er om när ert lagersaldo ligger på en acceptabel nivå.",
		syncStockFromSupplierHelpText:
			"Synka ert lager med leverantörens lager.",
		placeAutomaticOrdersHelpText:
			"Lägg automatiska ordrar när lagersaldot når låg nivå.",
		billingAddressHelpText: "Faktureringsadress",
		invalidCredential:
			"systemet kunde inte validera dina autentiseringsuppgifter",
		title: "Titel",
		send_invoices_to_nox_finance: "Skicka fakturor via Nox Finans",
		send_to_noxHelpText:
			"Vid ja så kommer fakturor skapas upp i Fortnox och direkt därefter skickas via Nox Finans. Leveranssättet baseras på det som är förinställd på kundkortet i Fortnox",
		UpdateArticleCategory: "Uppdatera artiklar kategori",
		finallyDelivered: "SLUTLEVERERADE",
		finallyPaid: "SLUTBETALDA",
		NotDelivered: "ICKE LEVERERADE",
		storeDescription: "Butiksbeskrivning",
		removeBlogCategory: "“Är du säker på att du vill radera kategori",
		blogAddedSuccess: "Blogginlägget har lagts till",
		blogUpdatedSuccess: "Uppdaterat blogginlägget",
		priceExcludeTax: "*Priser anges ex moms",
		termsAndCondition: "Jag har läst och godkänner",
		privacyLink: "köpvillkoren",
		unSelectedTermsCondition: "Vänligen ange att du godkänner våra villkor",
		privacyPolicy: "Sekretess och juridik",
		general: "ALLMÄNT",
		generalContent:
			"Det här avtalet gäller när vi på Connectivo AB, org. nr. 556932-7777, tillhandahåller tjänster till er.\n" +
			'I detta Avtal betyder ”vi”, ”oss” , "leverantören" och ”B2B Portal" Connectivo AB och ”ni”,\n' +
			"”er” eller ”kunden” ni som kund.",
		trademarks: "Varumärken",
		certainDisclaimer: "Vissa ansvarsfriskrivningar",
		confidentialInformation: "Konfidentiell information",
		globalAvailability: "Global tillgänglighet",
		linkTosite: "Länka till denna webbplats",
		thirdPartyWebsite: "Tredjepartswebbplatser",
		thirdPartyProductServices: "Produkter och tjänster från tredje part",
		collectionTechnicalInformation:
			"Insamling och användning av teknisk information",
		useOfCookie: "Användning av kakor",
		jurisdiction: "Jurisdiktion",
		disclaimerOfGuarranty: "FRISKRIVNING AV GARANTI",
		limitationLiability: "ANSVARSBEGRÄNSNING",
		loginRequest: "Sessionen har löpt ut, logga in igen",
		definitions: "DEFINITIONER",
		definitionsContent:
			"Nedan listas en del ord som används genomgående i Avtalet. Orden ska ha den betydelse som anges\n" +
			"nedan.\n",
		connectionPoint: "Anslutningspunkten",
		connectionPointContent:
			"Den punkt där våra Tjänster görs tillgängliga för er.",
		userHeader: "Användare",
		userHeaderContent:
			"Den person som ni har givit tillgång till Tjänsterna. Användare ska vara anställd hos er, eller annars\n" +
			"jobba för er räkning, till exempel en konsult eller redovisningsekonom",
		agreement: "Avtal",
		agreementContent:
			"Det här avtalet inklusive bilagor och underbilagor.\n",
		customerData: "Kunddata",
		customerDataContent:
			"Data som ni på något sätt, själv eller genom Användare, överlämnar till oss eller som vi annars får\n" +
			"del av genom er användning av Tjänsterna.\n",
		customerAgreement: "Kund med Byråavtal",
		customerAgreementContent:
			"Customer who has entered into an agreement with an accounting firm or similar company for financial services\n" +
			"based on the Services.",
		systemAdministrator: "Systemadministratör",
		systemAdministratorContent:
			"Den person som tecknat Tjänsterna för er räkning, eller som annars har utsetts av er till\n" +
			"Systemadministratör. Kund med Byråavtal kan ha en egen systemadministratör.\n",
		serviceOrServices: "Tjänsten eller Tjänsterna",
		serviceOrServicesContent:
			"Molnbaserade prenumerationstjänster avseende integration av webbapplikationer och\n" +
			"automatisering av manuella processer inom löpande bokföring. Tjänsten innefattar leverans av\n" +
			"standardiserade integrationer. Skräddarsydda och kundspecifika integrationer innefattas ej av denna\n" +
			"tjänst, utan kan beställas som en separat tjänst.",
		conclusionOrder: "AVTALETS INGÅENDE OCH BESTÄLLNING",
		conclusionOrderContent:
			"Avtal avseende Tjänsten ska ingås skriftligen antingen via e-post, via beställning på hemsidan, ww.bridgero.se, eller via App Marknadsplatser.\n" +
			"Den dag Kunden fullföljt beställningen utgör ”Avtalsdagen”.\n" +
			"Kundens minimiåtagande per månad enligt Avtalet ska beräknas som summan av de månadsavgifter\n" +
			"som Kunden ska betala för det totala antal licenser som Kunden beställt vid Avtalets ingående.\n" +
			"Kundens minimiåtagande per månad framkommer i prislistan som är publicerad på hemsidan och\n" +
			"kan ej ändras under Avtalets bindningstid.\n" +
			"Beställning av ytterligare/nya tjänster (inklusive licenser för användare och abonnemang utöver\n" +
			"Kundens minimiåtagande) eller Produkter efter det att Avtalet ingåtts ska göras på det sätt\n" +
			"Leverantören anvisar.",
		rightOfwithdrawal: "ÅNGERRÄTT",
		rightOfwithdrawalContent:
			"Nedladdning och nyttjande av digitala produkter klassas som en tjänst som inte kan återlämnas då \n" +
			"det är en så kallad direktkonsumtion. Den digitala verifikationsprocessen motsvarar en plombering" +
			"av en fysisk vara.",
		delivery: "LEVERANS",
		deliveryContent:
			"Tjänstens konfiguration och aktivering levereras i form av en kundportal där kund ansvarar för att\n" +
			"följa anvisningar och konfigurera sina inställningar.\n" +
			"Om Kunden anser att det finns fel eller brister i leveransen av Tjänsten, ska Kunden skriftligen\n" +
			"informera Leverantören därom och tillse att relevant skriftlig dokumentation och felbeskrivning är\n" +
			"Leverantören tillhanda senast tio (10) dagar räknat från Leveransdatum. Leverantören ska därefter\n" +
			"och inom skälig tid vidta nödvändiga korrigerande åtgärder. Om Kunden inte anmält fel eller brist i\n" +
			"enlighet med ovan anses Tjänsten felfritt levererad.\n" +
			"Fel eller ändringar efter nyssnämnda tid hanteras enligt vad som anges under punkt 16\n" +
			"(Felavhjälpning) nedan. Om leverans inte kan ske och detta beror på att Kunden inte fullgjort sina\n" +
			"åtaganden enligt Avtalet äger Leverantören rätt att fakturera samtliga avgifter från Avtalsdagen.",
		deliveryGuarranty: "LEVERANSGARANTI",
		deliveryGuarrantyContent1:
			"• Om Leveransdatum inte ägt rum senast nittio (90) dagar från Avtalsdagen, har Kunden rätt att kräva\n" +
			"avdrag med belopp motsvarande femtio (50) procent av den obrukbara Tjänstens erlagda fasta avgift",
		deliveryGuarrantyContent2:
			"Om Leveransdatum inte ägt rum senast etthundratjugo (120) dagar från Avtalsdagen, har Kunden\n" +
			"rätt att häva Avtalet. Om Avtalet hävs ska samtliga prestationer återgå och samtliga förhållanden\n" +
			"anses reglerade, bl.a. med innebörd att ingen ytterligare ersättning utöver att av Kunden eventuellt\n" +
			"erlagda avgifter ska återbetalas till Kunden.",
		deliveryGuarrantyContent3:
			"• Leveransgarantin i denna punkt gäller endast om förseningen enligt ovan uteslutande beror på\n" +
			"förhållanden hänförliga till Leverantören. Så anses inte vara fallet exempelvis om",
		deliveryGuarrantyContent4:
			"- Förseningen helt eller delvis är hänförlig till annan tredjepart än Leverantören eller om förseningen" +
			"helt eller delvis är hänförlig till Kunden, vilket kan vara fallet om Kunden påverkar eller ändrar" +
			"datum för Leveransdatum eller Kunden inte uppfyllt sina åtaganden enligt Avtalet, såsom" +
			"installation och aktivering av tjänsten.",
		deliveryGuarrantyContent5:
			"- Uppgift i Avtalet eller i Kunddatainformation är felaktig och Leverantören inte kan få korrekt\n" +
			"uppgift (bekräftad) av Kunden före planerad installation.\n" +
			"-Force Majeure föreligger",
		customerObligation: "KUNDENS ÅLIGGANDEN",
		customerObligationContent1:
			" Kunden ska se till att alla uppgifter som anges angående kontaktuppgifter, faktureringsinformation" +
			"eller kreditkortsinformation är korrekta och förbinder sig att uppdatera dessa uppgifter regelbundet ",
		customerObligationContent2:
			"• Kunden skall ansvara för den verksamhet som bedrivs av Kunden och dess medlemmar och ska" +
			"säkerställa att den nationella lagstiftningen följs. ",
		customerObligationContent3:
			"• Kunden ansvarar för att kontinuerligt kontrollera importerad data till ekonomiprogrammet och" +
			"hålla uppdaterad dess programinställningar för brukande av programvara. ",
		customerObligationContent4:
			"• Kunden ansvarar för att inställningar i ekonomiprogrammet ställs in i enlighet med instruktioner" +
			"som tillhandahålls av B2B Portal och aktivering av levererad tjänst. ",
		customerObligationContent5:
			"Kunden samtycker till att inte reproducera, duplicera, kopiera, sälja, återförsälja eller exploatera\n" +
			"någon del av bridgero.se, användning av bridgero.se, eller tillgång till bridgero.se. Återförsäljning av bridgero.se är endast tillåtet med skriftligt samtycke från\n" +
			"Connectivo AB och ett återförsäljaravtal från Connectivo AB (som Connectivo AB förbehåller sig\n" +
			"rätten att när som helst avsluta och som kan omfatta vissa villkor).",
		customerObligationContent6:
			"Den som undertecknar detta avtal genom köp av tjänst garanterar att han/hon är ansvarig för att se\n" +
			"till att tillstånd finns för att binda Kunden genom ett sådant utförande",
		customerObligationContent7:
			"Kunden ansvarar för att hålla användarinformation, lösenord och SIM-kort med tillhörande koder\n" +
			"väl skyddade så användande av obehörig förhindras.",
		customerObligationContent8:
			"Kunden ansvarar för att informera berörda användare hos Kunden om att Leverantören kan komma\n" +
			"att skicka information om Leverantörens produkter och tjänster.",
		responsibiltyClaims: "Ansvar för eventuella krav som riktas mot oss",
		responsibiltyClaimsContent:
			"Om någon annan riktar krav mot oss på grund av er eller era Användares användning av Tjänsterna\n" +
			"eller tredje parts tjänster ska ni hålla oss skadelösa genom att ersätta oss för den skada eller förlust vi\n" +
			"lidit på grund av kravet. Exempel på sådana krav är begäran om ersättning för intrång i tredje parts\n" +
			"immateriella rättigheter så som upphovsrätt eller varumärke. Vi ska även ha rätt att ge vidare rätten\n" +
			"att framställa sådan begäran och därmed låta någon annan framställa begäran.",
		customerDataInformation: "KUNDDATAINFORMATION",
		customerDataInformationContent:
			"Leverantören behandlar Kunddatainformation för att tillhandahålla och säkerställa driften av\n" +
			"Tjänsten enligt Avtalet, för att fullgöra skyldighet enligt lag eller annan författning samt för\n" +
			"fakturering. Leverantören får använda Kunddata för att fullgöra uppdraget, för statistiska ändamål,\n" +
			"för att förbättra och utveckla våra Tjänster samt för marknadsföring.\n" +
			"Kunden ska på Leverantörens begäran lämna den kunddatainformation som Leverantören behöver\n" +
			"för att tillhandahålla Tjänsten (”Kunddatainformation”).\n" +
			"Kunden svarar för att samtlig Kunddatainformation är korrekt samt för att att Kunddata är fri från\n" +
			"virus, trojaner, maskar eller annan programvara eller kod som kan skada Tjänsterna.\n" +
			"Kund ska även ersätta Leverantör för all skada som åsamkas direkt eller indirekt på grund av att\n" +
			"innehållet i Kunddata eller användningen av Kunddata i Tjänsterna strider mot tillämplig\n" +
			"lagstiftning.\n" +
			"Som kund kan ni alltid ta ut Kunddata från Tjänsterna. I samband med att ni säger upp Avtalet är det\n" +
			"upp till er att ta ut all Kunddata från Tjänsterna som ni kan komma att behöva för framtida bruk.",
		priceAndPayment: "PRISER OCH BETALNING",
		priceAndPaymentContent:
			"Såvida inte parterna kommit överens om annat gäller de priser för Tjänsterna som vid tidpunkten\n" +
			"som parterna ingåt Avralet publicerats på B2B Portal hemsida. Detsamma gäller vid\n" +
			"förlängning av Avtalet.\n" +
			"Tjänstens fasta avgifter debiteras månadsvis eller årsvis, antingen via kortbetalning eller faktura.\n" +
			"Betalningsvillkor för faktura är 15 dagar, såvida inte Parterna kommit överens om något annat.\n" +
			"Vid betalning efter förfallodagen debiteras dröjsmålsränta enligt lag. Leverantören äger dessutom\n" +
			"rätt till ersättning för påminnelseavgift och inkassokostnader. Invändning mot faktura ska vara\n" +
			"Leverantören tillhanda senast tio (10) dagar efter fakturadatum. Invändning ska göras skriftligen\n" +
			"med angivande av skäl till varför Kunden invänder mot fakturan. Leverantören har rätt att med\n" +
			"omedelbar verkan säga upp Avtalet om betalning av faktura inte har skett trettio (30) dagar efter\n" +
			"förfallodatum.\n",
		priceAndPaymentContent2:
			"Varje integration innefattas av två (2) typer av kostnader - en licensavgift samt en återkommande\n" +
			"månadsavgift för molntjänsten.\n" +
			"I samband med köp av integration är det kundens ansvar att säkerställa att korrekt nivåpaket väljs så\n" +
			"att tjänsten täcker kundens månatliga ordervolym. Connectivo AB förbehåller sig rätten att i\n" +
			"efterhand fakturera kunden i de fall kunden överstiger den ordervolym som valt nivåpaket täcker.",
		priceAndPaymentContent3:
			"Vi har rätt att höja priset, med direkt verkan, om höjningen beror på omständigheter vi inte kan\n" +
			"kontrollera, till exempel förändring av valutakurs, skatt eller annan omständighet av ekonomisk\n" +
			"betydelse som påverkar kostnaden för att tillhandahålla Tjänsterna.\n" +
			"Vi har också rätt att höja priset i andra fall men då måste vi kontakta er senast trettio (30) dagar\n" +
			"innan vi höjer priset för att informera er om förändringen. Det nya priset börjar gälla först när nästa\n" +
			"betalningsperiod börjar. Ni har möjlighet att till dagen innan prishöjningen träder ikraft säga upp\n" +
			"Avtalet med oss. Om ingen uppsägning görs av er, anses ni ha godkänt den nya prissättningen.\n",
		agreementTimeAndTermination: "AVTALSTID OCH UPPSÄGNING AV AVTALET",
		agreementTimeAndTerminationContent:
			"Kunden väljer själv vid köptillfälle om denne önskar med bindningstid eller utan bindningstid på\n" +
			"avtalet. Om inte annat avtalats, ä bindningstiden 12 månader och förlängs automatiskt med ett år i\n" +
			"taget, om avtalet inte sägs upp av endera parten senast 30 dagar före avtalstidens utgång.\n" +
			"Avtalet ska sägas upp skriftligen en (1) månad innan avtalstiden löper ut. Om Avtalet inte sagts upp\n" +
			"förlängs Avtalet med tolv (12) månader åt gången, med en uppsägningstid om en (1) månad innan\n" +
			"varje ny tolvmånadsperiod.\n" +
			"Utöver vad som annars anges i detta Avtal har vi båda rätt att med omedelbar verkan säga upp\n" +
			"Avtalet om den andra av oss har:",
		agreementTimeAndTerminationContent1:
			"brutit mot Avtalet och inte rättar till det inom trettio (30) dagar efter att ha mottagit den andra\n" +
			"partens skriftliga meddelande om avtalsbrott, eller",
		agreementTimeAndTerminationContent2:
			"inleder likvidationsförfarande, ansöker om eller försätts i konkurs, ställer in betalningarna eller\n" +
			"på annat sätt kan befaras vara på väg mot obestånd.",
		supportTroubleShooting: "SUPPORT/FELAVHJÄLPNING",
		supportTroubleShootingContent:
			"I Tjänsten ingår, utöver tillgång till serviceportalen som nås via www.bridgero.se, teknisk\n" +
			"och administrativ support via mail och telefon som avser att hjälpa Kunden med frågor avseende\n" +
			"Tjänsten, bistå med nödvändig support från Leverantörens leverantörer, enklare\n" +
			"ändringar/konfigurationer och tillägg/borttag i Tjänsten samt felsökning i Tjänsten om Fel\n" +
			"konstateras i samband med felsökningen.\n" +
			"Ni kan vända er till oss med frågor relaterade till hur våra Tjänster fungerar och ska användas. Vi\n" +
			"kan däremot inte erbjuda support i frågor om till exempel bokföring eller frågor av teknisk karaktär\n" +
			"som inte handlar om Tjänsten.\n" +
			"Ni kan kontakta oss genom de kontaktuppgifter som finns på vår hemsida. Aktuella öppettider för\n" +
			"supporten hittar du på vår hemsida.\n" +
			"Tjänsten omfattar inte support som bland annat men ej uteslutande innebär: felsökning i Kundens,\n" +
			"tredje mans eller annan leverantörs program, support avseende Kundens utrustning, konfiguration\n" +
			"som inte tillhandahållits av Leverantören, support som inte avser Tjänsten, utbildning, längre\n" +
			"telefonmöten, rådgivning eller konsultation. Sådan support debiteras i enlighet med Leverantörens\n" +
			"vid var tid gällande standardprislista",
		supportTroubleShootingContent2:
			"Med ”Fel” avses att Kunden inte kan använda Tjänsten i enlighet med Avtalet och att felet beror på\n" +
			"Leverantören.\n" +
			"Leverantören ska avhjälpa Fel kostnadsfritt inom skälig tid. Med Fel avses inte, exempelvis men ej\n" +
			"uteslutande, brister som:",
		supportTroubleShootingContent3:
			"är av mindre betydelse för Kunden eller som inte hindrar Kunden från att använda Tjänsten,\n" +
			"orsakats av tredje man eller genom omständigheter utanför Leverantörens kontroll.",
		supportTroubleShootingContent4:
			"avser annat än Tjänsten, exempelvis avseende tjänster, produkter och leveranser som sker genom\n" +
			"annan leverantör.\n",
		supportTroubleShootinfContent5:
			"Felanmälningar hanteras av Leverantörens support. Felanmälan sker genom e-post eller\n" +
			"telefonsamtal. Det åligger Kunden att vara supporten behjälplig i felsökning och återkoppla inom 48\n" +
			"timmar med nödvändig information för fortlöpande sökning. Om återkoppling inte sker stängs\n" +
			"ärendet och betraktas som avslutat utan ansvar för Leverantören.\n" +
			"16.8. Felanmälan kan göras dygnet runt, alla årets dagar på info@bridgero.se.\n" +
			"Felanmälan som inkommer på annan tid än på helgfri vardag mellan 08.00-17.00 anses inkommen\n" +
			"nästkommande helgfri vardag kl. 08.00.",
		responsibilityAccountingData: "ANSVAR FÖR BOKFÖRINGSDATA",
		responsibilityAccountingDataContent:
			"Leverantören ansvarar för vidareutveckling av Tjänsten och bestämmer ensam vilka förbättringar,\n" +
			"förändringar och tekniska anpassningar som ska göras i Tjänsten. B2B Portal integrationer\n" +
			"och dess funktionalitet grundas i kontinuerlig efterforskning, maskininlärning samt replikering av\n" +
			"processer såsom löpande bokföring utförd av redovisningskonsulter.\n" +
			"Tjänsten är ett mjukvaruprogram utvecklad i syfte att imitera och till stor grad b2b portal arbetet\n" +
			"redovisningskonsulter utför. Tjänsten ersätter inte helt en redovisningskonsult utan är enbart\n" +
			"kompletterande.\n" +
			"Leverantören garanterar ej att tolkning av transaktionsdata alltid blir fullständigt korrekt, utan\n" +
			"Kunden ansvarar själv för slutlig kontroll av bokföringens korrekthet.\n" +
			"Med förbehåll för de begränsningar som anges i detta avtal, skall Leverantören vara ansvarig för\n" +
			"korrigering av felbokningar till följd av dess försumlighet. I händelse av fel eller brister som kan\n" +
			"tillskrivas Leverantören åtar denne sig att agera för att korrigera sådana fel utan oskäligt dröjsmål.\n" +
			"I det fall det är fråga om ett fel i Tjänsterna som gör att bearbetningen av Kunddata leder till ett\n" +
			"felaktigt resultat, så åtar vi oss att, så snart som omständigheterna kräver med hänsyn till typen av\n" +
			"fel och omständigheterna i övrigt, utan extra kostnad för er, göra en ny bearbetning av Kunddata.\n" +
			"Kunden har inte rätt till en minskning av betalning, debitering eller andra påföljder i händelse av\n" +
			"driftstörningar eller felrapportering som kan uppstå.\n" +
			"Felanmälan måste ges av kunden i enlighet med de instruktioner som aviserats av Leverantören från\n" +
			"tid till tid och inom en rimlig tid för upptäckten av felet. I avsaknad av uppsåt eller grov oaktsamhet\n" +
			"av Leverantören, antar denne annars inget ansvar för fel eller brister i tjänster som ingår däri.\n",
		modificationTermsAndCondition: "ÄNDRING AV AVTALSVILLKOR",
		modificationTermsAndConditionContent:
			"Leverantören förbehåller sig vidare rätten att ändra villkoren i detta Tjänsteavtal, inklusive, men\n" +
			"inte begränsat till, de priser som Leverantören har från en tid till en annan. Kunden skall informeras\n" +
			"om sådana ändringar via e-post eller genom den information som görs tillgänglig på B2B Portal\n" +
			"hemsida en (1) månad före ikraftträdandet. Om kunden inte godkänner ändringen, har\n" +
			"Kunden inom trettio (30) kalenderdagar från avsändandet av e-post eller, i förekommande fall,\n" +
			"trettio (30) kalenderdagar från ändringen publiceras på B2B Portal webbplats, rätt att säga\n" +
			"upp avtalet med omedelbar verkan. Om avtalet inte sägs upp av Kunden inom nämnda tid, ska\n" +
			"Kunden anses ha godkänt de nya villkoren.\n",
		transferOfTerms: "ÖVERLÅTELSE AV AVTAL",
		transferOfTermsContent:
			"Kunden har inte rätt att utan Leverantörens föregående skriftliga medgivande överlåta, pantsätta\n" +
			"eller på annat sätt disponera över eller upplåta säkerhetsrätt avseende sina rättigheter och/eller\n" +
			"skyldigheter enligt Avtalet. Leverantören har rätt att överlåta och/eller på annat sätt disponera över\n" +
			"sina rättigheter och/eller skyldigheter enligt Avtalet.\n",
		marketing: "MARKNADSFÖRING",
		marketingContent:
			"Vi får marknadsföra tjänster till er som vi eller någon samarbetspartner till oss tillhandahåller.\n" +
			"Marknadsföringen kan ske i Tjänsterna, genom e-post eller på annat sätt.",
		agentsAndSubContractors: "OMBUD OCH UNDERLEVERANTÖRER",
		agentsAndSubContractorContent:
			"Leverantören har rätt att anlita underleverantörer och samarbetspartners för att utveckla Tjänsterna\n" +
			"och fullgöra sina åtaganden enligt Avtalet",
		intellectualProperty: "IMATERIELLA RÄTTIGHETER",
		intellectualPropertyContent:
			"Samtliga immateriella rättigheter till Tjänsten samt ändringar och variationer därav, är och ska\n" +
			"fortsatt vara Leverantörens egendom. Ni får inte kopiera, ändra eller på annat sätt hantera\n" +
			"programvara eller annat material som hör till Tjänsterna.\n" +
			"Vid immaterialrättsintrång som beror på Kunden gäller följande:\n" +
			"Kund ska försvara Leverantör om krav riktas eller talan förs mot leverantör om intrång på grund av\n" +
			"kunden eller den som använder Tjänsterna i strid med detta Avtal. Detta sker då på kundens egna\n" +
			"bekostnad. Kund ska också ersätta Leverantör för alla de kostnader och skadestånd som Leverantör\n" +
			"genom förlikning eller dom kan bli skyldiga att betala.\n",
		messages: "MEDDELANDEN",
		messagesContent:
			"Meddelanden under Avtalet lämnas till av Leverantören respektive Kunden angiven adress i Avtalet,\n" +
			"såvida inte meddelanden med fördel lämnas på annat sätt, exempelvis via telefon eller e-mail.\n" +
			"Meddelanden ska anses ha kommit den mottagande Parten tillhanda fem (5) dagar efter avsändandet\n" +
			"med brev och tre (3) dagar efter avsändande med e-post och direkt via telefon. Parterna ska\n" +
			"skyndsamt informera varandra om adressändringar",
		forceMajure: "FORCE MAJEURE",
		forceMajureContent:
			"Leverantören ska inte vara ansvarig för underlåtenhet att fullgöra Avtalet om detta föranleds av en\n" +
			"omständighet som legat utanför Leverantörens kontroll och som Leverantören inte skäligen kunde\n" +
			"förväntas ha räknat med eller tagit i beaktande vid tiden för Avtalets ingående och vars följder\n" +
			"Leverantören inte heller skäligen kunde ha undvikit eller övervunnit, såsom krig, brand,\n" +
			"översvämning, åsknedslag, eldsvåda, terrordåd, hacker-attack, ockupation, lock-out,\n" +
			"importbegränsningar, sanktioner eller liknande, eller fel eller förseningar i leverans från\n" +
			"underleverantör på grund av omständigheter som denne ej kunnat råda över.",
		applicableLaw: "TILLÄMPLIG LAG OCH TVISTELÖSNING",
		applicableLawContent:
			"Parternas rättigheter och skyldigheter vid tolkning och tillämpning av Avtalet ska bestämmas i\n" +
			"enlighet med svensk lag. Tvister som uppstår i anledning av Avtalet ska slutligt avgöras genom\n" +
			"skiljedomsförfarande administrerat av Västsvenska Handelskammares Skiljedomsinstitut.\n" +
			"Talan avseende förfallna obetalda fordringar äger dock Part anhängiggöra inför allmän domstol.\n" +
			"Reglerna om förenklat skiljeförfarande ska tillämpas, om inte Västsvenska Handelskammarens\n" +
			"Skiljeråd med beaktande av tvistens svårighetsgrad, tvisteföremålets värde och övriga\n" +
			"omständigheter bestämmer att reglerna om ordinärt skiljeförfarande ska tillämpas. I sistnämnda fall\n" +
			"ska Skiljerådet också bestämma om skiljenämnden ska bestå av en eller tre skiljemän.\n",
		termsFooter:
			"Skiljeförfarandets säte ska vara Göteborg, Sverige. Språket för förfarandet ska vara svenska.",
		termsFooterContent: "Reviderad januari 2022.",
		authenticationInProcess: "Autentisering pågår",
		maxImageUploadMessage: "jpg/png-filer med en dimension på 5000x3000px",
		maxArticleImageUploadMessage:
			"jpg / png-filer med en maxdimension på 1500x1500px",
		maxImageUploadMessageError:
			"Bilduppladdningen måste vara av dimensionen 5000 x 3000 px",
		maxArticleImageUploadMessageError:
			"Bilduppladdningen måste vara av dimensionen 1500 x 1500 px",
		maxCreditLimitError: "max kredit kanske inte är null.",
		categoryRequired: "Kategori kanske inte är null.",
		subscriptionSuccessMessage: "Tack, välkommen att logga in ert konto nu",
		emailVerificationMessage: "Verifierar din e-post.....",
		emailError:
			"systemet kunde inte verifiera din e-post, se till att du klickar på rätt länk",
		errorTag: "Det gick inte att hämta bloggtaggen",
		proceed: "Fortsätt",
		pending: "EJ ANSLUTEN",
		completed: "ANSLUTEN",
		info: "INFO",
		error: "FEL",
		display_store_to_all_buyers:
			"Synliggör er butik för alla ansluta kunder i B2B portalens nätverk",
		apply_display_store_to_all_buyers:
			"Om du ej bockar för rutan är er butik enbart synlig för era inbjudna kunder",
		archive: "Arkiv",
		show_more: "visa mer",
		show_less: "visa mindre",
		error_processing_request: "Det gick inte att utföra uppgiften",
		max_order_limit_exceeded: "Ni har nått er max kreditgräns",
		order_confirmation_text: "Orderbekräftelsetext",
		order_confirmation_text_en: "Orderbekräftelsetext Engelska",
		order_confirmation_text_help_text:
			"Denna text kommer att visas för kunden när de mottar en orderbekräftelse i portalen samt via email",
		bulk_article_upload: "Massuppladdning av artiklar",
		excel_file_format_text: "Endast xlxs, xlx excel-format stöds",
		article_upload_in_progress: "Artikeluppladdning pågår",
		article_excel_document_required: "Uppladdning av Excel-dokument krävs",
		upload_bulk_article_excel: "Ladda upp massartikel (excel)",
		export_product_data: "Exportera artikel (excel)",
		download_excel_template: "Ladda ner artikelmall (excel)",
		articleLongDescription: "Lång produkttext",
		role: "Roll",
		event: "Händelse",
		fieldTitle: "Fälttitel",
		fieldMap: "Fältnamn i Ekonomiprogrammet",
		required: "Obligatorisk",
		fieldTitleHelpText:
			"Lägg till fält som kunden kan fylla i vid Orderläggning. Namnge fältet och välj sedan vilket referensfält i ekonomiprogrammet värdet ska skickas till",
		fieldMapHelpText:
			"Dessa fält är tillgängliga i Ekonomiprogrammet som referensfält",
		requiredHelpText:
			"Bocka för om ni vill att fältet ska vara obligatoriskt för kunden att fylla i vid beställning",
		companyConfigurationSuccess:
			"Konfigurationen för företagets kassa har sparats",
		companyEmailConfigurationSuccess:
			"Företagets e-postkonfigurationer har sparats",
		accountingFieldAlreadyExist: "Redovisningsfältet finns redan",
		selectAccountField: "Välj kontofält",
		emptyUploadedZip: "Uppladdad zip-fil är tom",
		invalidExcelFormat:
			"Ogiltigt excel-format, vänligen följ det excelmall som getts som exempel.",
		addedSuccess: "Posten har lagts till",
		updateArticleSpecification: "Uppdatera artikelspecifikationen",
		specificationTitle: "Specifikationens titel",
		specificationText: "Specifikationstext",
		actions: "Åtgärd",
		specification: "Produkt Specifikationer",
		text: "Text",
		discountedPrice: "Kampanjpris",
		discount: "Rabatt",
		purchase_price: "Inköpspris",
		deliveryTime: "Leveranstid",
		updateSuccess: "Uppgifterna har uppdaterats",
		allowCustomText: "Lägg till extra textfält för artikel vid beställning",
		articleCustomTextTitle: "Titel för extra textfältet",
		allowCustomTextHelpText:
			"Syftet med denna funktion är att ge köparen ett extra inmatningsfält när de lägger till produkten i varukorgen.",
		additionProductItem:
			"Skriv in informationen och avgränsa med kommatecken",
		itemUpdated: "Uppdaterat",
		campaignScheduleDate: "Kampanj period",
		uploadExcelFile: "Ladda upp excel-fil",
		add: "Lägg till ",
		customerDeliveryAddress: "Kundens leveransadress",
		addDeliveryAddress: "Lägg till leveransadress",
		use_accounting_customer_number_for_identifier:
			"Använd ekonomiprogrammets kundnummer som unik identifierare",
		use_accounting_customer_number_for_identifier_text:
			"Som default används kundens orgnummer som unik identifierare. Om ni har flera kunder i ekonomiprogrammet med samma orgnummer bör ni använda kundnummret som identifierare istället",
		emailSettings: "E-postinställningar",
		emailFrom: "Avsändarens mejladress",
		emailSetting: "E-post inställningar",
		halfLogo: "Företagslogotyp liten storlek",
		fullLogo: "Företagslogotyp stor storlek",
		estimatedDeliveryDay: "Ange beräknad leveranstid i dagar",
		articleDescriptionError: "Ange artikelbeskrivning",
		articleSalesPriceError: "Ange försäljningspris",
		zipTextMsg: "Filen måste vara i .zip-format",
		zipTextDimensionMsg: "Bilder får vara max 800 x 800 px",
		clickUploadZipText: "Här bifogar du .zip filen med produktbilder",
		ShopOwner: "Ägare",
		BuyerEmployee: "Anställd",
		EndUserCustomer: "Kund",
		ShopOwnerEmployee: "Anställd",
		excelReport: "EXCEL MASSHANTERING",
		locationName: "Platsnamn",
		streetAddress: "Gatuadress",
		processingOrder: "Bearbetar order",
		checkoutMessage: "Här kan du lämna en kommentar för ordern",
		Delivery: "Leverans",
		days: "dagar",
		estimatedDeliveryDate: "Beräknat leveransdatum",
		viewProductDetail: "Produktinformation",
		notifyWhenAvailable: "Bevaka produkten",
  		enterEmailForNotification: "Din epostadress",
  		notifyMe : "Meddela mig via email",
  		submitting: "Sparar...",
  		outOfStock: "Ej i lager",
		buyerNote: "Meddelande från kund",
		internalNote: "Intern anteckning",
		customerEmptyError: "Intern anteckning",
		deliveryAddressOptions: "Alternativ för leveransadress",
		country: "Land",
		enableMinimumQty: "Sätt minimum beställningskvantitet",
		minimumOrderQty: "Minimum antal",
		minimumOrderQtyError:
			"Minsta beställningskvantitet måste vara större än 1",
		customerMininmumOrderQtyError:
			"Minsta beställningskvantitet måste vara",
		minimumOrderAmount: "Minimum orderbelopp",
		customerMinimumOrderAmountError: "Minimum ordersumma måste vara ",
		tasksHandle: "Markera anteckning som hanterad",
		categoryTree: "Kategoriträd",
		minimum_order_amount: "Minsta ordersumma",
		minimum_order_amount_text: "Minimum ordersumma",
		minimum_order_amount_error: "Ordern understiger minimumbeloppet",
		addFaq: "Lägg till FAQ",
		editFaq: "Uppdatera FAQ",
		faqSuccess: "FAQ har skapats",
		faqCategorySuccess: "FAQ kategori har skapats",
		question: "Fråga",
		answer: "Svar",
		removeFaq: "Radera FAQ",
		removeFaqCategory: "Radera Kategori",
		cancelled: "Makulerad",
		cancelling: "Makulerad",
		supplierInfoText:
			"Här hittar ni alla grossister som nu är anslutna till och även de grossister " +
			"som är verksamma i plattformen. Om ni önskar få bli återförsäljare " +
			"till en specifik grossist kan ni klicka in på denne och skicka in en ansökan. " +
			"För att logga in på en specifik grossist webbshop som ni är anslutna till," +
			" klicka på presentations boxen nedan",
		invalidExcelHeaderError:
			"Vänligen följ kolumn ordningen och titeln som är angivet i excelmallen",
		invalidFields: "ogiltiga fält",
		emptyEntry: "Lägg till en post",
		childCategorySuccess: "Underkategori har lagts till",
		emptyNodeSelected: "Tom underkategori har valts",
		subcategoryAssignedSuccess: "Underkategori är nu sparad",
		categoryTreeModification:
			"",
		selectChildCategory: "Välj Underkategori",
		categoryStructure: "Kategoristruktur",
		removeSubCategory: "Ta bort underkategori",
		assignArticleCategory: "Tilldela kategori till artikel",
		assign: "Tilldela",
		articleCategoryAssign: "Kategori har tilldelats",
		articleCategoryAssignError:
			"Fel vid tilldelning av kategori till artikel",
		additionalSubCategory: "En lager till av underkategori",
		tags: "Taggar",
		postStatus: "Status",
		sellerBackendUrl: "Backend Domän",
		sellerFrontendUrl: "Frontend Domän",
		emailHost: "E-postvärd",
		emailUsername: "Användarnamn",
		emailPassword: "Lösenord",
		emailPort: "Port",
		useSSL: "Använd SSL",
		useTLS: "Använd TLS",
		connectionType: "Kopplingstyp",
		connectionTypeHelpText:
			"Detta anger vilken smtp-anslutningstyp som ska användas för att skicka e-post",
		categorySpecificUpdate: "Kundspecifik kategori uppdaterad",
		setCustomer: "Ställ in kund",
		applyTo: "Synlighet",
		applyToCustomerText: "Specifika kunder enbart",
		allCustomer: "Alla kunder",
		customerSpecific: "Kundspecifik",
		showCustomerResalePrice: "Visa Rekommenderat Konsumentpris",
		consumer_resale_field_title: "Titel för Konsumentprisfältet",
		consumer_resale_price: "Återförsäljningspris för konsument",
		// availableOnlyForSpecificCustomer: 'Gör den här kategorin synlig för endast vissa kunder',
		customerSpecificCategoryText:
			"Synliggör kategorin för enbart specifika kunder",
		renderOnlyCustomerSpecificArticle:
			"Får bara se kundspecifika produkter",
		renderOnlyCustomerSpecificCategories:
			"Får bara se kundspecifika kategorier",
		renderOnlyCustomerSpecificReferenceField:
			"Får bara se kundspecifika referensfält vid Checkout",
		customerSpecificCheckoutText:
			"Synliggör referensfält för enbart specifika kunder",
		availableOnlyForSpecificCustomer:
			"Synliggör kategorin för bara vissa kunder",
		permissionDenied:
			"Din användarprofil har inte behörighet för tillgång till denna sida",
		productArticleCategoryAssign: "Produktkategorier",
		productEmpty: "Ingen produkt tillgänglig för närvarande",
		searchEmpty: "Din sökning matchar inte någon produkt",
		filterEmpty: "Ditt filter matchar inte någon produkt",
		hide_stock_quantity: "Dölj lagerkvantitet",
		b2bOrderNumber: "B2B ordernummer",
		delete: "Radera",
		self_allowed_create_delivery_addresses:
			"Tillåt kund att skapa egna leveransadresser",
		is_default_address: "Default Adress",
		apply_customer_specific_user_profiles:
			"Använd kundspecifik användarprofil",
		apply_customer_currency_on_orders:
			"Använd kundinställd valuta på order",
		no_company_text: "Välj en butik för att få åtkomst till dessa resurser",
		not_allowed_setting:
			"Din leverantör tillåter inte åtkomst till att redigera era leveransadresser. Kontakta din leverantör för tillgång",
		activeFilter: "Aktiva filter",
		removeAllCustomerFromArticle:
			"Är du säker på att ta bort alla kunder från den här artikeln",
		customerSelectionEmpty: "Välj en eller flera kunder att tilldela",
		totalImageToUpload: "Totalt antal bilder att ladda upp",
		// estimatedTime: 'Beräknad uppladdningstid:',

		all: "Alla",
		blogDataSuccess: "Blogginformationen har uppdaterats",
		articlePdf: "Artikeldokument (pdf)",
		clickUploadPdf: "Klicka för att ladda upp (pdf)",
		pdfSizeText: "pdf-filer med en max storlek på 10mb",
		fileToLarge: "Filen överskrider maxstorlek",
		fileExceedLimit: "Filuppladdning överskrider gränsen",
		adminEmailAddress: "Admin e-postadress",
		send_order_notifications_to_admin:
			"Skicka meddelande till admin vid beställning",
		disable_customer_order_conf_emails:
			"Inaktivera orderbekräftelser till köparen",
		show_standard_price_additional_to_customer_specific_price:
			"Visa standardpris utöver kundspecifikt pris",
		standard_price: "Standard pris",
		relevant: "Relevant",
		assignProductBlog: "Rekommendera produkt",
		searchProduct: "Sök artikel",
		linkedArticle: "Länkade artiklar",
		linkedArticleField: "Välj vilka artiklar som ska länkas till inlägget",
		linkedArticleHelpText:
			"Genom att länka produkter till ditt blogginlägg kommer de att inkluderas och markeras i blogginlägget",
		sendAsNewsLetter: "Skicka som nyhetsbrev via e-post också",
		recommendedProduct: "Rekommenderade produkter",
		recommendedAccessories: "Rekommendera tillbehör",
		accessories: "Tillbehör",
		customerInviteReminder: "Skicka inbjudan påminnelser till kund",
		customerInviteReminderHelpText:
			"Ställ in om du vill att din kund får påminnelser via email om de ännu inte har accepterat inbjudan",
		once_in_a_week: "En gång i veckan",
		twice_in_a_week: "Två gånger i veckan",
		three_time_in_a_week: "Tre gånger i veckan",
		dont_send_reminder: "Skicka inte påminnelse",
		profit_margin: "Vinstmarginal",
		totalSubscription: "Totalt abonnemang",
		totalSupplier: "Total leverantör",
		totalBuyer: "Totala köpare",
		totalUser: "Totalt antal användare",
		logo: "Logotyp",
		buyers: "Köpare",
		companies: "Företag",
		notConnectedToCompany: "Inte kopplad till något företag",
		settingUpAccount: "Vänligen vänta... Konfigurerar konto",
		accessBuyerDashboard: "Gå till kundens vy",
		permissionDeniedResource:
			"Du har för närvarande inte behörighet att komma åt denna sida",
		allow_seller_place_order:
			"Tillåt säljaren att göra en beställning för din räkning",
		settingUpdated: "Inställningen har uppdaterats",
		editDeliveryAddress: "Uppdatera leveransadress",
		productDownload: "Ladda ner Produktlista",
		orderHelpText: "Redigera beställning",
		orderManagement: "Orderhantering",
		deliveryDate: "Leveransdatum",
		updateOrder: "Uppdatera beställning",
		orderDetail: "Beställningsinformation",
		orderRows: "Beställ rader",
		customerDetail: "Kunddetaljer",
		deliveryInformation: "Leveransinformation",
		invoiceType: "Faktura",
		createOrder: "Skapa order",
		account: "Konto",
		vatIncluded: "Moms ingår",
		yourOrderNumber: "Ditt beställningsnummer",
		// yourReference: 'Er referens',
		paymentTerms: "Betalningsvillkor",
		priceList: "Prislista",
		currency: "Valuta",
		project: "Projekt",
		vatType: "Typ av moms",
		emptyRows: "Order/faktura måste innehålla en eller flera rader",
		cancelOrderInvoiceErrorText:
			"Annullerad order/faktura kan inte uppdateras",
		cancelOderInvoiceHelpText: "Avbryt beställning eller faktura",
		cancelSuccessful: "Avbröts framgångsrikt",
		unittypes: "Enhetstyper",
		addNewUnitType: "Skapa enhetstyper",
		unit: "Enhet",
		updateUnitType: "Uppdatera enhetstyp",
		removeUnitType: "Ta bort enhetstyp",
		unitUpdated: "Enheten har uppdaterats",
		unitCreated: "Enheten har skapats",
		productInUnit: "Produkten säljs som ett paket",
		productPackageText: "Hur många enheter ska gälla per paket",
		productPdf: "Produkinformationsblad",
		blogVisibilty: "Synlighet",
		generalSetting: "Allmän inställning",
		stockManagement: "Lagerhantering",
		notifications: "Aviseringar",
		invalidDimension: "Ogiltig dimension för den uppladdade bilden",
		generalMaxCreditError: "Max kredit måste vara större än 0",
		adminEmailAddressError: "Admin e-postadress krävs",
		customEmailRuleHelpText:
			"Välj om du vill att kunden ska påminnas om inbjudan efter intiala inbjudan är skickad",
		sendEmailToAdminHelpText:
			"Bocka för om du vill motta en email avisering varje gång din kund lägger en order",
		dontSendOrderConfirmationToBuyerText:
			"Bocka för om du vill att kunden inte ska motta en orderbekräftelse via email",
		allow_seller_to_see_your_orders:
			"Tillåt säljaren att se sina beställningar",
		othersInformation: "Annan information",
		productRecommendations: "Produktrekommendationer",
		priceInformation: "Prisinformation",
		productInformation: "Produktinformation",
		newsletterDate: "Datum för att skicka nyhetsbrev",
		openBlogRecommendedProduct: "Länkad produkt",
		searchArticles: "Sök artikel",
		previewEmail: "Förhandsvisa Email",
		profitMargin: "Vinstmarginal",
		sellerAllowedToPlaceOrder:
			"Grossisten får behörighet att lägga ordrar för vår räkning",
		sellerAllowedToSeeOrder:
			"Grossisten får behörighet att se vår ordrerhistorik",
		stockThresholdQuantity: "Visa lageraldo som noll vid denna saldogräns",
		notification: "Aviseringar",
		selectRule: "Ställ in intervall",
		invoice_: "Faktura",
		estimatedTime: "Beräknad uppladdningstid:",
		outOfStock: "Produkten finns ej i lager",
		textSchedule: "Nyhetsbrev utskick skedde: ",
		sync_only_webshop_articles: "Synka bara webbshops artiklar",
		connect: "Anslut",
		productVariations: "Varianter",
		stockpoint: "Lagerställe",
		allow_seller_send_employee_invite:
			"Tillåt säljaren att skicka inbjudan till anställda",
		articleVariations: "Artikelvariationer",
		variations: "Variationer",
		readNotification: "Lästa aviseringar",
		unreadNotification: "Olästa aviseringar",
		notificationEmpty: "Inga aviseringar",
		microsoft: "Microsoft 365",
		other_mail_server: "Andra e-postserver",
		mail_server: "Mejl server",
		createSuccess: "Skapat",
		createOrderOrInvoiceCopyText: "Vill du skapa en kopia av denna",
		createArticle: "Skapa artikel",
		deleteSuccess: "Raderat",
		deleteArticle: "Ta bort artikel",
		createCustomer: "Skapa kund",
		otherInformation: "Annan information",
		customerInformation: "Kundinformation",
		updateCustomer: "Uppdatera kund",
		customerAddress: "Faktura adress",
		requestToBuyerTitle: 'Ansök om att bli kund',
		companyName: 'Företagsnamn',
		enterCompanyName: 'Ange företagsnamn',
		organizationNumber: 'Organisationsnummer',
		enterOrgNumber: 'Ange organisationsnummer',
		address: 'Adress',
		enterAddress: 'Ange adress',
		city: 'Stad',
		enterCity: 'Ange stad',
		zipCode: 'Postnummer',
		enterZipCode: 'Ange postnummer',
		email: 'E-post',
		enterEmail: 'Ange e-postadress',
		billingEmail: 'Faktureringsmail',
		enterBillingEmail: 'Ange faktureringsmail',
		contactName: 'Kontaktperson',
		enterContactName: 'Ange namn på kontaktperson',
		message: 'Meddelande',
		enterMessage: 'Ange ytterligare meddelande (valfritt)',
		submitting: 'Skickar...',
		submitRequest: 'Skicka ansökan',
		fieldRequired: 'Vänligen fyll i alla obligatoriska fält',
		invalidEmail: 'Vänligen ange en giltig e-postadress',
		invalidBillingEmail: 'Vänligen ange en giltig faktureringsmail',
		requestSubmitted: 'Tack! Din ansökan har mottagits.',
		serverError: 'Ett fel uppstod. Försök igen senare',
		total: "Totalt",
		vat: "Varav moms",
		copyOrder: "Kopiera Order",
		copyInvoice: "Kopiera Faktura",
		loading: "Laddar info",
		editCustomer: "Redigera kund",
		revenue: "Intäkter",
		hideDetails: "Dölj detaljer",
		whiteLabelSection: {
			fullImageTextCaption: "Maxdimensioner 200 * 200. Filformat .jpeg",
			halfImageTextCaption: "Maxdimensioner 100 * 100. Filformat .jpeg",
			favIconTextCaption: "Maxdimensioner 16 * 16. Filformat .jpeg",
			favIcon: "Favicon",
		},
		productDetail: "Produktdetaljer",
		hide_sales_price: "Dölj inköpspris",
		hide_delivery_time: "Dölj leveranstid",
		hide_discounted_price: "Dölj rabatter",
		hide_recommended_price: "Dölj rek konsumentpris",
		hideDetailsHelpText:
			"Här kan du styra och dölja viss produktinformation på produktsidan. För att styra vilken info, gå till inställningar sidan. Default kommer att dölja priser enbart.",
		pinAsTopPost: "Ställ in som huvudinlägg",
		bannerBackgroundColor: "Banner Bakgrundsfärg",
		toWebshop: "Till Webbshop",
		buyer_sidebar_menu_colorHelpText:
			"Här kan ni ställa in vilken färg sidomenyn ska ha för era kunder",
		buyer_sidebar_menu_color: "Sidmeny för ÅF vy",
		documentDownloadInProgress: "Underlaget laddas ner",
		address: "Leveransadresser",
		pinCategory:
			"Vill du sätta produkter i kategori {category_name} som default i produktöversiktssidan?",
		unPinCategory:
			"Vill du plocka bort produkter i kategori {category_name} som default i produktöversiktssidan?",
		recommended: "Rekommenderad",
		clearCategoryFilter: "Nollställ",
		authorize_microsoft_365: "Autentisera Microsoft 365 appen",
		sync_only_corporate_customers:
			"Synka bara Företagskunder från ekonomiprogrammet",
		import_all_orders_from_accounting:
			"Importera samtliga ordrar från ekonomiprogrammet",
		import_all_orders_from_start_date:
			"Import ordrar från ekonomiprogrammet från detta datum",
		viewAll: "Visa alla",
		// published: 'Publiceras',
		draft: "Utkast",
		categoryUpdated: "Kategorin är uppdaterad",
		notificationUpdated: "Aviseringar är uppdaterad",
		favIconText: "",
		blogPostUpdated: "Uppdaterat",
		unpinBlog: "Blogginläggen plockas bort som huvudinlägg nu",
		productImages: "Bilder",
		delivered: "SLUTLEVERERADE",
		processing: "ICKE LEVERERADE",
		myProfile: "Min Profil",
		buyerDetails: "Kunddetaljer",
		orderItems: "Orderrader",
		"loginError": "Något gick fel, vänligen kontakta supporten.",
		"invalidCredentials": "Det finns ett konto med denna användare men angivet lösenord stämmer ej.",
		"noUserExists": "Det finns ingen användare med denna mejladress.",
		"loginSuccess": "Välkommen in!",
		notificationNowMarkAsRead: "Notifiering är markerad som läst",
		savedAddress: "Sparade Leveransadresser",
		videoGuides: "Video guider",
		noSubscriptionSelected: "Vänligen välj en prenumerationstyp",
		backToFortnoxConnect:
			"Något gick fel, vänligen återanslut igen till Fortnox",
		integrations: "Integrationer",
		subscriptionNotSelectedByCustomer:
			"Det gick inte att gå vidare eftersom prenumeration är inte vald. Vänligen navigera tillbaka och välj ett paket.",
		yet_to_connect_to_integration:
			"Bolaget är inte anslutet till ett integration ännu, vänligen klicka nedan för att komma igång",
		getStarted: "Anslut",
		connectedBuyers: "Anslutna Kunder",
		accessRequest: "Inbjuden & Ej svarat",
		"status": {
			"processing": "Hanteras",
			"received": "Mottagen",
			"delivered": "Levererad"
		}
	},
	dk: {
		fields: {
			username: "Brugernavn",
			email: "E-mail",
			password: "Adgangskode",
			domain: "Domæne",
			newPassword: "Ny adgangskode",
			firstName: "Fornavn",
			lastName: "Efternavn",
			setDomain: "Indstil domæne",
			repeatPassword: "Gentag adgangskode",
			confirmNewPassword: "Bekræft ny adgangskode",
		},
		validation: dk.messages,
		onboardHeader: "Velkommen til B2B Portal",
		welcomeEntry: "One-stop shop for grossister og detailhandlere",
		enterAccountInfo: "Indtast kontooplysninger",
		toLoginPage: "Gå til loginside",
		serviceStart: "Ønsker du at starte servicen?",
		customerPortal: "Gå til kundeportal",
		wouldConnect: "Klar til at oprette forbindelse på B2B-portalen",
		onBoardSeller: "Gå videre",
		onBoardBuyer: "Detailhandlere",
		toDestination: "B2B portal",
		connectNow: "Ja, installer appen",
		signUpText: "Ønsker du at starte tjenesten?",
		codeAlreadyUse: "API-nøglen er allerede brugt",
		username: "Brugernavn",
		password: "Adgangskode",
		loginHeader: "Kundeportal",
		forgetPassword: "Glemt adgangskode",
		resetPassword: "Nulstil adgangskode",
		newPassword: "Ny adgangskode",
		confirmNewPassword: "Bekræft ny adgangskode",
		login: "Login",
		loginSuccess: "Login lykkedes",
		fieldRequired: "Alle felter er obligatoriske",
		emailAddress: "E-mailadresse",
		customerAlready: "Allerede kunde",
		resettingPassword: "Nulstiller",
		passGreater: "Adgangskoden skal indeholde mere end 6 tegn",
		passwordNotMatch: "Adgangskoderne er ikke ens",
		accountCreated: "Oprettelse af kontoen lykkedes",
		serverError: "Ups...Fejl fra serveren",
		repeatPassword: "Gentag adgangskode",
		setDomain: "Indstil domæne til din portal",
		createAccount: "Opret din konto for at komme i gang",
		signUp: "Tilmeld dig",
		notCustomer: "Ikke kunde",
		creatingAccount: "Opretter konto...",
		inviteCode: "Invitationskode",
		invite: "Inviter",
		accept: "Accepter",
		reject: "Afvis",
		acceptStatement: "Accepter brugeranmodning?",
		rejectStatement: "Afvis brugeranmodning?",
		articles: "Artikler",
		confirmPassword: "Bekræft adgangskode",
		chooseShop: "Vælg en butik",
		dashboard: "Dashboard",
		orderHistory: "Ordrehistorik",
		invoiceHistory: "Fakturahistorik",
		orders: "Ordrehistorik",
		customerName: "Kundens navn",
		orderNumber: "Ordrenummer",
		orderType: "Ordretype",
		orderDate: "Ordredato",
		orderStatus: "Ordrestatus",
		orderSummary: "Belopp",
		reference: "Reference",
		action: "Filhåndtering",
		search: "Søg",
		subscription: "Abonnement",
		show: "Viser",
		invoice: "Fakturahistorik",
		invoiceNumber: "Fakturanummer",
		invoiceDate: "Fakturadato",
		invoiceStatus: "Fakturastatus",
		invoiceSummary: "Fakturaoversigt",
		product: "Produkt",
		youHave: "Du har",
		itemsInCart: "vare(r) i din indkøbskurv",
		subTotal: "Subtotal",
		checkout: "Gå till Kassa",
		logout: "Log ud",
		brand: "Mærke",
		totalOrdered: "Samlede ordrer",
		remove: "Fjern",
		totalAmountIncVat: "Total mængde inkl. moms",
		totalAmountExVat: "Total mængde eksl. moms",
		vatAmount: "Momsbeløb",
		deliveryDetails: "Leveringsdetaljer",
		deliveryName: "Leveringsnavn",
		deliveryAddress: "Leveringsadresse",
		deliveryZipCode: "Leveringspostnummer",
		deliveryCity: "Leveringsby",
		deliveryCountry: "Leveringsland",
		email: "E-mail",
		ourReference: "Vår Referens",
		yourReference: '"Er Referens',
		ourOrderNumber: "Vores ordrenummer",
		message: "Besked",
		sameBillingAddress: "Benyt samme som faktureringsadresse",
		allowBackOrder: "Forudbestilling/Restordre Artikel",
		allowBackOrderHelpText: "Hvis markeret, kan kunden købe produktet uanset lagerbeholdning. Lagerbeholdningen vil ikke være synlig for kunden",
		preOrderItem: "Forudbestilling/Restordre Vare",
		buyProduct: "Køb",
		placeOrder: "Afgiv ordre",
		pleaseWait: "Vent venligst",
		disposableQty: "Mængde",
		itemRemove: "vare fjernet fra indkøbskurv!",
		itemAdd: "vare(r) tilføjet til indkøbskurv!",
		orderConfirmation: "Ordrebekræftelse",
		thankYouOrder: "Tak for din ordre!",
		proceedOrder:
			"vil gennemføre behandlingen af ordren, og der sendes en bekræftelse til",
		date: "Dato",
		amount: "Mængde",
		seller: "Sælger",
		buyer: "Køber",
		customerSettings: "Kundeindstillinger",
		users: "Brugere",
		companySettings: "Virksomhedsindstillinger",
		totalOrderToday: "Samlet antal ordrer i dag",
		totalOrderMonth: "Samlet antal ordrer denne måned",
		totalCustomerVisit: "Antal besøgende kunder i dag",
		averageOrderAmount: "Gennemsnitlig ordremængde",
		totalRevenue: "Samlede B2B-indtægter",
		today: "I dag",
		revenueSales: "Indtægter fra salg",
		bestSellingArticle: "Bedst sælgende varer",
		totalInvitedCustomers: "Samlet antal inviterede kunder",
		totalPublishArticle: "Samlet antal offentliggjorte varer",
		totalSalesMonth: "Samlet antal salg denne måned",
		customerHighestPurchase: "Kunder med de største køb",
		articleSetting: "Vareindstillinger",
		articleNumber: "Vare#",
		description: "Beskrivelse",
		price: "Pris",
		priceListSelection: "Vælg prisliste",
		thumnails: "Miniaturebilleder",
		inviteCustomer: "Inviter kunder",
		number: "Numre",
		organizationNumber: "Organisationsnummer",
		category: "Kategori",
		status: "Status",
		RequestBecomeBuyer: "Ansøg om at blive kunde",
		notInvited: "Ikke inviteret",
		pendingInvitation: "Afventer",
		waitingInvite: "Venter",
		accepted: "Accepteret",
		confirmed: "Bekræftet",
		createUserCategory: "Opret brugerkategori",
		customerCategoryName: "Kundekategoriens navn",
		accountNumber: "Kontonummer",
		costCenter: "Omkostningscenter",
		addUser: "Tilføj brugere",
		firstName: "Fornavn",
		lastName: "Efternavn",
		addBtn: "Tilføj",
		adding: "Tilføjer",
		updateBtn: "Opdater",
		manageUser: "Administrer brugere",
		userSetting: "Brugerindstillinger",
		companyName: "Virksomhedens navn",
		companyNumber: "Virksomhedens nummer",
		zipCode: "Postnummer",
		city: "By",
		phoneNumber: "Telefonnummer",
		billingAddress: "Faktureringsadresse",
		invoiceEmail: "Faktureringsmail",
		shopDomain: "Shopdomæne URL",
		brandName: "Varemærke",
		selectPricelist: "Vælg prisliste",
		postInvoice: "Send fakturaen til Fortnox",
		chooseCompanyLogo: "Vælg virksomhedens logo",
		applyCustomerPricelist: "Anvend kundespecifikke prislister",
		choose: "Kundespecifik",
		update: "Opdater",
		updating: "Opdaterer",
		updateShopUsers: "Opdater butikbrugere",
		inviteEndCustomer: "Inviter slutbruger",
		customerEmail: "Kunde e-mail",
		customerNumber: "Kundenummer",
		selectCategory: "Vælg kategori",
		inviting: "Inviterer",
		createCustomerCategory: "Opret kundekategori",
		selectCostCenter: "Vælg omkostningscenter",
		create: "Opret",
		creating: "Opretter",
		uploadLogo: "Upload logo",
		chooseFile: "Vælg en fil",
		crop: "Beskær",
		cropping: "Beskærer",
		finishUpload: "Upload afsluttet",
		updateProductImage: "Opdater produktbilleder",
		articleDetail: "Varedetaljer",
		quantity: "Mængde",
		dropImageFiles: "Slip filen her eller",
		clickToUpload: "klik for at uploade",
		fileFormat: "filer med en størrelse på mindre end 500kb",
		maximumImages: "Maksimalt 4 billeder",
		uploading: "Uploader",
		updateArticle: "Opdater varer",
		articleDescription: "Varebeskrivelse",
		articleName: "Varenavn",
		articleVideoURL: "Produktvideo URL",
		updateCustomerInfo: "Opdater kundeinformation",
		articleImage: "Varebillede",
		removeUser: "Er du sikker på at du vil fjerne",
		continue: "Fortsæt",
		warning: "Advarsel",
		removeSuccessful: "blev fjernet",
		updateUserSuccess: "Brugeren blev opdateret",
		enterValidPrice: "Indtast venligst en gyldig pris",
		enterValidQty: "Indtast venligst en gyldig mængde",
		articleUpdateSuccess: "Vareinformation blev opdateret",
		removeImage: "Er du sikker på at du vil fjerne dette billede",
		imageRemoveSuccess: "Billede blev fjernet",
		yes: "Ja",
		no: "Nej",
		cancel: "Annuller",
		cancelPurchase: "Annuller køb",
		undoSelection: "Fortryd markering",
		invalidOrganizationNumber: "Ugyldigt organisationsnummer",
		customerInfoUpdated: "Kundeinformation blev opdateret",
		invitedSuccessful: "Kunde blev inviteret",
		customerCategoryCreated: "Brugerkategori blev oprettet",
		invalidFormat: "Ugyldigt filformat",
		maximumFour:
			"Højst 4 billeder. Tilføj flere billeder ved at slette nogle af de eksisterende",
		fourOnly: "Upload kun 4 billeder",
		imageRequired: "Billedfelt påkrævet",
		uploadSuccessful: "Varebilleder blev uploadet",
		setUpAccount: "Opsæt konto",
		invitedTo: "Du blev inviteret til at blive medlem",
		settingUp: "Opsætter",
		cartEmpty: "Din indkøbskurv er tom",
		orderedSuccessfully: "Dine varer blev bestilt",
		pieces: "stk",
		errorPlacingOrder:
			"Ups, der skete en fejl i forbindelse med afgivelsen af din ordre",
		costPrice: "Omkostningspris",
		salesPrice: "Salgspris",
		vatRate: "Momssats",
		margin: "Margin",
		checkEmail: "Tjek venligst din e-mail for vejledning",
		invalidUser: "Ugyldig bruger",
		resetSuccessful:
			"Adgangskoden blev nulstillet. Du bedes venligst logge ind igen",
		selectFile: "Vælg fil",
		optional: "Valgfri",
		selectSubscription: "Vælg dit abonnement",
		subscriptionSuccess: "Dit abonnement blev oprettet",
		next: "Næste",
		currentSubscription: "Nuværende abonnement",
		monthlyCost: "Månedlig pris",
		notAvailable: "Ikke tilgængelig",
		massAssign: "Massetildeling",
		bulkUpdate: "Masseopdatering",
		fieldValue: "Feltværdi kan ikke være længere end 50 tegn",
		download: "Download",
		invalidCellValue: "Ugyldig værdi",
		invitedUserSuccess: "Medarbejder blev inviteret",
		successCompanyupdated: "Virksomhedsinformation blev opdateret",
		notify: "Notifikation",
		noNotify: "Ingen nuværende notifikationer",
		notFoundText: "Ån nej! Siden blev ikke fundet",
		invalidAccount: "Ugyldig konto",
		backHome: "Gå tilbage til startside",
		back: "Tilbage",
		filter: "Sorter efter",
		markRead: "Marker som læst",
		more: "mere",
		less: "mindre",
		save: "Gem",
		connecting: "Tilslutter",
		addWebshop: "Tilføj webshop",
		addProductCategory: "Tilføj produktkategori",
		itemOutOfStock: "Varan är ej tillänglig för beställning",
		webshop: "Webshop",
		updatedWebshop: "Webshop blev opdateret",
		shopName: "Butiksnavn",
		note: "Noter",
		approachingLowStockQty: "Lav lagerbeholdning",
		criticalLowStockQty: "Kritisk lav lagerbeholdning",
		syncStockFromSupplier:
			"Synkroniser lagerbeholdning fra leverandør til webshop",
		placeAutomaticOrders:
			"Afgiv ordre til leverandøren automatisk når lagerbeholdningen er lav",
		addWebshopFor: "Tilføj webshop for",
		wellStocked: "Velforsynet",
		sufficientQty: "Tilstrækkelig mængde",
		webshopProduct: "Webshopprodukt",
		productCategoryName: "Navn på produktkategori",
		productCategorySuccess: "Produktkategorien blev tilføjet",
		productCategory: "Produktkategori",
		allProductCategory: "Få alle produkter",
		assignSelectedProducts: "Tildel markeret/de produkt(er)",
		assignSuccess: "Produkt(er) blev tildelt",
		assignProduct: "Tildelt produkt(er)",
		assignProgress: "Du er ved at tildelige 1 produkt til",
		thresholdQty: "Tærskel for lagerbeholdning",
		relevantCustomer: "Relevant kunde",
		metricsOverview: "Metrisk overblik",
		productManager: "Produktchef",
		view: "Se",
		supplier: "Leverandør",
		isEqualTo: "er lig med",
		isGreaterThan: "er større end",
		isBetween: "er mellem",
		isLessThan: "er mindre end",
		clear: "Ryd",
		done: "Færdig",
		supplierMatch: "Leverandørmatch",
		supplierQuantity: "Leverandørmængde",
		unavailable: "Utilgængelig",
		name: "Navn",
		totalMonth: "Samlet antal ordrer denne måned",
		averageMargin: "Gennemsnitlig fortjenstmargen",
		averagePerOrder: "Gennemsnitlig fortjeneste per ordre",
		selectParentCategory: "Vælg overordnet kategori",
		addSubCategory: "Vælg underkategori",
		subCategoryName: "Navn på underkategori",
		subCategorySuccess: "Underkategori blev tilføjet",
		addCategory: "Tilføj kategori",
		source: "Kilde",
		productWithImage: "Produkt med billede",
		bankIdAuth: "Godkendelse af bank-id",
		bankIDStatement:
			"Underskriv med dit bank-id for at godkende køb for dette beløb",
		validBankIDFormat: "Eksempel på gyldigt format",
		connectShopify: "Tilslut til Shopify",
		settings: "Indstillinger",
		billing: "Fakturering",
		myAccount: "Kontooplysninger",
		amountDue: "Skyldigt beløb",
		dueDate: "Forfaldsdato",
		file: "Sag",
		paid: "Betalt",
		unPaid: "Ubetalt",
		requireBankIDforAllOrders: "Påkræv bank-id for alle ordrer",
		requireBankIDThresholdAmount:
			"Påkræv kun bank-id for ordrer over et bestemt beløb",
		applyCustomerSpecificBasedMaxCredit:
			"Anvend kundespecifikt maksimalt kreditbeløb",
		applyGeneralCreditforAllCustomers:
			"Anvend generelt maksimalt kreditbeløb",
		applyStockThreshold: "Anvend tærskel for lager",
		orderAmount: "Ordremængde",
		verifyBankId: "Godkender bank-id",
		connectedSupplier: "Tilsluttet leverandør",
		connectIntegration: "Tilsluttet webshop",
		selectCategoryBelow: "Vælg kategori herunder",
		nonConnectedSupplier: "Ikke-tilsluttet leverandør",
		maxCreditLimit: "Maksimalt kreditbeløb",
		priceManagement: "Prisstyring",
		categoryManagement: "Kategoristyring",
		parentCategory: "Overordnet kategori",
		childCategory: "Underkategori",
		selectCategoryParent: "Vælg overordnet kategori",
		removeParentCategory: "Fjern overordnet kategori",
		removeChildCategory: "Fjern underkategori",
		totalOrderAmount: "Samlet ordrebeløb",
		customerManagement: "Kundestyring",
		totalConnectedSuppliers: "Samlet antal tilsluttede leverandører",
		totalOrderAmountMonth: "Samlet antal ordrer/måned",
		totalProducts: "Samlet antal produkter",
		customerCategory: "Kundekategori",
		customerCategoryManagement: "Kundekategoristyring",
		customerCategoryList: "Kundekategoriliste",
		maxLimitErrorMessage:
			"Dit købsbeløb er højere end det maksimale kreditbeløb som din leverandør har tilladt, og derefter kan denne ordre ikke afgives. Kontakt venligst din leverandør",
		selectCompany: "Vælg venligst virksomhed",
		shopNameRequire: "Indtast venligst butikkens navn",
		invalidUpdateResponse: "Ugyldig responsopdatering",
		clickToEdit: "Klik for at redigere",
		appLogs: "Logfiler for applikationer",
		logInitiator: "Igangsætter",
		logStatus: "Status for logfiler",
		logs: "Logfiler",
		priceNotAvailable: "Prisen er ikke tilgængelig",
		productVideo: "Produktvideo",
		relevantArticle: "Relevant",
		selectPricelistIndicator: "Vælg prisliste med indikator",
		updatePriceList: "Lav venligst en prisliste",
		relevantArticleCustomers: "Vis kun til kunder",
		searchCustomers: "Søg efter kunder",
		select: "Vælg",
		setRelevantArticles: "Vælg engrosrelevante artikler",
		setVisible: "Synlige",
		setHidden: "Skjulte",
		selectCustomers: "Vælg kunder",
		userCustomization: "Tilpasning",
		inviteEmailSubject: "Emne for e-mailinvitation",
		menuColor: "Menufarve",
		InviteEmailHeader: "Overskrift for e-mailinvitation",
		buttonColor: "Farve på knap",
		inviteEmailContent: "E-mailinvitationens indhold",
		linkColor: "Farve på link",
		pricelistUpdated: "Prisliste opdateret",
		customization: "Tilpasning",
		invited: "Inviteret",
		connected: "Tilsluttet",
		companyLogo: "Virksomhedens logo",
		storeName: "Butikkens navn",
		requestAccess: "Anmod om adgang",
		viewDetails: "Se detaljer",
		logType: "Logtype",
		startDate: "Startdato",
		endDate: "Slutdato",
		logError: "Fejl",
		logInfo: "Info",
		noDescription: "Ingen beskrivelse",
		CustomerSpecific: "Kundespecifik",
		deleteBlogSuccess: "Blogindlæg blev slettet",
		noBlogPost: "Du har ikke oprettet et blogindlæg endnu",
		noWebshop: "Webshoppen blev ikke fundet",
		unAuthorizedShopify: "Shopify-konto er ikke blevet godkendt endnu",
		blogPreviewTitle: "Forhåndsvis panel",
		faqz: "FAQ",
		inviteEmailSubjectHelpText: "Emnet for ÅFs e-mailinvitation",
		inviteEmailHeaderHelpText: "Titlen på ÅFs e-mailinvitation",
		inviteEmailContentHelpText: "Teksten i ÅFs e-mailinvitation",
		menuColorHelpText: "Farve til sidemenuen",
		linkColorHelpText: "Farve på link",
		buttonColorHelpText: "Farve på knap",
		brandNameHelpText: "Varemærke",
		storeDescriptionHelpText: "Butiksbeskrivelse synlig for ÅF",
		postInvoiceToFortnoxHelpText:
			"Hvis kassen er bogført, oprettes der fakturaer i finansprogrammet. Hvis den ikke er bogført, oprettes der ordrer i finansprogrammet",
		applyStockThresholdHelpText:
			"Viser produkter som ikke er tilgængelige i butikken for kunden, hvis lagernummeret i dit finansprogram er lavere end det specificerede nummer",
		applyCustomerPricelistHelpText:
			"Anvender prislister for kunden i webshoppen baseret på prislisten som er forbundet til kunden i finansprogrammet",
		require_bank_id_for_for_orders_above_certain_amountHelpText:
			"Påkræv bank-id for alle køb over et vist beløb",
		applyCustomerSpecificBasedMaxCreditHelpText:
			"Anvendt kreditbegrænsninger for kunder. Beløbet for kreditbegrænsningen er fastsat under kundeindstillinger, og vil forhindre kunden i at afgive ordre over et vist beløb med en faktura",
		applyGeneralCreditforAllCustomersHelpText:
			"Anvend generelle kreditbegrænsninger for alle kunder af samme beløb",
		requireBankIDforAllOrdersHelpText:
			"Påkræv underskrivning med bank-id for alle køb uanset beløb",
		chooseCompanyLogoHelpText: "Upload virksomhedens logo",
		CompanyNameHelpText: "Upload virksomhedens logo",
		cityHelpText: "By",
		phoneNumberHelpText: "Telefonnummer",
		companyNumberHelpText: "Organisationsnummer",
		emailHelpText: "Kontaktpersonens e-mail",
		invoiceEmailHelpText: "Udsendelse af fakturaer",
		bankGiroHelpText:
			"Dit bankoverførselsnummer som vil fremgå af din faktura",
		IBANHelpText: "Dit IBAN-nummer som vil fremgå af din faktura",
		plusgiroHelpText: "Dit plusgironummer som vil fremgå af din faktura",
		shopNameHelpText: "Butiksnavn",
		apply_english_on_all_templates: "Sätt engelska som språk vid kommunikation",
		woo_consumer_keyHelpText:
			"API-nøgler som du kan downloade i Woocommerce under avancerede indstillinger",
		woo_consumer_secretHelpText:
			"Husk at angive både læse- og skriverettigheder",
		approaching_low_stock_quantityHelpText:
			"Lagernummer for lav lagerbeholdning. Nummeret du indtaster her vil følge linket, som en påmindelse når din lagerbeholdning er lav",
		critical_low_stock_quantityHelpText:
			"Lagernummer for kritisk lav lagerbeholdning. Nummeret du indtaster her vil følge linket, som en påmindelse når din lagerbeholdning er kritisk lav",
		sufficient_stock_quantityHelpText:
			"Lagernummer for tilstrækkelig lagerbeholdning. Nummeret du indtaster her vil følge linket, som en påmindelse når din lagerbeholdning er på et acceptabelt niveau",
		syncStockFromSupplierHelpText:
			"Synkroniser dit lager med leverandørens lager",
		placeAutomaticOrdersHelpText:
			"Afgiv automatisk ordrer når lagerbeholdninger når et lavt niveau",
		billingAddressHelpText: "Betalingsadresse",
		invalidCredential:
			"systemet kunde inte validera dina autentiseringsuppgifter",
		title: "Titel",
		send_invoices_to_nox_finance: "Skicka fakturor via Nox Finans",
		send_to_noxHelpText:
			"Vid ja så kommer fakturor skapas upp i Fortnox och direkt därefter skickas via Nox Finans. Leveranssättet baseras på det som är förinställd på kundkortet i Fortnox",
		UpdateArticleCategory: "Uppdatera artiklar kategori",
		finallyDelivered: "SLUTLEVERERADE",
		finallyPaid: "SLUTBETALDA",
		NotDelivered: "ICKE LEVERERADE",
		storeDescription: "Butiksbeskrivning",
		removeBlogCategory: "“Är du säker på att du vill radera kategori",
		blogAddedSuccess: "Blogginlägget har lagts till",
		blogUpdatedSuccess: "Uppdaterat blogginlägget",
		priceExcludeTax: "*Priser anges ex moms",
		termsAndCondition: "Jag har läst och godkänner",
		privacyLink: "köpvillkoren",
		unSelectedTermsCondition: "Vänligen ange att du godkänner våra villkor",
		privacyPolicy: "Sekretess och juridik",
		general: "ALLMÄNT",
		generalContent:
			"Det här avtalet gäller när vi på Connectivo AB, org. nr. 556932-7777, tillhandahåller tjänster till er.\n" +
			'I detta Avtal betyder ”vi”, ”oss” , "leverantören" och ”B2B Portal" Connectivo AB och ”ni”,\n' +
			"”er” eller ”kunden” ni som kund.",
		trademarks: "Varumärken",
		certainDisclaimer: "Vissa ansvarsfriskrivningar",
		confidentialInformation: "Konfidentiell information",
		globalAvailability: "Global tillgänglighet",
		linkTosite: "Länka till denna webbplats",
		thirdPartyWebsite: "Tredjepartswebbplatser",
		thirdPartyProductServices: "Produkter och tjänster från tredje part",
		collectionTechnicalInformation:
			"Insamling och användning av teknisk information",
		useOfCookie: "Användning av kakor",
		jurisdiction: "Jurisdiktion",
		disclaimerOfGuarranty: "FRISKRIVNING AV GARANTI",
		limitationLiability: "ANSVARSBEGRÄNSNING",
		loginRequest: "Sessionen har löpt ut, logga in igen",
		definitions: "DEFINITIONER",
		definitionsContent:
			"Nedan listas en del ord som används genomgående i Avtalet. Orden ska ha den betydelse som anges\n" +
			"nedan.\n",
		connectionPoint: "Anslutningspunkten",
		connectionPointContent:
			"Den punkt där våra Tjänster görs tillgängliga för er.",
		userHeader: "Användare",
		userHeaderContent:
			"Den person som ni har givit tillgång till Tjänsterna. Användare ska vara anställd hos er, eller annars\n" +
			"jobba för er räkning, till exempel en konsult eller redovisningsekonom",
		agreement: "Avtal",
		agreementContent:
			"Det här avtalet inklusive bilagor och underbilagor.\n",
		customerData: "Kunddata",
		customerDataContent:
			"Data som ni på något sätt, själv eller genom Användare, överlämnar till oss eller som vi annars får\n" +
			"del av genom er användning av Tjänsterna.\n",
		customerAgreement: "Kund med Byråavtal",
		customerAgreementContent:
			"Customer who has entered into an agreement with an accounting firm or similar company for financial services\n" +
			"based on the Services.",
		systemAdministrator: "Systemadministratör",
		systemAdministratorContent:
			"Den person som tecknat Tjänsterna för er räkning, eller som annars har utsetts av er till\n" +
			"Systemadministratör. Kund med Byråavtal kan ha en egen systemadministratör.\n",
		serviceOrServices: "Tjänsten eller Tjänsterna",
		serviceOrServicesContent:
			"Molnbaserade prenumerationstjänster avseende integration av webbapplikationer och\n" +
			"automatisering av manuella processer inom löpande bokföring. Tjänsten innefattar leverans av\n" +
			"standardiserade integrationer. Skräddarsydda och kundspecifika integrationer innefattas ej av denna\n" +
			"tjänst, utan kan beställas som en separat tjänst.",
		conclusionOrder: "AVTALETS INGÅENDE OCH BESTÄLLNING",
		conclusionOrderContent:
			"Avtal avseende Tjänsten ska ingås skriftligen antingen via e-post, via beställning på hemsidan, ww.bridgero.se, eller via App Marknadsplatser.\n" +
			"Den dag Kunden fullföljt beställningen utgör ”Avtalsdagen”.\n" +
			"Kundens minimiåtagande per månad enligt Avtalet ska beräknas som summan av de månadsavgifter\n" +
			"som Kunden ska betala för det totala antal licenser som Kunden beställt vid Avtalets ingående.\n" +
			"Kundens minimiåtagande per månad framkommer i prislistan som är publicerad på hemsidan och\n" +
			"kan ej ändras under Avtalets bindningstid.\n" +
			"Beställning av ytterligare/nya tjänster (inklusive licenser för användare och abonnemang utöver\n" +
			"Kundens minimiåtagande) eller Produkter efter det att Avtalet ingåtts ska göras på det sätt\n" +
			"Leverantören anvisar.",
		rightOfwithdrawal: "ÅNGERRÄTT",
		rightOfwithdrawalContent:
			"Nedladdning och nyttjande av digitala produkter klassas som en tjänst som inte kan återlämnas då \n" +
			"det är en så kallad direktkonsumtion. Den digitala verifikationsprocessen motsvarar en plombering" +
			"av en fysisk vara.",
		delivery: "LEVERANS",
		deliveryContent:
			"Tjänstens konfiguration och aktivering levereras i form av en kundportal där kund ansvarar för att\n" +
			"följa anvisningar och konfigurera sina inställningar.\n" +
			"Om Kunden anser att det finns fel eller brister i leveransen av Tjänsten, ska Kunden skriftligen\n" +
			"informera Leverantören därom och tillse att relevant skriftlig dokumentation och felbeskrivning är\n" +
			"Leverantören tillhanda senast tio (10) dagar räknat från Leveransdatum. Leverantören ska därefter\n" +
			"och inom skälig tid vidta nödvändiga korrigerande åtgärder. Om Kunden inte anmält fel eller brist i\n" +
			"enlighet med ovan anses Tjänsten felfritt levererad.\n" +
			"Fel eller ändringar efter nyssnämnda tid hanteras enligt vad som anges under punkt 16\n" +
			"(Felavhjälpning) nedan. Om leverans inte kan ske och detta beror på att Kunden inte fullgjort sina\n" +
			"åtaganden enligt Avtalet äger Leverantören rätt att fakturera samtliga avgifter från Avtalsdagen.",
		deliveryGuarranty: "LEVERANSGARANTI",
		deliveryGuarrantyContent1:
			"• Om Leveransdatum inte ägt rum senast nittio (90) dagar från Avtalsdagen, har Kunden rätt att kräva\n" +
			"avdrag med belopp motsvarande femtio (50) procent av den obrukbara Tjänstens erlagda fasta avgift",
		deliveryGuarrantyContent2:
			"Om Leveransdatum inte ägt rum senast etthundratjugo (120) dagar från Avtalsdagen, har Kunden\n" +
			"rätt att häva Avtalet. Om Avtalet hävs ska samtliga prestationer återgå och samtliga förhållanden\n" +
			"anses reglerade, bl.a. med innebörd att ingen ytterligare ersättning utöver att av Kunden eventuellt\n" +
			"erlagda avgifter ska återbetalas till Kunden.",
		deliveryGuarrantyContent3:
			"• Leveransgarantin i denna punkt gäller endast om förseningen enligt ovan uteslutande beror på\n" +
			"förhållanden hänförliga till Leverantören. Så anses inte vara fallet exempelvis om",
		deliveryGuarrantyContent4:
			"- Förseningen helt eller delvis är hänförlig till annan tredjepart än Leverantören eller om förseningen" +
			"helt eller delvis är hänförlig till Kunden, vilket kan vara fallet om Kunden påverkar eller ändrar" +
			"datum för Leveransdatum eller Kunden inte uppfyllt sina åtaganden enligt Avtalet, såsom" +
			"installation och aktivering av tjänsten.",
		deliveryGuarrantyContent5:
			"- Uppgift i Avtalet eller i Kunddatainformation är felaktig och Leverantören inte kan få korrekt\n" +
			"uppgift (bekräftad) av Kunden före planerad installation.\n" +
			"-Force Majeure föreligger",
		customerObligation: "KUNDENS ÅLIGGANDEN",
		customerObligationContent1:
			" Kunden ska se till att alla uppgifter som anges angående kontaktuppgifter, faktureringsinformation" +
			"eller kreditkortsinformation är korrekta och förbinder sig att uppdatera dessa uppgifter regelbundet ",
		customerObligationContent2:
			"• Kunden skall ansvara för den verksamhet som bedrivs av Kunden och dess medlemmar och ska" +
			"säkerställa att den nationella lagstiftningen följs. ",
		customerObligationContent3:
			"• Kunden ansvarar för att kontinuerligt kontrollera importerad data till ekonomiprogrammet och" +
			"hålla uppdaterad dess programinställningar för brukande av programvara. ",
		customerObligationContent4:
			"• Kunden ansvarar för att inställningar i ekonomiprogrammet ställs in i enlighet med instruktioner" +
			"som tillhandahålls av B2B Portal och aktivering av levererad tjänst. ",
		customerObligationContent5:
			"Kunden samtycker till att inte reproducera, duplicera, kopiera, sälja, återförsälja eller exploatera\n" +
			"någon del av bridgero.se, användning av bridgero.se, eller tillgång till bridgero.se. Återförsäljning av bridgero.se är endast tillåtet med skriftligt samtycke från\n" +
			"Connectivo AB och ett återförsäljaravtal från Connectivo AB (som Connectivo AB förbehåller sig\n" +
			"rätten att när som helst avsluta och som kan omfatta vissa villkor).",
		customerObligationContent6:
			"Den som undertecknar detta avtal genom köp av tjänst garanterar att han/hon är ansvarig för att se\n" +
			"till att tillstånd finns för att binda Kunden genom ett sådant utförande",
		customerObligationContent7:
			"Kunden ansvarar för att hålla användarinformation, lösenord och SIM-kort med tillhörande koder\n" +
			"väl skyddade så användande av obehörig förhindras.",
		customerObligationContent8:
			"Kunden ansvarar för att informera berörda användare hos Kunden om att Leverantören kan komma\n" +
			"att skicka information om Leverantörens produkter och tjänster.",
		responsibiltyClaims: "Ansvar för eventuella krav som riktas mot oss",
		responsibiltyClaimsContent:
			"Om någon annan riktar krav mot oss på grund av er eller era Användares användning av Tjänsterna\n" +
			"eller tredje parts tjänster ska ni hålla oss skadelösa genom att ersätta oss för den skada eller förlust vi\n" +
			"lidit på grund av kravet. Exempel på sådana krav är begäran om ersättning för intrång i tredje parts\n" +
			"immateriella rättigheter så som upphovsrätt eller varumärke. Vi ska även ha rätt att ge vidare rätten\n" +
			"att framställa sådan begäran och därmed låta någon annan framställa begäran.",
		customerDataInformation: "KUNDDATAINFORMATION",
		customerDataInformationContent:
			"Leverantören behandlar Kunddatainformation för att tillhandahålla och säkerställa driften av\n" +
			"Tjänsten enligt Avtalet, för att fullgöra skyldighet enligt lag eller annan författning samt för\n" +
			"fakturering. Leverantören får använda Kunddata för att fullgöra uppdraget, för statistiska ändamål,\n" +
			"för att förbättra och utveckla våra Tjänster samt för marknadsföring.\n" +
			"Kunden ska på Leverantörens begäran lämna den kunddatainformation som Leverantören behöver\n" +
			"för att tillhandahålla Tjänsten (”Kunddatainformation”).\n" +
			"Kunden svarar för att samtlig Kunddatainformation är korrekt samt för att att Kunddata är fri från\n" +
			"virus, trojaner, maskar eller annan programvara eller kod som kan skada Tjänsterna.\n" +
			"Kund ska även ersätta Leverantör för all skada som åsamkas direkt eller indirekt på grund av att\n" +
			"innehållet i Kunddata eller användningen av Kunddata i Tjänsterna strider mot tillämplig\n" +
			"lagstiftning.\n" +
			"Som kund kan ni alltid ta ut Kunddata från Tjänsterna. I samband med att ni säger upp Avtalet är det\n" +
			"upp till er att ta ut all Kunddata från Tjänsterna som ni kan komma att behöva för framtida bruk.",
		priceAndPayment: "PRISER OCH BETALNING",
		priceAndPaymentContent:
			"Såvida inte parterna kommit överens om annat gäller de priser för Tjänsterna som vid tidpunkten\n" +
			"som parterna ingåt Avralet publicerats på B2B Portal hemsida. Detsamma gäller vid\n" +
			"förlängning av Avtalet.\n" +
			"Tjänstens fasta avgifter debiteras månadsvis eller årsvis, antingen via kortbetalning eller faktura.\n" +
			"Betalningsvillkor för faktura är 15 dagar, såvida inte Parterna kommit överens om något annat.\n" +
			"Vid betalning efter förfallodagen debiteras dröjsmålsränta enligt lag. Leverantören äger dessutom\n" +
			"rätt till ersättning för påminnelseavgift och inkassokostnader. Invändning mot faktura ska vara\n" +
			"Leverantören tillhanda senast tio (10) dagar efter fakturadatum. Invändning ska göras skriftligen\n" +
			"med angivande av skäl till varför Kunden invänder mot fakturan. Leverantören har rätt att med\n" +
			"omedelbar verkan säga upp Avtalet om betalning av faktura inte har skett trettio (30) dagar efter\n" +
			"förfallodatum.\n",
		priceAndPaymentContent2:
			"Varje integration innefattas av två (2) typer av kostnader - en licensavgift samt en återkommande\n" +
			"månadsavgift för molntjänsten.\n" +
			"I samband med köp av integration är det kundens ansvar att säkerställa att korrekt nivåpaket väljs så\n" +
			"att tjänsten täcker kundens månatliga ordervolym. Connectivo AB förbehåller sig rätten att i\n" +
			"efterhand fakturera kunden i de fall kunden överstiger den ordervolym som valt nivåpaket täcker.",
		priceAndPaymentContent3:
			"Vi har rätt att höja priset, med direkt verkan, om höjningen beror på omständigheter vi inte kan\n" +
			"kontrollera, till exempel förändring av valutakurs, skatt eller annan omständighet av ekonomisk\n" +
			"betydelse som påverkar kostnaden för att tillhandahålla Tjänsterna.\n" +
			"Vi har också rätt att höja priset i andra fall men då måste vi kontakta er senast trettio (30) dagar\n" +
			"innan vi höjer priset för att informera er om förändringen. Det nya priset börjar gälla först när nästa\n" +
			"betalningsperiod börjar. Ni har möjlighet att till dagen innan prishöjningen träder ikraft säga upp\n" +
			"Avtalet med oss. Om ingen uppsägning görs av er, anses ni ha godkänt den nya prissättningen.\n",
		agreementTimeAndTermination: "AVTALSTID OCH UPPSÄGNING AV AVTALET",
		agreementTimeAndTerminationContent:
			"Kunden väljer själv vid köptillfälle om denne önskar med bindningstid eller utan bindningstid på\n" +
			"avtalet. Om inte annat avtalats, ä bindningstiden 12 månader och förlängs automatiskt med ett år i\n" +
			"taget, om avtalet inte sägs upp av endera parten senast 30 dagar före avtalstidens utgång.\n" +
			"Avtalet ska sägas upp skriftligen en (1) månad innan avtalstiden löper ut. Om Avtalet inte sagts upp\n" +
			"förlängs Avtalet med tolv (12) månader åt gången, med en uppsägningstid om en (1) månad innan\n" +
			"varje ny tolvmånadsperiod.\n" +
			"Utöver vad som annars anges i detta Avtal har vi båda rätt att med omedelbar verkan säga upp\n" +
			"Avtalet om den andra av oss har:",
		agreementTimeAndTerminationContent1:
			"brutit mot Avtalet och inte rättar till det inom trettio (30) dagar efter att ha mottagit den andra\n" +
			"partens skriftliga meddelande om avtalsbrott, eller",
		agreementTimeAndTerminationContent2:
			"inleder likvidationsförfarande, ansöker om eller försätts i konkurs, ställer in betalningarna eller\n" +
			"på annat sätt kan befaras vara på väg mot obestånd.",
		supportTroubleShooting: "SUPPORT/FELAVHJÄLPNING",
		supportTroubleShootingContent:
			"I Tjänsten ingår, utöver tillgång till serviceportalen som nås via www.bridgero.se, teknisk\n" +
			"och administrativ support via mail och telefon som avser att hjälpa Kunden med frågor avseende\n" +
			"Tjänsten, bistå med nödvändig support från Leverantörens leverantörer, enklare\n" +
			"ändringar/konfigurationer och tillägg/borttag i Tjänsten samt felsökning i Tjänsten om Fel\n" +
			"konstateras i samband med felsökningen.\n" +
			"Ni kan vända er till oss med frågor relaterade till hur våra Tjänster fungerar och ska användas. Vi\n" +
			"kan däremot inte erbjuda support i frågor om till exempel bokföring eller frågor av teknisk karaktär\n" +
			"som inte handlar om Tjänsten.\n" +
			"Ni kan kontakta oss genom de kontaktuppgifter som finns på vår hemsida. Aktuella öppettider för\n" +
			"supporten hittar du på vår hemsida.\n" +
			"Tjänsten omfattar inte support som bland annat men ej uteslutande innebär: felsökning i Kundens,\n" +
			"tredje mans eller annan leverantörs program, support avseende Kundens utrustning, konfiguration\n" +
			"som inte tillhandahållits av Leverantören, support som inte avser Tjänsten, utbildning, längre\n" +
			"telefonmöten, rådgivning eller konsultation. Sådan support debiteras i enlighet med Leverantörens\n" +
			"vid var tid gällande standardprislista",
		supportTroubleShootingContent2:
			"Med ”Fel” avses att Kunden inte kan använda Tjänsten i enlighet med Avtalet och att felet beror på\n" +
			"Leverantören.\n" +
			"Leverantören ska avhjälpa Fel kostnadsfritt inom skälig tid. Med Fel avses inte, exempelvis men ej\n" +
			"uteslutande, brister som:",
		supportTroubleShootingContent3:
			"är av mindre betydelse för Kunden eller som inte hindrar Kunden från att använda Tjänsten,\n" +
			"orsakats av tredje man eller genom omständigheter utanför Leverantörens kontroll.",
		supportTroubleShootingContent4:
			"avser annat än Tjänsten, exempelvis avseende tjänster, produkter och leveranser som sker genom\n" +
			"annan leverantör.\n",
		supportTroubleShootinfContent5:
			"Felanmälningar hanteras av Leverantörens support. Felanmälan sker genom e-post eller\n" +
			"telefonsamtal. Det åligger Kunden att vara supporten behjälplig i felsökning och återkoppla inom 48\n" +
			"timmar med nödvändig information för fortlöpande sökning. Om återkoppling inte sker stängs\n" +
			"ärendet och betraktas som avslutat utan ansvar för Leverantören.\n" +
			"16.8. Felanmälan kan göras dygnet runt, alla årets dagar på info@bridgero.se.\n" +
			"Felanmälan som inkommer på annan tid än på helgfri vardag mellan 08.00-17.00 anses inkommen\n" +
			"nästkommande helgfri vardag kl. 08.00.",
		responsibilityAccountingData: "ANSVAR FÖR BOKFÖRINGSDATA",
		responsibilityAccountingDataContent:
			"Leverantören ansvarar för vidareutveckling av Tjänsten och bestämmer ensam vilka förbättringar,\n" +
			"förändringar och tekniska anpassningar som ska göras i Tjänsten. B2B Portal integrationer\n" +
			"och dess funktionalitet grundas i kontinuerlig efterforskning, maskininlärning samt replikering av\n" +
			"processer såsom löpande bokföring utförd av redovisningskonsulter.\n" +
			"Tjänsten är ett mjukvaruprogram utvecklad i syfte att imitera och till stor grad b2b portal arbetet\n" +
			"redovisningskonsulter utför. Tjänsten ersätter inte helt en redovisningskonsult utan är enbart\n" +
			"kompletterande.\n" +
			"Leverantören garanterar ej att tolkning av transaktionsdata alltid blir fullständigt korrekt, utan\n" +
			"Kunden ansvarar själv för slutlig kontroll av bokföringens korrekthet.\n" +
			"Med förbehåll för de begränsningar som anges i detta avtal, skall Leverantören vara ansvarig för\n" +
			"korrigering av felbokningar till följd av dess försumlighet. I händelse av fel eller brister som kan\n" +
			"tillskrivas Leverantören åtar denne sig att agera för att korrigera sådana fel utan oskäligt dröjsmål.\n" +
			"I det fall det är fråga om ett fel i Tjänsterna som gör att bearbetningen av Kunddata leder till ett\n" +
			"felaktigt resultat, så åtar vi oss att, så snart som omständigheterna kräver med hänsyn till typen av\n" +
			"fel och omständigheterna i övrigt, utan extra kostnad för er, göra en ny bearbetning av Kunddata.\n" +
			"Kunden har inte rätt till en minskning av betalning, debitering eller andra påföljder i händelse av\n" +
			"driftstörningar eller felrapportering som kan uppstå.\n" +
			"Felanmälan måste ges av kunden i enlighet med de instruktioner som aviserats av Leverantören från\n" +
			"tid till tid och inom en rimlig tid för upptäckten av felet. I avsaknad av uppsåt eller grov oaktsamhet\n" +
			"av Leverantören, antar denne annars inget ansvar för fel eller brister i tjänster som ingår däri.\n",
		modificationTermsAndCondition: "ÄNDRING AV AVTALSVILLKOR",
		modificationTermsAndConditionContent:
			"Leverantören förbehåller sig vidare rätten att ändra villkoren i detta Tjänsteavtal, inklusive, men\n" +
			"inte begränsat till, de priser som Leverantören har från en tid till en annan. Kunden skall informeras\n" +
			"om sådana ändringar via e-post eller genom den information som görs tillgänglig på B2B Portal\n" +
			"hemsida en (1) månad före ikraftträdandet. Om kunden inte godkänner ändringen, har\n" +
			"Kunden inom trettio (30) kalenderdagar från avsändandet av e-post eller, i förekommande fall,\n" +
			"trettio (30) kalenderdagar från ändringen publiceras på B2B Portal webbplats, rätt att säga\n" +
			"upp avtalet med omedelbar verkan. Om avtalet inte sägs upp av Kunden inom nämnda tid, ska\n" +
			"Kunden anses ha godkänt de nya villkoren.\n",
		transferOfTerms: "ÖVERLÅTELSE AV AVTAL",
		transferOfTermsContent:
			"Kunden har inte rätt att utan Leverantörens föregående skriftliga medgivande överlåta, pantsätta\n" +
			"eller på annat sätt disponera över eller upplåta säkerhetsrätt avseende sina rättigheter och/eller\n" +
			"skyldigheter enligt Avtalet. Leverantören har rätt att överlåta och/eller på annat sätt disponera över\n" +
			"sina rättigheter och/eller skyldigheter enligt Avtalet.\n",
		marketing: "MARKNADSFÖRING",
		marketingContent:
			"Vi får marknadsföra tjänster till er som vi eller någon samarbetspartner till oss tillhandahåller.\n" +
			"Marknadsföringen kan ske i Tjänsterna, genom e-post eller på annat sätt.",
		agentsAndSubContractors: "OMBUD OCH UNDERLEVERANTÖRER",
		agentsAndSubContractorContent:
			"Leverantören har rätt att anlita underleverantörer och samarbetspartners för att utveckla Tjänsterna\n" +
			"och fullgöra sina åtaganden enligt Avtalet",
		intellectualProperty: "IMATERIELLA RÄTTIGHETER",
		intellectualPropertyContent:
			"Samtliga immateriella rättigheter till Tjänsten samt ändringar och variationer därav, är och ska\n" +
			"fortsatt vara Leverantörens egendom. Ni får inte kopiera, ändra eller på annat sätt hantera\n" +
			"programvara eller annat material som hör till Tjänsterna.\n" +
			"Vid immaterialrättsintrång som beror på Kunden gäller följande:\n" +
			"Kund ska försvara Leverantör om krav riktas eller talan förs mot leverantör om intrång på grund av\n" +
			"kunden eller den som använder Tjänsterna i strid med detta Avtal. Detta sker då på kundens egna\n" +
			"bekostnad. Kund ska också ersätta Leverantör för alla de kostnader och skadestånd som Leverantör\n" +
			"genom förlikning eller dom kan bli skyldiga att betala.\n",
		messages: "MEDDELANDEN",
		messagesContent:
			"Meddelanden under Avtalet lämnas till av Leverantören respektive Kunden angiven adress i Avtalet,\n" +
			"såvida inte meddelanden med fördel lämnas på annat sätt, exempelvis via telefon eller e-mail.\n" +
			"Meddelanden ska anses ha kommit den mottagande Parten tillhanda fem (5) dagar efter avsändandet\n" +
			"med brev och tre (3) dagar efter avsändande med e-post och direkt via telefon. Parterna ska\n" +
			"skyndsamt informera varandra om adressändringar",
		forceMajure: "FORCE MAJEURE",
		forceMajureContent:
			"Leverantören ska inte vara ansvarig för underlåtenhet att fullgöra Avtalet om detta föranleds av en\n" +
			"omständighet som legat utanför Leverantörens kontroll och som Leverantören inte skäligen kunde\n" +
			"förväntas ha räknat med eller tagit i beaktande vid tiden för Avtalets ingående och vars följder\n" +
			"Leverantören inte heller skäligen kunde ha undvikit eller övervunnit, såsom krig, brand,\n" +
			"översvämning, åsknedslag, eldsvåda, terrordåd, hacker-attack, ockupation, lock-out,\n" +
			"importbegränsningar, sanktioner eller liknande, eller fel eller förseningar i leverans från\n" +
			"underleverantör på grund av omständigheter som denne ej kunnat råda över.",
		applicableLaw: "TILLÄMPLIG LAG OCH TVISTELÖSNING",
		applicableLawContent:
			"Parternas rättigheter och skyldigheter vid tolkning och tillämpning av Avtalet ska bestämmas i\n" +
			"enlighet med svensk lag. Tvister som uppstår i anledning av Avtalet ska slutligt avgöras genom\n" +
			"skiljedomsförfarande administrerat av Västsvenska Handelskammares Skiljedomsinstitut.\n" +
			"Talan avseende förfallna obetalda fordringar äger dock Part anhängiggöra inför allmän domstol.\n" +
			"Reglerna om förenklat skiljeförfarande ska tillämpas, om inte Västsvenska Handelskammarens\n" +
			"Skiljeråd med beaktande av tvistens svårighetsgrad, tvisteföremålets värde och övriga\n" +
			"omständigheter bestämmer att reglerna om ordinärt skiljeförfarande ska tillämpas. I sistnämnda fall\n" +
			"ska Skiljerådet också bestämma om skiljenämnden ska bestå av en eller tre skiljemän.\n",
		termsFooter:
			"Skiljeförfarandets säte ska vara Göteborg, Sverige. Språket för förfarandet ska vara svenska.",
		termsFooterContent: "Reviderad januari 2022.",
		authenticationInProcess: "Autentisering pågår",
		maxImageUploadMessage: "jpg/png-filer med en dimension på 5000x3000px",
		maxArticleImageUploadMessage:
			"jpg / png-filer med en maxdimension på 800x800px",
		maxImageUploadMessageError:
			"Bilduppladdningen måste vara av dimensionen 5000 x 3000 px",
		maxArticleImageUploadMessageError:
			"Bilduppladdningen måste vara av dimensionen 800 x 800 px",
		maxCreditLimitError: "max kredit kanske inte är null.",
		categoryRequired: "Kategori kanske inte är null.",
		subscriptionSuccessMessage: "Tack, välkommen att logga in ert konto nu",
		emailVerificationMessage: "Verifierar din e-post.....",
		emailError:
			"systemet kunde inte verifiera din e-post, se till att du klickar på rätt länk",
		errorTag: "Det gick inte att hämta bloggtaggen",
		proceed: "Fortsätt",
		pending: "EJ ANSLUTEN",
		completed: "ANSLUTEN",
		info: "INFO",
		error: "FEL",
		display_store_to_all_buyers:
			"Synliggör er butik för alla ansluta kunder i B2B portalens nätverk",
		apply_display_store_to_all_buyers:
			"Om du ej bockar för rutan är er butik enbart synlig för era inbjudna kunder",
		archive: "Arkiv",
		show_more: "visa mer",
		show_less: "visa mindre",
		error_processing_request: "Det gick inte att utföra uppgiften",
		max_order_limit_exceeded: "Du har nått din max kredit gräns",
		order_confirmation_text: "Orderbekräftelsetext",
		order_confirmation_text_help_text: "Orderbekräftelsetext",
		order_confirmation_text_en: "Orderbekräftelsetext Engelska",
		bulk_article_upload: "Massuppladdning av artiklar",
		excel_file_format_text: "Endast xlxs, xlx excel-format stöds",
		article_upload_in_progress: "Artikeluppladdning pågår",
		article_excel_document_required: "Uppladdning av Excel-dokument krävs",
		upload_bulk_article_excel: "Ladda upp massartikel (excel)",
		export_product_data: "Exportera artikel (excel)",
		download_excel_template: "Ladda ner artikelmall (excel)",
		articleLongDescription: "Lång produkt text",
		role: "Roll",
		event: "Händelse",
		fieldTitle: "Fälttitel",
		fieldMap: "Fältnamn i Ekonomiprogrammet",
		required: "Obligatorisk",
		fieldTitleHelpText:
			"Lägg till fält som kunden kan fylla i vid Orderläggning. Namnge fältet och välj sedan vilket referensfält i ekonomiprogrammet värdet ska skickas till",
		fieldMapHelpText:
			"Dessa fält är tillgängliga i Ekonomiprogrammet som referensfält",
		requiredHelpText:
			"Bocka för om ni vill att fältet ska vara obligatoriskt för kunden att fylla i vid beställning",
		companyConfigurationSuccess:
			"Konfigurationen för företagets kassa har sparats",
		companyEmailConfigurationSuccess:
			"Företagets e-postkonfigurationer har sparats",
		accountingFieldAlreadyExist: "Redovisningsfältet finns redan",
		selectAccountField: "Välj kontofält",
		emptyUploadedZip: "Uppladdad zip-fil är tom",
		invalidExcelFormat:
			"Ogiltigt excel-format, vänligen följ det excelmall som getts som exempel.",
		addedSuccess: "Posten har lagts till",
		updateArticleSpecification: "Uppdatera artikelspecifikationen",
		specificationTitle: "Specifikationens titel",
		specificationText: "Specifikationstext",
		actions: "Åtgärd",
		specification: "Produktspecifikationer",
		text: "Text",
		discountedPrice: "Kampanjpris",
		purchase_price: "Inköpspris",
		deliveryTime: "Leveranstid",
		updateSuccess: "Uppgifterna har uppdaterats",
		allowCustomText: "Lägg till extra textfält för artikel vid beställning",
		articleCustomTextTitle: "Titel för extra textfältet",
		allowCustomTextHelpText:
			"Syftet med denna funktion är att ge köparen ett extra inmatningsfält när de lägger till produkten i varukorgen.",
		additionProductItem:
			"Skriv in informationen och avgränsa med kommatecken",
		itemUpdated: "Uppdaterat",
		campaignScheduleDate: "Kampanj period",
		uploadExcelFile: "Ladda upp excel-fil",
		add: "Lägg till ",
		customerDeliveryAddress: "Kundens leveransadress",
		addDeliveryAddress: "Lägg till leveransadress",
		use_accounting_customer_number_for_identifier:
			"Använd ekonomiprogrammets kundnummer som unik identifierare",
		use_accounting_customer_number_for_identifier_text:
			"Använd ekonomiprogrammets kundnummer som unik identifierare",
		emailSettings: "E-postinställningar",
		emailFrom: "Avsändar mejladress",
		emailSetting: "E-post inställning",
		halfLogo: "Företagslogotyp liten storlek",
		fullLogo: "Företagslogotyp stor storlek",
		estimatedDeliveryDay: "Ange beräknad leveranstid i dagar",
		articleDescriptionError: "Ange artikelbeskrivning",
		articleSalesPriceError: "Ange försäljningspris",
		zipTextMsg: "Filen måste vara i .zip-format",
		zipTextDimensionMsg: "Bilder får vara max 800 x 800 px",
		clickUploadZipText: "Här bifogar du .zip filen med produktbilder",
		ShopOwner: "Ägare",
		BuyerEmployee: "Anställd",
		EndUserCustomer: "Kund",
		ShopOwnerEmployee: "Anställd",
		excelReport: "EXCEL MASSHANTERING",
		locationName: "Platsnamn",
		streetAddress: "Gatuadress",
		processingOrder: "Bearbetar order",
		checkoutMessage: "Här kan du lämna en kommentar för ordern",
		Delivery: "Leverans",
		days: "dagar",
		estimatedDeliveryDate: "Beräknat leveransdatum",
		viewProductDetail: "Produktinformation",
		buyerNote: "Meddelande från kund",
		internalNote: "Intern anteckning",
		customerEmptyError: "Intern anteckning",
		deliveryAddressOptions: "Alternativ för leveransadress",
		country: "Land",
		enableMinimumQty: "Sätt minimum beställningskvantitet",
		minimumOrderQty: "Minimum antal",
		minimumOrderQtyError:
			"Minsta beställningskvantitet måste vara större än 1",
		customerMininmumOrderQtyError:
			"Minsta beställningskvantitet måste vara",
		minimumOrderAmount: "Minimum orderbelopp",
		customerMinimumOrderAmountError: "Minimum ordersumma måste vara ",
		tasksHandle: "Markera anteckning som hanterad",
		categoryTree: "Kategori träd",
		minimum_order_amount: "Minsta ordersumma",
		minimum_order_amount_text: "Minimum ordersumma",
		minimum_order_amount_error: "Ordern understiger minimumbeloppet",
		addFaq: "Lägg till FAQ",
		editFaq: "Uppdatera FAQ",
		faqSuccess: "FAQ har skapats",
		faqCategorySuccess: "FAQ kategori har skapats",
		question: "Fråga",
		answer: "Svar",
		removeFaq: "Radera FAQ",
		removeFaqCategory: "Radera Kategori",
		cancelled: "Makulerat",
		cancelling: "Makulerat",
		supplierInfoText:
			"Här hittar ni alla grossister som nu är anslutna till och även de grossister " +
			"som är verksamma i plattformen. Om ni önskar få bli återförsäljare " +
			"till en specifik grossist kan ni klicka in på denne och skicka in en ansökan. " +
			"För att logga in på en specifik grossist webbshop som ni är anslutna till," +
			" klicka på presentations boxen nedan",
		invalidExcelHeaderError:
			"Vänligen följ kolumn ordningen och titeln som är angivet i excelmallen",
		invalidFields: "ogiltiga fält",
		emptyEntry: "Lägg till en post",
		childCategorySuccess: "Underkategori har lagts till",
		emptyNodeSelected: "Tom underkategori har valts",
		subcategoryAssignedSuccess: "Underkategori är nu sparad",
		categoryTreeModification:
			"",
		selectChildCategory: "Välj Underkategori",
		categoryStructure: "Kategoristruktur",
		removeSubCategory: "Ta bort underkategori",
		assignArticleCategory: "Tilldela kategori till artikel",
		assign: "Tilldela",
		articleCategoryAssign: "Kategori har tilldelats",
		articleCategoryAssignError:
			"Fel vid tilldelning av kategori till artikel",
		additionalSubCategory: "En lager till av underkategori",
		tags: "Taggar",
		postStatus: "Status",
		sellerBackendUrl: "Backend Domän",
		sellerFrontendUrl: "Frontend Domän",
		emailHost: "E-postvärd",
		emailUsername: "Användarnamn",
		emailPassword: "Lösenord",
		emailPort: "Port",
		useSSL: "Använd SSL",
		useTLS: "Använd TLS",
		connectionType: "Kopplingstyp",
		connectionTypeHelpText:
			"Detta anger vilken smtp-anslutningstyp som ska användas för att skicka e-post",
		renderOnlyCustomerSpecificArticle: "Visa endast kundspecifik artikel",
		renderOnlyCustomerSpecificCategories:
			"Visa endast kundspecifika kategorier",
		availableOnlyForSpecificCustomer:
			"Gör den här kategorin synlig för endast vissa kunder",
		customerSpecificCategoryText:
			"Synliggör kategorin för enbart specifika kunder",
		renderOnlyCustomerSpecificReferenceField:
			"Visa referensfältet för specifik kund enbart",
		categorySpecificUpdate: "Kundspecifik kategori uppdaterad",
		setCustomer: "Ställ in kund",
		applyTo: "Synlighet",
		applyToCustomerText: "Specifika kunder enbart",
		allCustomer: "Alla kunder",
		customerSpecific: "Kundspecifik",
		showCustomerResalePrice: "Visa Rekommenderat Konsumentpris",
		consumer_resale_field_title: "Titel för Konsumentprisfältet",
		consumer_resale_price: "Återförsäljningspris för konsument",
		customerSpecificCheckoutText:
			"Synliggör referensfält för enbart specifika kunder",
		// renderOnlyCustomerSpecificArticle: 'Får bara se kundspecifika produkter',
		// renderOnlyCustomerSpecificCategories: 'Får bara se kundspecifika kategorier',
		// availableOnlyForSpecificCustomer: 'Synliggör kategorin för bara vissa kunder',
		// customerSpecificCategoryText: 'Ange vilka kunder som denna kategori är synlig för',
		// customerSpecificCategoryText: 'Ange vilka kunder som denna kategori är synlig för',
		permissionDenied: "Åtkomst nekad",
		productArticleCategoryAssign: "Produktkategorier",
		productEmpty: "Ingen produkt tillgänglig för närvarande",
		searchEmpty: "Din sökning matchar inte någon produkt",
		filterEmpty: "Ditt filter matchar inte någon produkt",
		hide_stock_quantity: "Dölj lagerkvantitet",
		b2bOrderNumber: "B2B ordernummer",
		delete: "Radera",
		self_allowed_create_delivery_addresses:
			"Tillåt kund att skapa egna leveransadresser",
		is_default_address: "Default Adress",
		apply_customer_specific_user_profiles:
			"Använd kundspecifik användarprofil",
		apply_customer_currency_on_orders:
			"Använd kundinställd valuta på order",
		no_company_text: "Välj en butik för att få åtkomst till dessa resurser",
		not_allowed_setting:
			"Din leverantör tillåter inte åtkomst till denna inställning. Kontakta din leverantör",
		activeFilter: "Aktiva filter",
		removeAllCustomerFromArticle:
			"Är du säker på att ta bort alla kunder från den här artikeln",
		customerSelectionEmpty: "Välj en eller flera kunder att tilldela",
		totalImageToUpload: "Totalt antal bilder att ladda upp",
		// estimatedTime: 'Beräknad uppladdningstid:',

		all: "Alla",
		blogDataSuccess: "Blogginformationen har uppdaterats",
		articlePdf: "Artikeldokument (pdf)",
		clickUploadPdf: "Klicka för att ladda upp (pdf)",
		pdfSizeText: "pdf-filer med en max storlek på 10mb",
		fileToLarge: "Filen överskrider maxstorlek",
		fileExceedLimit: "Filuppladdning överskrider gränsen",
		adminEmailAddress: "Admin e-postadress",
		send_order_notifications_to_admin:
			"Skicka meddelande till admin vid beställning",
		disable_customer_order_conf_emails:
			"Inaktivera orderbekräftelser till köparen",
		show_standard_price_additional_to_customer_specific_price:
			"Visa standardpris utöver kundspecifikt pris",
		standard_price: "Standard pris",
		relevant: "Relevant",
		assignProductBlog: "Rekommendera produkt",
		searchProduct: "Sök artikel",
		linkedArticle: "Länkade artiklar",
		linkedArticleField: "Välj vilka artiklar som ska länkas till inlägget",
		linkedArticleHelpText:
			"Genom att länka produkter till ditt blogginlägg kommer de att inkluderas och markeras i blogginlägget",
		sendAsNewsLetter: "Skicka som nyhetsbrev via e-post också",
		recommendedProduct: "Rekommenderade produkter",
		recommendedAccessories: "Rekommendera tillbehör",
		accessories: "Tillbehör",
		customerInviteReminder: "Skicka inbjudan påminnelser till kund",
		customerInviteReminderHelpText:
			"Ställ in om du vill att din kund får påminnelser via email om de ännu inte har accepterat inbjudan",
		once_in_a_week: "En gång i veckan",
		twice_in_a_week: "Två gånger i veckan",
		three_time_in_a_week: "Tre gånger i veckan",
		dont_send_reminder: "Skicka inte påminnelse",
		profit_margin: "Vinstmarginal",
		totalSubscription: "Totalt abonnemang",
		totalSupplier: "Total leverantör",
		totalBuyer: "Totala köpare",
		totalUser: "Totalt antal användare",
		logo: "Logotyp",
		buyers: "Köpare",
		companies: "Företag",
		notConnectedToCompany: "Inte kopplad till något företag",
		settingUpAccount: "Vänligen vänta... Konfigurerar konto",
		accessBuyerDashboard: "Gå till kundens vy",
		permissionDeniedResource:
			"Du har för närvarande inte behörighet att komma åt denna sida",
		allow_seller_place_order:
			"Tillåt säljaren att göra en beställning för din räkning",
		settingUpdated: "Inställningen har uppdaterats",
		editDeliveryAddress: "Uppdatera leveransadress",
		productDownload: "Ladda ner Produktlista",
		orderHelpText: "Redigera beställning",
		orderManagement: "Orderhantering",
		deliveryDate: "Leveransdatum",
		updateOrder: "Uppdatera beställning",
		orderDetail: "Beställningsinformation",
		orderRows: "Beställ rader",
		customerDetail: "Kunddetaljer",
		deliveryInformation: "Leveransinformation",
		invoiceType: "Faktura",
		createOrder: "Skapa order",
		account: "Konto",
		vatIncluded: "Moms ingår",
		yourOrderNumber: "Ditt beställningsnummer",
		// yourReference: 'Er referens',
		paymentTerms: "Betalningsvillkor",
		priceList: "Prislista",
		currency: "Valuta",
		project: "Projekt",
		vatType: "Typ av moms",
		emptyRows: "Order/faktura måste innehålla en eller flera rader",
		cancelOrderInvoiceErrorText:
			"Annullerad order/faktura kan inte uppdateras",
		cancelOderInvoiceHelpText: "Avbryt beställning eller faktura",
		cancelSuccessful: "Avbröts framgångsrikt",
		unittypes: "Enhetstyper",
		addNewUnitType: "Skapa enhetstyper",
		unit: "Enhet",
		updateUnitType: "Uppdatera enhetstyp",
		removeUnitType: "Ta bort enhetstyp",
		unitUpdated: "Enheten har uppdaterats",
		unitCreated: "Enheten har skapats",
		productInUnit: "Produkten säljs som ett paket",
		productPackageText: "Hur många enheter ska gälla per paket",
		productPdf: "Produkinformationsblad",
		blogVisibilty: "Synlighet",
		generalSetting: "Allmän inställning",
		stockManagement: "Lagerhantering",
		notifications: "Aviseringar",
		productVariations: "Varianter",
		invalidDimension: "Ogiltig dimension för den uppladdade bilden",
		generalMaxCreditError: "Max kredit måste vara större än 0",
		adminEmailAddressError: "Admin e-postadress krävs",
		customEmailRuleHelpText:
			"Välj om du vill att kunden ska påminnas om inbjudan efter intiala inbjudan är skickad",
		sendEmailToAdminHelpText:
			"Bocka för om du vill motta en email avisering varje gång din kund lägger en order",
		dontSendOrderConfirmationToBuyerText:
			"Bocka för om du vill att kunden inte ska motta en orderbekräftelse via email",
		allow_seller_to_see_your_orders:
			"Tillåt säljaren att se sina beställningar",
		othersInformation: "Annan information",
		productRecommendations: "Produktrekommendationer",
		priceInformation: "Prisinformation",
		productInformation: "Produktinformation",
		newsletterDate: "Datum för att skicka nyhetsbrev",
		openBlogRecommendedProduct: "Länkad produkt",
		searchArticles: "Sök artikel",
		previewEmail: "Förhandsvisa Email",
		profitMargin: "Vinstmarginal",
		sellerAllowedToPlaceOrder:
			"Grossisten får behörighet att lägga ordrar för vår räkning",
		sellerAllowedToSeeOrder:
			"Grossisten får behörighet att se vår ordrerhistorik",
		stockThresholdQuantity: "Visa lageraldo som noll vid denna saldogräns",
		notification: "Aviseringar",
		selectRule: "Ställ in intervall",
		invoice_: "Faktura",
		estimatedTime: "Beräknad uppladdningstid:",
		outOfStock: "Produkten finns ej i lager",
		textSchedule: "Nyhetsbrev utskick skedde: ",
		sync_only_webshop_articles: "Synka bara webbshops artiklar",
		connect: "Anslut",
		stockpoint: "Lagerställe",
		allow_seller_send_employee_invite:
			"Tillåt säljaren att skicka inbjudan till anställda",
		articleVariations: "Artikelvariationer",
		variations: "Variationer",
		readNotification: "Lästa aviseringar",
		unreadNotification: "Olästa aviseringar",
		notificationEmpty: "Inga aviseringar",
		microsoft: "Microsoft 365",
		other_mail_server: "Andra e-postserver",
		mail_server: "Mejl server",
		createSuccess: "Skapat",
		createOrderOrInvoiceCopyText: "Vill du skapa en kopia av denna",
		createArticle: "Skapa artikel",
		deleteSuccess: "Raderat",
		deleteArticle: "Ta bort artikel",
		createCustomer: "Skapa kund",
		otherInformation: "Annan information",
		customerInformation: "Kundinformation",
		updateCustomer: "Uppdatera kund",
		customerAddress: "Faktura adress",
		total: "Totalt",
		vat: "Varav moms",
		copyOrder: "Kopiera Order",
		copyInvoice: "Kopiera Faktura",
		loading: "Laddar info",
		editCustomer: "Redigera kund",
		revenue: "Intäkter",
		hideDetails: "Dölj detaljer",
		whiteLabelSection: {
			fullImageTextCaption: "Maxdimensioner 200 * 200. Filformat .jpeg",
			halfImageTextCaption: "Maxdimensioner 100 * 100. Filformat .jpeg",
			favIconTextCaption: "Maxdimensioner 16 * 16. Filformat .jpeg",
			favIcon: "Favicon",
		},
		productDetail: "Produktdetaljer",
		hide_sales_price: "Dölj inköpspris",
		hide_delivery_time: "Dölj leveranstid",
		hide_discounted_price: "Dölj rabatter",
		hide_recommended_price: "Dölj rek konsumentpris",
		hideDetailsHelpText:
			"Här kan du styra och dölja viss produktinformation på produktsidan. För att styra vilken info, gå till inställningar sidan. Default kommer att dölja priser enbart.",
		pinAsTopPost: "Ställ in som huvudinlägg",
		bannerBackgroundColor: "Banner Bakgrundsfärg",
		toWebshop: "Till Webbshop",
		buyer_sidebar_menu_colorHelpText:
			"Här kan ni ställa in vilken färg sidomenyn ska ha för era kunder",
		buyer_sidebar_menu_color: "Sidmeny för ÅF vy",
		documentDownloadInProgress: "Underlaget laddas ner",
		address: "Leveransadresser",
		pinCategory:
			"Vill du sätta produkter i kategori {category_name} som default i produktöversiktssidan?",
		unPinCategory:
			"Vill du plocka bort produkter i kategori {category_name} som default i produktöversiktssidan?",
		recommended: "Rekommenderad",
		clearCategoryFilter: "Nollställ",
		authorize_microsoft_365: "Autentisera Microsoft 365 appen",
		sync_only_corporate_customers:
			"Synka bara Företagskunder från ekonomiprogrammet",
		import_all_orders_from_accounting:
			"Importera samtliga ordrar från ekonomiprogrammet",
		import_all_orders_from_start_date:
			"Import ordrar från ekonomiprogrammet från detta datum",
		viewAll: "Visa alla",
		// published: 'Publiceras',
		draft: "Utkast",
		categoryUpdated: "Kategorin är uppdaterad",
		notificationUpdated: "Aviseringar är uppdaterad",
		favIconText: "",
		blogPostUpdated: "Uppdaterat",
		unpinBlog: "Blogginläggen plockas bort som huvudinlägg nu",
		productImages: "Bilder",
		delivered: "SLUTLEVERERADE",
		processing: "ICKE LEVERERADE",
		myProfile: "Min Profil",
		buyerDetails: "Kunddetaljer",
		orderItems: "Orderrader",
		notificationNowMarkAsRead: "Notifiering är markerad som läst",
		savedAddress: "Sparade Leveransadresser",
		videoGuides: "Video guider",
		noSubscriptionSelected: "Vänligen välj en prenumerationstyp",
		backToFortnoxConnect:
			"Något gick fel, vänligen återanslut igen till Fortnox",
		integrations: "Integrationer",
		subscriptionNotSelectedByCustomer:
			"Det gick inte att gå vidare eftersom prenumeration är inte vald. Vänligen navigera tillbaka och välj ett paket.",
		yet_to_connect_to_integration:
			"Bolaget är inte anslutet till ett integration ännu, vänligen klicka nedan för att komma igång",
		getStarted: "Anslut",
		"status": {
			"processing": "Behandles",
			"received": "Modtaget",
			"delivered": "Leveret"
    	}
	},
	no: {
		fields: {
			username: "Brukernavn",
			email: "E-post",
			password: "Passord",
			domain: "",
			newPassword: "Nytt passord",
			firstName: "Fornavn",
			lastName: "Etternavn",
			setDomain: "",
			repeatPassword: "Gjenta passordet",
			confirmNewPassword: "Bekreft nytt passord",
		},
		validation: no.messages,
		onboardHeader: "Velkommen til vår B2B portal",
		toDestination: "",
		connectNow: "Ja, installer appen",
		signUpText: "Oppretter konto og forbereder for godkjenning. Vent litt",
		codeAlreadyUse: "Autorisasjonskode er allerede i bruk",
		username: "Brukernavn",
		password: "Passord",
		loginHeader: "Kundeportal",
		forgetPassword: "Glemt passord",
		resetPassword: "Nullstil passord",
		newPassword: "Nytt passord",
		confirmNewPassword: "Bekreft passord",
		login: "Innlogging",
		loginSuccess: "Kontoinnlogging suksessfull",
		fieldRequired: "Alle felt er påkrevd",
		emailAddress: "E-postadresse",
		customerAlready: "Allerede kunde",
		resettingPassword: "Nullstiller",
		passGreater: "Passord må være lengre enn 6",
		passwordNotMatch: "Passordet stemmer ikke",
		accountCreated: "Konto opprettet suksessfullt",
		serverError: "Oops...Serverfeil",
		repeatPassword: "Gjenta passord",
		setDomain: "Sett domene for din portal",
		createAccount: "Opprett din konto for å starte",
		signUp: "Registrer deg",
		notCustomer: "Ikke kunde",
		creatingAccount: "Oppretter konto...",
		inviteCode: "Invitasjonskode",
		invite: "Inviter",
		accept: "Aksepter",
		reject: "Avvis",
		acceptStatement: "Godkjent brukerforespørsel?",
		rejectStatement: "Avvis brukerforespørsel?",
		articles: "Artikler",
		confirmPassword: "Bekreft passord",
		chooseShop: "Velg en butikk",
		dashboard: "Kontrollpanel",
		orderHistory: "Bestillingshistorikk",
		invoiceHistory: "Fakturahistorikk",
		orders: "Bestillingshistorikk",
		customerName: "Kundenavn",
		orderNumber: "Bestillingsnummer",
		orderDate: "Bestillingsdato",
		orderStatus: "Bestillingsstatus",
		orderSummary: "Totalt",
		reference: "Referanse",
		action: "Filadministrasjon",
		search: "Søk",
		subscription: "Abonner",
		show: "Viser",
		invoice: "Fakturahistorikk",
		invoiceNumber: "Fakturanummer",
		invoiceDate: "Fakturadato",
		invoiceStatus: "Fakturadato",
		invoiceSummary: "Fakturaoppsummering",
		product: "Produkt",
		youHave: "Du har",
		itemsInCart: "produkt(-er) i din handlekurv",
		subTotal: "Sum totalt",
		checkout: "Utsjekk",
		logout: "Logg ut",
		brand: "Brand",
		totalOrdered: "Totalt bestilt",
		remove: "Fjern",
		totalAmountIncVat: "Totalt beløp inkl mva",
		totalAmountExVat: "Totalt beløp ekskl mva",
		vatAmount: "MVA beløp",
		deliveryDetails: "Leveringsdetaljer",
		deliveryName: "Leveringsnavn",
		deliveryAddress: "Leveringsadresse",
		deliveryZipCode: "Leveringspostnummer",
		deliveryCity: "Leveringsby",
		deliveryCountry: "Leveringsland",
		email: "E-post",
		ourReference: "Vår referanse",
		ourOrderNumber: "Vårt bestillingsnummer",
		message: "Melding",
		sameBillingAddress: "Bruk samme som bestillingsadresse",
		allowBackOrder: "Forhåndsbestilling/Restordre Artikkel",
		allowBackOrderHelpText: "Hvis avkrysset, kan kunden kjøpe produktet uavhengig av lagerbeholdning. Lagerbeholdningen vil ikke være synlig for kunden",
		preOrderItem: "Forhåndsbestilling/Restordre Vare",
		buyProduct: "Kjøp",
		placeOrder: "Bestill",
		pleaseWait: "Vennligst vent",
		disposableQty: "Antall",
		itemRemove: "Produkt fjernet fra handlekurv",
		itemAdd: "Produkt(er) lagt til i handlekurv",
		orderConfirmation: "Bestillingsbekreftelse",
		thankYouOrder: "Takk for din bestilling!",
		proceedOrder:
			"vil fortsette å behandle bestillingen og en bekreftelse er sendt til",
		date: "Dato",
		amount: "Beløp",
		seller: "Selger",
		buyer: "Kjøper",
		apply_english_on_all_templates: "Sätt engelska som språk vid kommunikation",
		customerSettings: "Kundeinnstillinger",
		users: "Brukere",
		companySettings: "Selskapsinnstillinger",
		totalOrderToday: "Totalt bestillinger i dag",
		totalOrderMonth: "Totalt bestillinger denne måned",
		totalCustomerVisit: "Totalt kundebesøk i dag",
		averageOrderAmount: "Gjennomsnittlig bestillingsbeløp",
		totalRevenue: "Total B2B omsetning",
		today: "I dag",
		revenueSales: "Omsetning",
		bestSellingArticle: "Best selgende produkt",
		totalInvitedCustomers: "Totalt inviterte kunder",
		totalPublishArticle: "Totalt publiserte artikler",
		totalSalesMonth: "Totalt salg denne måned",
		customerHighestPurchase: "Kunde med høyeste kjøp",
		articleSetting: "Artikkelinnstillinger",
		articleNumber: "Artikkel#",
		description: "Beskrivelse",
		price: "Pris",
		priceListSelection: "Velg prisliste",
		thumnails: "Thumnails",
		inviteCustomer: "Inviter kunder",
		number: "Nummer",
		organizationNumber: "Organisasjonsnummer",
		category: "Kategori",
		status: "Status",
		notInvited: "Ikke invitert",
		pendingInvitation: "Ventende",
		waitingInvite: "Venter",
		accepted: "Akseptert",
		confirmed: "Bekreftet",
		createUserCategory: "Opprett brukerkategori",
		customerCategoryName: "Navn på kundekategori",
		accountNumber: "Kontonummer",
		costCenter: "Kostnadsenter",
		addUser: "Legg til brukere",
		firstName: "Fornavn",
		lastName: "Etternavn",
		addBtn: "Legg til",
		adding: "Legger til",
		manageUser: "Administrer brukere",
		userSetting: "Brukerinnstillinger",
		companyName: "Firmanavn",
		companyNumber: "Firmanummer",
		zipCode: "Postnummer",
		city: "By",
		phoneNumber: "Telefonnummer",
		billingAddress: "Fakturaadresse",
		invoiceEmail: "Faktura e-post",
		shopDomain: "Shop domene URL",
		brandName: "Selskapsnavn",
		selectPricelist: "Velg prisliste",
		postInvoice: "Post faktura til Fortnox",
		chooseCompanyLogo: "Velg firmalogo",
		inviting: "Inviterer",
		createCustomerCategory: "Opprett kundekategori",
		selectCostCenter: "Velg kostnadsenter",
		create: "Opprette",
		creating: "Oppretter",
		uploadLogo: "Last opp logo",
		chooseFile: "Velg en fil",
		crop: "Skaler",
		cropping: "Skalerer",
		finishUpload: "Ferdig med opplasting",
		updateProductImage: "Oppdater produktbilder",
		articleDetail: "Artikkeldetaljer",
		quantity: "Antall",
		dropImageFiles: "Dropp filen her eller", // Släpp bilden här
		clickToUpload: "klikk for å laste opp",
		fileFormat: "filer mmindre enn 500kb",
		maximumImages: "Maksimalt 4 bilder",
		uploading: "Laster opp",
		updateArticle: "Oppdater artikler",
		articleDescription: "Artikkelbeskrivelse",
		articleName: "Artikkelnavn",
		articleVideoURL: "Produktvideo URL",
		updateCustomerInfo: "Oppdater kundeinformasjon",
		articleImage: "Artikkelbilde",
		removeUser: "Er du sikker på at du vil fjerne",
		continue: "Fortsett",
		warning: "Varsel",
		removeSuccessful: "Fjernet",
		updateUserSuccess: "Bruker oppdatert",
		enterValidPrice: "Vennligst skriv en gyldig pris",
		enterValidQty: "Vennligst skriv gyldig antall",
		articleUpdateSuccess: "Artikkelinformasjon oppdatert",
		removeImage: "Er du sikker på at du vil fjerne dette bilde",
		imageRemoveSuccess: "Bilde fjernet",
		yes: "Ja",
		no: "Nei",
		cancel: "Avbryt",
		cancelPurchase: "Avbryt kjøp",
		undoSelection: "Angre valg",
		invalidOrganizationNumber: "Ugyldig organisasjonsnummer",
		customerInfoUpdated: "Kundeinformasjon oppdatert",
		invitedSuccessful: "Kunde invitert",
		customerCategoryCreated: "Kundekategori opprettet",
		invalidFormat: "Ugyldig filformat",
		maximumFour:
			"Maksimalt 4. Legg til flere bilder ved å slette eksisterende bilder",
		fourOnly: "Last kun opp 4 bilder",
		imageRequired: "Bildefelt påkrevd",
		uploadSuccessful: "Artikkelbilder lastet opp",
		setUpAccount: "Sett opp konto",
		invitedTo: "Du er blitt invitert til å bli med",
		settingUp: "Setter opp",
		cartEmpty: "Din handlekurv er tom",
		orderedSuccessfully: "Bestillingen var vellykket",
		pieces: "stk",
		errorPlacingOrder: "Oops, det oppstod en feil under bestillingen",
		costPrice: "Kostpris",
		salesPrice: "Salgspris",
		vatRate: "MVA",
		margin: "Margin",
		checkEmail: "Vennligst sjekk din e-post for instruksjoner",
		invalidUser: "Ugyldig bruker",
		resetSuccessful:
			"Nullstilling av passord vellykket. Vennligst logg inn",
		selectFile: "Velg fil",
		optional: "Valgfritt",
		selectSubscription: "Velg ditt abonnement",
		subscriptionSuccess: "Ditt abonnement er blitt valgt",
		next: "Neste",
		currentSubscription: "Nåværende abonnement",
		monthlyCost: "Månedlig kostnad",
		notAvailable: "Ikke tilgjengelig",
		massAssign: "Massehåndtering",
		bulkUpdate: "Bulkoppdatering",
		fieldValue: "Feltverdi kan ikke være lengre enn 50 tegn",
		download: "Last ned",
		invalidCellValue: "Ugyldig verdi",
		invitedUserSuccess: "Ansatt invitert",
		successCompanyupdated: "Selskapsinformasjon ble oppdatert",
		notify: "Varsel",
		noNotify: "Ingen aktuelle varsler",
		notFoundText: "Beklager, siden ble ikke funnet",
		invalidAccount: "Ugyldig konto",
		backHome: "Tilbake til hjemmeside",
		back: "Tilbake",
		RequestBecomeBuyer: "Søk om å bli kunde",
		filter: "Filtrer etter",
		markRead: "Marker som lest",
		more: "mer",
		less: "mindre",
		save: "Lagre",
		connecting: "Kobler til",
		addWebshop: "Legg til webshop",
		addProductCategory: "Legg til produktkategori",
		itemOutOfStock: "Varan är ej tillänglig för beställning",
		webshop: "Webshop",
		updatedWebshop: "Webshop ble oppdatert",
		shopName: "Shop navn",
		note: "Merk",
		approachingLowStockQty: "Snart lite varer igjen",
		criticalLowStockQty: "Kritisk lite varelager",
		syncStockFromSupplier:
			"Synkroniser lagerbeholdning fra leverandør til webshop",
		placeAutomaticOrders:
			"Plasser automatisk bestillinger hos leverandør ved lav varebeholdning",
		addWebshopFor: "Legg til webshop for",
		wellStocked: "Stort varelager",
		sufficientQty: "Tilstrekkelig varelager",
		webshopProduct: "Webshop produkt",
		productCategoryName: "Produktkategorinavn",
		productCategorySuccess: "Produktkategori lagt til",
		productCategory: "Produktkategori",
		allProductCategory: "Hent alle produkter",
		assignSelectedProducts: "Kategoriser valgte produkt(er)",
		assignSuccess: "Kategorisering fullført",
		assignProduct: "Kategoriser produkt(er)",
		assignProgress: "Du holder på å kategorisere 1 produkt til",
		thresholdQty: "Grense varelager",
		relevantCustomer: "Relevant kunde",
		metricsOverview: "Metrisk oversikt",
		productManager: "Product Manager",
		view: "Vis",
		supplier: "Leverandør",
		isEqualTo: "er lik",
		isGreaterThan: "er større enn",
		isBetween: "er mellom",
		isLessThan: "er mindre enn",
		clear: "Nullstill",
		done: "Ferdig",
		supplierMatch: "LeverandørMatch",
		supplierQuantity: "Leverandørkvantitet",
		unavailable: "Utilgjengelig",
		name: "Navn",
		totalMonth: "Totalt antall bestillinger denne måned",
		averageMargin: "Gjennomsnittlig margin",
		averagePerOrder: "Gjennomsnittlig profit per bestilling",
		selectParentCategory: "Velg hovedkategori",
		updateCategory: "Oppdater kategori",
		addSubCategory: "Legg til underkategori",
		subCategoryName: "Underkategorinavn",
		subCategorySuccess: "Underkategori lagt til",
		addCategory: "Legg til kategori",
		source: "Kilde",
		productWithImage: "Produkt med bilde",
		bankIdAuth: "BankID autentisering",
		bankIDStatement: "Logg inn med BankID for å godkjenne kjøpsbeløp",
		validBankIDFormat: "Gyldig formateksempel",
		connectShopify: "Koble til Shopify",
		settings: "Innstillinger",
		billing: "Fakturering",
		myAccount: "Kontoinformasjon",
		amountDue: "Beløp som forfaller",
		dueDate: "Forfallsdato",
		file: "Fil",
		paid: "Betalt",
		unPaid: "Ikke betalt",
		requireBankIDforAllOrders: "Krev BankID for alle bestillinger",
		requireBankIDThresholdAmount:
			"Krev BankID kun på bestillinger over et spesifikt beløp",
		applyCustomerSpecificBasedMaxCredit:
			"Angi spesifisert makskreditt for kunde",
		applyGeneralCreditforAllCustomers: "Angi generell makskreditt",
		applyStockThreshold: "Angi antallsbegrensning",
		orderAmount: "Bestillingsbeløp",
		verifyBankId: "Verifiserer BankID",
		connectedSupplier: "Tilkoblet leverandør",
		connectIntegration: "Tilkoblet webshop",
		selectCategoryBelow: "Velg kategori under",
		nonConnectedSupplier: "Ikke-tilkoblet leverandør",
		maxCreditLimit: "Maks kredittgrense",
		priceManagement: "Prisadministrasjon",
		categoryManagement: "Kategoriadministrasjon",
		parentCategory: "Hovedkategori",
		childCategory: "Underkategori",
		selectCategoryParent: "Vennligst velg hovedkategori",
		removeParentCategory: "Fjern hovedkategori",
		removeChildCategory: "Fjern underkategori",
		totalOrderAmount: "Totalt bestillingsbeløp",
		customerManagement: "Kundeadministrasjon",
		totalConnectedSuppliers: "Totalt tilkoblede leverandører",
		totalOrderAmountMonth: "Totalt bestillingsbeløp/måned",
		// Total orders amount/month’: ‘Antal ordrar/mån’
		totalProducts: "Totalt produkter",
		customerCategory: "Kundekategori",
		customerCategoryManagement: "Administrasjon av kundekategori",
		customerCategoryList: "Kundekategori liste",
		maxLimitErrorMessage:
			"Ditt kjøpsbeløp er over maksimal kreditt din leverandør har tillatt, og derfor kan ikke bestillingen fullføres. Vennligst kontakt leverandøren",
		selectCompany: "Vennligst velg selskap",
		shopNameRequire: "Vennligst skriv inn butikknavn",
		invalidUpdateResponse: "Ugyldig responsoppdatering",
		clickToEdit: "Klikk for å endre",
		appLogs: "Applikasjonslogger",
		logInitiator: "Initiativtaker",
		logStatus: "Loggstatus",
		logs: "Logger",
		priceNotAvailable: "Pris ikke tilgjengelig",
		productVideo: "Produktvideo",
		relevantArticle: "Relevant",
		selectPricelistIndicator: "Velg prisliste med indikator",
		updatePriceList: "Vennligst sett en prisliste",
		relevantArticleCustomers: "Synlig kun for kunder",
		searchCustomers: "Kundesøk",
		select: "Velg",
		setRelevantArticles: "Sett artikler relevant for engros",
		setVisible: "Synlig",
		setHidden: "Skjult",
		selectCustomers: "Velg kunder",
		userCustomization: "Tilpassing",
		inviteEmailSubject: "Emne for e-post invitasjon",
		menuColor: "Menyfarge",
		InviteEmailHeader: "Overskrift for e-post invitasjon",
		buttonColor: "Knappfarge",
		inviteEmailContent: "Innhold for e-post invitasjon",
		linkColor: "Linkfarge",
		pricelistUpdated: "Prisliste oppdatert",
		customization: "Tilpasning",
		invited: "Invitert",
		connected: "Tilkoblet",
		companyLogo: "Firmalogo",
		storeName: "Butikknavn",
		requestAccess: "Be om tilgang",
		viewDetails: "Vis detaljer",
		logType: "Loggtype",
		startDate: "Startdato",
		endDate: "Sluttdato",
		logError: "Feil",
		logInfo: "Info",
		noDescription: "Ingen beskrivelse",
		CustomerSpecific: "",
		deleteBlogSuccess: "Blogginnlegget er fjernet",
		noBlogPost: "Du har enda ikke laget et blogginnlegg",
		noWebshop: "",
		unAuthorizedShopify: "",
		blogPreviewTitle: "Forhåndsvisning",
		faqz: "FAQ",
		inviteEmailSubjectHelpText: "Emnefelt for e-postinvitasjon",
		inviteEmailHeaderHelpText:
			"Overskrift for e-postinvitasjon (F.eks. Velkommen som kunde)",
		inviteEmailContentHelpText: "Teksten for e-postinvitasjon",
		menuColorHelpText: "Farge for sidemeny",
		linkColorHelpText: "Farge for link",
		buttonColorHelpText: "Knappfarge",
		brandNameHelpText: "Varemerkets navn",
		storeDescriptionHelpText: "Butikkbeskrivelse synlig for kunde",
		postInvoiceToFortnoxHelpText:
			"Dersom du merker av skapes fakturaer i økonomiprogrammet. Om ikke avmerket skapes bestillinger i økonomiprogrammet",
		applyStockThresholdHelpText:
			"Viser produkter som ikke er tilgjengelig i butikken for kunden dersom antall i ditt økonomiprogram er lavere enn det spesifiserte antall",
		applyCustomerPricelistHelpText:
			"Bruk kundeprisliste i webshop basert på prislisten linket til kunden i økonomiprogrammet",
		require_bank_id_for_for_orders_above_certain_amountHelpText:
			"Krev BankID signering for alle kjøp over et spesifikt beløp",
		applyCustomerSpecificBasedMaxCreditHelpText:
			"Bruk kredittgrense for kunder. Kredittgrense kan stilles inn under kundeinnstillinger og gjør at kunden ikke kan legge inn bestillinger over et bestemt beløp per måned med faktura",
		applyGeneralCreditforAllCustomersHelpText:
			"Bruk generell kredittgrense med samme beløp for alle kunder",
		requireBankIDforAllOrdersHelpText:
			"Krev BankID signering for alle bestillinger uavhengig av bestillingsbeløp",
		chooseCompanyLogoHelpText: "Last opp firmalogo",
		CompanyNameHelpText: "Last opp firmalogo",
		cityHelpText: "By",
		phoneNumberHelpText: "Telefon",
		companyNumberHelpText: "Organisasjonsnummer",
		emailHelpText: "E-post til kontaktperson",
		invoiceEmailHelpText: "Faktura e-post",
		bankGiroHelpText: "Ditt bankkontonummer som kommer på faktura",
		IBANHelpText: "Ditt IBAN nummer som kommer på faktura",
		plusgiroHelpText: "Ditt plusgiro nummer som kommer på faktura",
		shopNameHelpText: "Butikknavn",
		woo_consumer_keyHelpText:
			"API-nøkkel som du laster ned i Woocommerce, under avanserte innstillinger",
		woo_consumer_secretHelpText:
			"Husk til å spesifisere både lese/skrive rettigheter",
		approaching_low_stock_quantityHelpText:
			"Antall for lav lagerbeholdning. Nummeret du skriver inn her vil følge linken for å varsle deg når din lagerbeholdning når et lavt nivå",
		critical_low_stock_quantityHelpText:
			"Antall for kritisk lav lagerbeholdning. Nummeret du skriver inn her vil følge linken for å varsle deg når din lagerbeholdning når et kritisk lavt nivå",
		sufficient_stock_quantityHelpText:
			"Antall for normal varebeholdning. Nummeret du skriver inn her vil følge linken for å varsle deg når din varebeholdning er på et akseptabelt nivå",
		syncStockFromSupplierHelpText:
			"Synkroniser din lagerbeholdning med leverandørens lagerbeholdning",
		placeAutomaticOrdersHelpText:
			"Gjør automatiske bestillinger når varebeholdning når et lavt nivå",
		billingAddressHelpText: "Fakturaadresse",
		title: "Tittel",
		// emailSettings: 'E-postinställningar',
		// streetAddress: 'Gatuadress',
		// renderOnlyCustomerSpecificArticle: 'Återge endast kundspecifik artikel',
		// renderOnlyCustomerSpecificCategories: 'Återge endast kundspecifika kategorier',
		applyTo: "Ansöka till",
		applyToCustomerText: "Ansök till specifik kund",
		invalidCredential:
			"systemet kunde inte validera dina autentiseringsuppgifter",
		// title: 'Titel',
		send_invoices_to_nox_finance: "Skicka fakturor via Nox Finans",
		send_to_noxHelpText:
			"Vid ja så kommer fakturor skapas upp i Fortnox och direkt därefter skickas via Nox Finans. Leveranssättet baseras på det som är förinställd på kundkortet i Fortnox",
		UpdateArticleCategory: "Uppdatera artiklar kategori",
		finallyDelivered: "SLUTLEVERERADE",
		finallyPaid: "SLUTBETALDA",
		NotDelivered: "ICKE LEVERERADE",
		storeDescription: "Butiksbeskrivning",
		removeBlogCategory: "“Är du säker på att du vill radera kategori",
		blogAddedSuccess: "Blogginlägget har lagts till",
		blogUpdatedSuccess: "Uppdaterat blogginlägget",
		priceExcludeTax: "*Priser anges ex moms",
		termsAndCondition: "Jag har läst och godkänner",
		privacyLink: "köpvillkoren",
		unSelectedTermsCondition: "Vänligen ange att du godkänner våra villkor",
		privacyPolicy: "Sekretess och juridik",
		general: "ALLMÄNT",
		generalContent:
			"Det här avtalet gäller när vi på Connectivo AB, org. nr. 556932-7777, tillhandahåller tjänster till er.\n" +
			'I detta Avtal betyder ”vi”, ”oss” , "leverantören" och ”B2B Portal" Connectivo AB och ”ni”,\n' +
			"”er” eller ”kunden” ni som kund.",
		trademarks: "Varumärken",
		certainDisclaimer: "Vissa ansvarsfriskrivningar",
		confidentialInformation: "Konfidentiell information",
		globalAvailability: "Global tillgänglighet",
		linkTosite: "Länka till denna webbplats",
		thirdPartyWebsite: "Tredjepartswebbplatser",
		thirdPartyProductServices: "Produkter och tjänster från tredje part",
		collectionTechnicalInformation:
			"Insamling och användning av teknisk information",
		useOfCookie: "Användning av kakor",
		jurisdiction: "Jurisdiktion",
		disclaimerOfGuarranty: "FRISKRIVNING AV GARANTI",
		limitationLiability: "ANSVARSBEGRÄNSNING",
		loginRequest: "Sessionen har löpt ut, logga in igen",
		definitions: "DEFINITIONER",
		definitionsContent:
			"Nedan listas en del ord som används genomgående i Avtalet. Orden ska ha den betydelse som anges\n" +
			"nedan.\n",
		connectionPoint: "Anslutningspunkten",
		connectionPointContent:
			"Den punkt där våra Tjänster görs tillgängliga för er.",
		userHeader: "Användare",
		userHeaderContent:
			"Den person som ni har givit tillgång till Tjänsterna. Användare ska vara anställd hos er, eller annars\n" +
			"jobba för er räkning, till exempel en konsult eller redovisningsekonom",
		agreement: "Avtal",
		agreementContent:
			"Det här avtalet inklusive bilagor och underbilagor.\n",
		customerData: "Kunddata",
		customerDataContent:
			"Data som ni på något sätt, själv eller genom Användare, överlämnar till oss eller som vi annars får\n" +
			"del av genom er användning av Tjänsterna.\n",
		customerAgreement: "Kund med Byråavtal",
		customerAgreementContent:
			"Customer who has entered into an agreement with an accounting firm or similar company for financial services\n" +
			"based on the Services.",
		systemAdministrator: "Systemadministratör",
		systemAdministratorContent:
			"Den person som tecknat Tjänsterna för er räkning, eller som annars har utsetts av er till\n" +
			"Systemadministratör. Kund med Byråavtal kan ha en egen systemadministratör.\n",
		serviceOrServices: "Tjänsten eller Tjänsterna",
		serviceOrServicesContent:
			"Molnbaserade prenumerationstjänster avseende integration av webbapplikationer och\n" +
			"automatisering av manuella processer inom löpande bokföring. Tjänsten innefattar leverans av\n" +
			"standardiserade integrationer. Skräddarsydda och kundspecifika integrationer innefattas ej av denna\n" +
			"tjänst, utan kan beställas som en separat tjänst.",
		conclusionOrder: "AVTALETS INGÅENDE OCH BESTÄLLNING",
		conclusionOrderContent:
			"Avtal avseende Tjänsten ska ingås skriftligen antingen via e-post, via beställning på hemsidan, ww.bridgero.se, eller via App Marknadsplatser.\n" +
			"Den dag Kunden fullföljt beställningen utgör ”Avtalsdagen”.\n" +
			"Kundens minimiåtagande per månad enligt Avtalet ska beräknas som summan av de månadsavgifter\n" +
			"som Kunden ska betala för det totala antal licenser som Kunden beställt vid Avtalets ingående.\n" +
			"Kundens minimiåtagande per månad framkommer i prislistan som är publicerad på hemsidan och\n" +
			"kan ej ändras under Avtalets bindningstid.\n" +
			"Beställning av ytterligare/nya tjänster (inklusive licenser för användare och abonnemang utöver\n" +
			"Kundens minimiåtagande) eller Produkter efter det att Avtalet ingåtts ska göras på det sätt\n" +
			"Leverantören anvisar.",
		rightOfwithdrawal: "ÅNGERRÄTT",
		rightOfwithdrawalContent:
			"Nedladdning och nyttjande av digitala produkter klassas som en tjänst som inte kan återlämnas då \n" +
			"det är en så kallad direktkonsumtion. Den digitala verifikationsprocessen motsvarar en plombering" +
			"av en fysisk vara.",
		delivery: "LEVERANS",
		deliveryContent:
			"Tjänstens konfiguration och aktivering levereras i form av en kundportal där kund ansvarar för att\n" +
			"följa anvisningar och konfigurera sina inställningar.\n" +
			"Om Kunden anser att det finns fel eller brister i leveransen av Tjänsten, ska Kunden skriftligen\n" +
			"informera Leverantören därom och tillse att relevant skriftlig dokumentation och felbeskrivning är\n" +
			"Leverantören tillhanda senast tio (10) dagar räknat från Leveransdatum. Leverantören ska därefter\n" +
			"och inom skälig tid vidta nödvändiga korrigerande åtgärder. Om Kunden inte anmält fel eller brist i\n" +
			"enlighet med ovan anses Tjänsten felfritt levererad.\n" +
			"Fel eller ändringar efter nyssnämnda tid hanteras enligt vad som anges under punkt 16\n" +
			"(Felavhjälpning) nedan. Om leverans inte kan ske och detta beror på att Kunden inte fullgjort sina\n" +
			"åtaganden enligt Avtalet äger Leverantören rätt att fakturera samtliga avgifter från Avtalsdagen.",
		deliveryGuarranty: "LEVERANSGARANTI",
		deliveryGuarrantyContent1:
			"• Om Leveransdatum inte ägt rum senast nittio (90) dagar från Avtalsdagen, har Kunden rätt att kräva\n" +
			"avdrag med belopp motsvarande femtio (50) procent av den obrukbara Tjänstens erlagda fasta avgift",
		deliveryGuarrantyContent2:
			"Om Leveransdatum inte ägt rum senast etthundratjugo (120) dagar från Avtalsdagen, har Kunden\n" +
			"rätt att häva Avtalet. Om Avtalet hävs ska samtliga prestationer återgå och samtliga förhållanden\n" +
			"anses reglerade, bl.a. med innebörd att ingen ytterligare ersättning utöver att av Kunden eventuellt\n" +
			"erlagda avgifter ska återbetalas till Kunden.",
		deliveryGuarrantyContent3:
			"• Leveransgarantin i denna punkt gäller endast om förseningen enligt ovan uteslutande beror på\n" +
			"förhållanden hänförliga till Leverantören. Så anses inte vara fallet exempelvis om",
		deliveryGuarrantyContent4:
			"- Förseningen helt eller delvis är hänförlig till annan tredjepart än Leverantören eller om förseningen" +
			"helt eller delvis är hänförlig till Kunden, vilket kan vara fallet om Kunden påverkar eller ändrar" +
			"datum för Leveransdatum eller Kunden inte uppfyllt sina åtaganden enligt Avtalet, såsom" +
			"installation och aktivering av tjänsten.",
		deliveryGuarrantyContent5:
			"- Uppgift i Avtalet eller i Kunddatainformation är felaktig och Leverantören inte kan få korrekt\n" +
			"uppgift (bekräftad) av Kunden före planerad installation.\n" +
			"-Force Majeure föreligger",
		customerObligation: "KUNDENS ÅLIGGANDEN",
		customerObligationContent1:
			" Kunden ska se till att alla uppgifter som anges angående kontaktuppgifter, faktureringsinformation" +
			"eller kreditkortsinformation är korrekta och förbinder sig att uppdatera dessa uppgifter regelbundet ",
		customerObligationContent2:
			"• Kunden skall ansvara för den verksamhet som bedrivs av Kunden och dess medlemmar och ska" +
			"säkerställa att den nationella lagstiftningen följs. ",
		customerObligationContent3:
			"• Kunden ansvarar för att kontinuerligt kontrollera importerad data till ekonomiprogrammet och" +
			"hålla uppdaterad dess programinställningar för brukande av programvara. ",
		customerObligationContent4:
			"• Kunden ansvarar för att inställningar i ekonomiprogrammet ställs in i enlighet med instruktioner" +
			"som tillhandahålls av B2B Portal och aktivering av levererad tjänst. ",
		customerObligationContent5:
			"Kunden samtycker till att inte reproducera, duplicera, kopiera, sälja, återförsälja eller exploatera\n" +
			"någon del av bridgero.se, användning av bridgero.se, eller tillgång till bridgero.se. Återförsäljning av bridgero.se är endast tillåtet med skriftligt samtycke från\n" +
			"Connectivo AB och ett återförsäljaravtal från Connectivo AB (som Connectivo AB förbehåller sig\n" +
			"rätten att när som helst avsluta och som kan omfatta vissa villkor).",
		customerObligationContent6:
			"Den som undertecknar detta avtal genom köp av tjänst garanterar att han/hon är ansvarig för att se\n" +
			"till att tillstånd finns för att binda Kunden genom ett sådant utförande",
		customerObligationContent7:
			"Kunden ansvarar för att hålla användarinformation, lösenord och SIM-kort med tillhörande koder\n" +
			"väl skyddade så användande av obehörig förhindras.",
		customerObligationContent8:
			"Kunden ansvarar för att informera berörda användare hos Kunden om att Leverantören kan komma\n" +
			"att skicka information om Leverantörens produkter och tjänster.",
		responsibiltyClaims: "Ansvar för eventuella krav som riktas mot oss",
		responsibiltyClaimsContent:
			"Om någon annan riktar krav mot oss på grund av er eller era Användares användning av Tjänsterna\n" +
			"eller tredje parts tjänster ska ni hålla oss skadelösa genom att ersätta oss för den skada eller förlust vi\n" +
			"lidit på grund av kravet. Exempel på sådana krav är begäran om ersättning för intrång i tredje parts\n" +
			"immateriella rättigheter så som upphovsrätt eller varumärke. Vi ska även ha rätt att ge vidare rätten\n" +
			"att framställa sådan begäran och därmed låta någon annan framställa begäran.",
		customerDataInformation: "KUNDDATAINFORMATION",
		customerDataInformationContent:
			"Leverantören behandlar Kunddatainformation för att tillhandahålla och säkerställa driften av\n" +
			"Tjänsten enligt Avtalet, för att fullgöra skyldighet enligt lag eller annan författning samt för\n" +
			"fakturering. Leverantören får använda Kunddata för att fullgöra uppdraget, för statistiska ändamål,\n" +
			"för att förbättra och utveckla våra Tjänster samt för marknadsföring.\n" +
			"Kunden ska på Leverantörens begäran lämna den kunddatainformation som Leverantören behöver\n" +
			"för att tillhandahålla Tjänsten (”Kunddatainformation”).\n" +
			"Kunden svarar för att samtlig Kunddatainformation är korrekt samt för att att Kunddata är fri från\n" +
			"virus, trojaner, maskar eller annan programvara eller kod som kan skada Tjänsterna.\n" +
			"Kund ska även ersätta Leverantör för all skada som åsamkas direkt eller indirekt på grund av att\n" +
			"innehållet i Kunddata eller användningen av Kunddata i Tjänsterna strider mot tillämplig\n" +
			"lagstiftning.\n" +
			"Som kund kan ni alltid ta ut Kunddata från Tjänsterna. I samband med att ni säger upp Avtalet är det\n" +
			"upp till er att ta ut all Kunddata från Tjänsterna som ni kan komma att behöva för framtida bruk.",
		priceAndPayment: "PRISER OCH BETALNING",
		priceAndPaymentContent:
			"Såvida inte parterna kommit överens om annat gäller de priser för Tjänsterna som vid tidpunkten\n" +
			"som parterna ingåt Avralet publicerats på B2B Portal hemsida. Detsamma gäller vid\n" +
			"förlängning av Avtalet.\n" +
			"Tjänstens fasta avgifter debiteras månadsvis eller årsvis, antingen via kortbetalning eller faktura.\n" +
			"Betalningsvillkor för faktura är 15 dagar, såvida inte Parterna kommit överens om något annat.\n" +
			"Vid betalning efter förfallodagen debiteras dröjsmålsränta enligt lag. Leverantören äger dessutom\n" +
			"rätt till ersättning för påminnelseavgift och inkassokostnader. Invändning mot faktura ska vara\n" +
			"Leverantören tillhanda senast tio (10) dagar efter fakturadatum. Invändning ska göras skriftligen\n" +
			"med angivande av skäl till varför Kunden invänder mot fakturan. Leverantören har rätt att med\n" +
			"omedelbar verkan säga upp Avtalet om betalning av faktura inte har skett trettio (30) dagar efter\n" +
			"förfallodatum.\n",
		priceAndPaymentContent2:
			"Varje integration innefattas av två (2) typer av kostnader - en licensavgift samt en återkommande\n" +
			"månadsavgift för molntjänsten.\n" +
			"I samband med köp av integration är det kundens ansvar att säkerställa att korrekt nivåpaket väljs så\n" +
			"att tjänsten täcker kundens månatliga ordervolym. Connectivo AB förbehåller sig rätten att i\n" +
			"efterhand fakturera kunden i de fall kunden överstiger den ordervolym som valt nivåpaket täcker.",
		priceAndPaymentContent3:
			"Vi har rätt att höja priset, med direkt verkan, om höjningen beror på omständigheter vi inte kan\n" +
			"kontrollera, till exempel förändring av valutakurs, skatt eller annan omständighet av ekonomisk\n" +
			"betydelse som påverkar kostnaden för att tillhandahålla Tjänsterna.\n" +
			"Vi har också rätt att höja priset i andra fall men då måste vi kontakta er senast trettio (30) dagar\n" +
			"innan vi höjer priset för att informera er om förändringen. Det nya priset börjar gälla först när nästa\n" +
			"betalningsperiod börjar. Ni har möjlighet att till dagen innan prishöjningen träder ikraft säga upp\n" +
			"Avtalet med oss. Om ingen uppsägning görs av er, anses ni ha godkänt den nya prissättningen.\n",
		agreementTimeAndTermination: "AVTALSTID OCH UPPSÄGNING AV AVTALET",
		agreementTimeAndTerminationContent:
			"Kunden väljer själv vid köptillfälle om denne önskar med bindningstid eller utan bindningstid på\n" +
			"avtalet. Om inte annat avtalats, ä bindningstiden 12 månader och förlängs automatiskt med ett år i\n" +
			"taget, om avtalet inte sägs upp av endera parten senast 30 dagar före avtalstidens utgång.\n" +
			"Avtalet ska sägas upp skriftligen en (1) månad innan avtalstiden löper ut. Om Avtalet inte sagts upp\n" +
			"förlängs Avtalet med tolv (12) månader åt gången, med en uppsägningstid om en (1) månad innan\n" +
			"varje ny tolvmånadsperiod.\n" +
			"Utöver vad som annars anges i detta Avtal har vi båda rätt att med omedelbar verkan säga upp\n" +
			"Avtalet om den andra av oss har:",
		agreementTimeAndTerminationContent1:
			"brutit mot Avtalet och inte rättar till det inom trettio (30) dagar efter att ha mottagit den andra\n" +
			"partens skriftliga meddelande om avtalsbrott, eller",
		agreementTimeAndTerminationContent2:
			"inleder likvidationsförfarande, ansöker om eller försätts i konkurs, ställer in betalningarna eller\n" +
			"på annat sätt kan befaras vara på väg mot obestånd.",
		supportTroubleShooting: "SUPPORT/FELAVHJÄLPNING",
		supportTroubleShootingContent:
			"I Tjänsten ingår, utöver tillgång till serviceportalen som nås via www.bridgero.se, teknisk\n" +
			"och administrativ support via mail och telefon som avser att hjälpa Kunden med frågor avseende\n" +
			"Tjänsten, bistå med nödvändig support från Leverantörens leverantörer, enklare\n" +
			"ändringar/konfigurationer och tillägg/borttag i Tjänsten samt felsökning i Tjänsten om Fel\n" +
			"konstateras i samband med felsökningen.\n" +
			"Ni kan vända er till oss med frågor relaterade till hur våra Tjänster fungerar och ska användas. Vi\n" +
			"kan däremot inte erbjuda support i frågor om till exempel bokföring eller frågor av teknisk karaktär\n" +
			"som inte handlar om Tjänsten.\n" +
			"Ni kan kontakta oss genom de kontaktuppgifter som finns på vår hemsida. Aktuella öppettider för\n" +
			"supporten hittar du på vår hemsida.\n" +
			"Tjänsten omfattar inte support som bland annat men ej uteslutande innebär: felsökning i Kundens,\n" +
			"tredje mans eller annan leverantörs program, support avseende Kundens utrustning, konfiguration\n" +
			"som inte tillhandahållits av Leverantören, support som inte avser Tjänsten, utbildning, längre\n" +
			"telefonmöten, rådgivning eller konsultation. Sådan support debiteras i enlighet med Leverantörens\n" +
			"vid var tid gällande standardprislista",
		supportTroubleShootingContent2:
			"Med ”Fel” avses att Kunden inte kan använda Tjänsten i enlighet med Avtalet och att felet beror på\n" +
			"Leverantören.\n" +
			"Leverantören ska avhjälpa Fel kostnadsfritt inom skälig tid. Med Fel avses inte, exempelvis men ej\n" +
			"uteslutande, brister som:",
		supportTroubleShootingContent3:
			"är av mindre betydelse för Kunden eller som inte hindrar Kunden från att använda Tjänsten,\n" +
			"orsakats av tredje man eller genom omständigheter utanför Leverantörens kontroll.",
		supportTroubleShootingContent4:
			"avser annat än Tjänsten, exempelvis avseende tjänster, produkter och leveranser som sker genom\n" +
			"annan leverantör.\n",
		supportTroubleShootinfContent5:
			"Felanmälningar hanteras av Leverantörens support. Felanmälan sker genom e-post eller\n" +
			"telefonsamtal. Det åligger Kunden att vara supporten behjälplig i felsökning och återkoppla inom 48\n" +
			"timmar med nödvändig information för fortlöpande sökning. Om återkoppling inte sker stängs\n" +
			"ärendet och betraktas som avslutat utan ansvar för Leverantören.\n" +
			"16.8. Felanmälan kan göras dygnet runt, alla årets dagar på info@bridgero.se.\n" +
			"Felanmälan som inkommer på annan tid än på helgfri vardag mellan 08.00-17.00 anses inkommen\n" +
			"nästkommande helgfri vardag kl. 08.00.",
		responsibilityAccountingData: "ANSVAR FÖR BOKFÖRINGSDATA",
		responsibilityAccountingDataContent:
			"Leverantören ansvarar för vidareutveckling av Tjänsten och bestämmer ensam vilka förbättringar,\n" +
			"förändringar och tekniska anpassningar som ska göras i Tjänsten. B2B Portal integrationer\n" +
			"och dess funktionalitet grundas i kontinuerlig efterforskning, maskininlärning samt replikering av\n" +
			"processer såsom löpande bokföring utförd av redovisningskonsulter.\n" +
			"Tjänsten är ett mjukvaruprogram utvecklad i syfte att imitera och till stor grad b2b portal arbetet\n" +
			"redovisningskonsulter utför. Tjänsten ersätter inte helt en redovisningskonsult utan är enbart\n" +
			"kompletterande.\n" +
			"Leverantören garanterar ej att tolkning av transaktionsdata alltid blir fullständigt korrekt, utan\n" +
			"Kunden ansvarar själv för slutlig kontroll av bokföringens korrekthet.\n" +
			"Med förbehåll för de begränsningar som anges i detta avtal, skall Leverantören vara ansvarig för\n" +
			"korrigering av felbokningar till följd av dess försumlighet. I händelse av fel eller brister som kan\n" +
			"tillskrivas Leverantören åtar denne sig att agera för att korrigera sådana fel utan oskäligt dröjsmål.\n" +
			"I det fall det är fråga om ett fel i Tjänsterna som gör att bearbetningen av Kunddata leder till ett\n" +
			"felaktigt resultat, så åtar vi oss att, så snart som omständigheterna kräver med hänsyn till typen av\n" +
			"fel och omständigheterna i övrigt, utan extra kostnad för er, göra en ny bearbetning av Kunddata.\n" +
			"Kunden har inte rätt till en minskning av betalning, debitering eller andra påföljder i händelse av\n" +
			"driftstörningar eller felrapportering som kan uppstå.\n" +
			"Felanmälan måste ges av kunden i enlighet med de instruktioner som aviserats av Leverantören från\n" +
			"tid till tid och inom en rimlig tid för upptäckten av felet. I avsaknad av uppsåt eller grov oaktsamhet\n" +
			"av Leverantören, antar denne annars inget ansvar för fel eller brister i tjänster som ingår däri.\n",
		modificationTermsAndCondition: "ÄNDRING AV AVTALSVILLKOR",
		modificationTermsAndConditionContent:
			"Leverantören förbehåller sig vidare rätten att ändra villkoren i detta Tjänsteavtal, inklusive, men\n" +
			"inte begränsat till, de priser som Leverantören har från en tid till en annan. Kunden skall informeras\n" +
			"om sådana ändringar via e-post eller genom den information som görs tillgänglig på B2B Portal\n" +
			"hemsida en (1) månad före ikraftträdandet. Om kunden inte godkänner ändringen, har\n" +
			"Kunden inom trettio (30) kalenderdagar från avsändandet av e-post eller, i förekommande fall,\n" +
			"trettio (30) kalenderdagar från ändringen publiceras på B2B Portal webbplats, rätt att säga\n" +
			"upp avtalet med omedelbar verkan. Om avtalet inte sägs upp av Kunden inom nämnda tid, ska\n" +
			"Kunden anses ha godkänt de nya villkoren.\n",
		transferOfTerms: "ÖVERLÅTELSE AV AVTAL",
		transferOfTermsContent:
			"Kunden har inte rätt att utan Leverantörens föregående skriftliga medgivande överlåta, pantsätta\n" +
			"eller på annat sätt disponera över eller upplåta säkerhetsrätt avseende sina rättigheter och/eller\n" +
			"skyldigheter enligt Avtalet. Leverantören har rätt att överlåta och/eller på annat sätt disponera över\n" +
			"sina rättigheter och/eller skyldigheter enligt Avtalet.\n",
		marketing: "MARKNADSFÖRING",
		marketingContent:
			"Vi får marknadsföra tjänster till er som vi eller någon samarbetspartner till oss tillhandahåller.\n" +
			"Marknadsföringen kan ske i Tjänsterna, genom e-post eller på annat sätt.",
		agentsAndSubContractors: "OMBUD OCH UNDERLEVERANTÖRER",
		agentsAndSubContractorContent:
			"Leverantören har rätt att anlita underleverantörer och samarbetspartners för att utveckla Tjänsterna\n" +
			"och fullgöra sina åtaganden enligt Avtalet",
		intellectualProperty: "IMATERIELLA RÄTTIGHETER",
		intellectualPropertyContent:
			"Samtliga immateriella rättigheter till Tjänsten samt ändringar och variationer därav, är och ska\n" +
			"fortsatt vara Leverantörens egendom. Ni får inte kopiera, ändra eller på annat sätt hantera\n" +
			"programvara eller annat material som hör till Tjänsterna.\n" +
			"Vid immaterialrättsintrång som beror på Kunden gäller följande:\n" +
			"Kund ska försvara Leverantör om krav riktas eller talan förs mot leverantör om intrång på grund av\n" +
			"kunden eller den som använder Tjänsterna i strid med detta Avtal. Detta sker då på kundens egna\n" +
			"bekostnad. Kund ska också ersätta Leverantör för alla de kostnader och skadestånd som Leverantör\n" +
			"genom förlikning eller dom kan bli skyldiga att betala.\n",
		messages: "MEDDELANDEN",
		messagesContent:
			"Meddelanden under Avtalet lämnas till av Leverantören respektive Kunden angiven adress i Avtalet,\n" +
			"såvida inte meddelanden med fördel lämnas på annat sätt, exempelvis via telefon eller e-mail.\n" +
			"Meddelanden ska anses ha kommit den mottagande Parten tillhanda fem (5) dagar efter avsändandet\n" +
			"med brev och tre (3) dagar efter avsändande med e-post och direkt via telefon. Parterna ska\n" +
			"skyndsamt informera varandra om adressändringar",
		forceMajure: "FORCE MAJEURE",
		forceMajureContent:
			"Leverantören ska inte vara ansvarig för underlåtenhet att fullgöra Avtalet om detta föranleds av en\n" +
			"omständighet som legat utanför Leverantörens kontroll och som Leverantören inte skäligen kunde\n" +
			"förväntas ha räknat med eller tagit i beaktande vid tiden för Avtalets ingående och vars följder\n" +
			"Leverantören inte heller skäligen kunde ha undvikit eller övervunnit, såsom krig, brand,\n" +
			"översvämning, åsknedslag, eldsvåda, terrordåd, hacker-attack, ockupation, lock-out,\n" +
			"importbegränsningar, sanktioner eller liknande, eller fel eller förseningar i leverans från\n" +
			"underleverantör på grund av omständigheter som denne ej kunnat råda över.",
		applicableLaw: "TILLÄMPLIG LAG OCH TVISTELÖSNING",
		applicableLawContent:
			"Parternas rättigheter och skyldigheter vid tolkning och tillämpning av Avtalet ska bestämmas i\n" +
			"enlighet med svensk lag. Tvister som uppstår i anledning av Avtalet ska slutligt avgöras genom\n" +
			"skiljedomsförfarande administrerat av Västsvenska Handelskammares Skiljedomsinstitut.\n" +
			"Talan avseende förfallna obetalda fordringar äger dock Part anhängiggöra inför allmän domstol.\n" +
			"Reglerna om förenklat skiljeförfarande ska tillämpas, om inte Västsvenska Handelskammarens\n" +
			"Skiljeråd med beaktande av tvistens svårighetsgrad, tvisteföremålets värde och övriga\n" +
			"omständigheter bestämmer att reglerna om ordinärt skiljeförfarande ska tillämpas. I sistnämnda fall\n" +
			"ska Skiljerådet också bestämma om skiljenämnden ska bestå av en eller tre skiljemän.\n",
		termsFooter:
			"Skiljeförfarandets säte ska vara Göteborg, Sverige. Språket för förfarandet ska vara svenska.",
		termsFooterContent: "Reviderad januari 2022.",
		authenticationInProcess: "Autentisering pågår",
		maxImageUploadMessage: "jpg/png-filer med en dimension på 5000x3000px",
		maxArticleImageUploadMessage:
			"jpg / png-filer med en maxdimension på 800x800px",
		maxImageUploadMessageError:
			"Bilduppladdningen måste vara av dimensionen 5000 x 3000 px",
		maxArticleImageUploadMessageError:
			"Bilduppladdningen måste vara av dimensionen 800 x 800 px",
		maxCreditLimitError: "max kredit kanske inte är null.",
		categoryRequired: "Kategori kanske inte är null.",
		subscriptionSuccessMessage: "Tack, välkommen att logga in ert konto nu",
		emailVerificationMessage: "Verifierar din e-post.....",
		emailError:
			"systemet kunde inte verifiera din e-post, se till att du klickar på rätt länk",
		errorTag: "Det gick inte att hämta bloggtaggen",
		proceed: "Fortsätt",
		pending: "EJ ANSLUTEN",
		completed: "ANSLUTEN",
		info: "INFO",
		error: "FEL",
		display_store_to_all_buyers:
			"Synliggör er butik för alla ansluta kunder i B2B portalens nätverk",
		apply_display_store_to_all_buyers:
			"Om du ej bockar för rutan är er butik enbart synlig för era inbjudna kunder",
		archive: "Arkiv",
		show_more: "visa mer",
		show_less: "visa mindre",
		error_processing_request: "Det gick inte att utföra uppgiften",
		max_order_limit_exceeded: "Du har nått din max kredit gräns",
		order_confirmation_text: "Orderbekräftelsetext",
		order_confirmation_text_help_text: "Orderbekräftelsetext",
		order_confirmation_text_en: "Orderbekräftelsetext Engelska",
		bulk_article_upload: "Massuppladdning av artiklar",
		excel_file_format_text: "Endast xlxs, xlx excel-format stöds",
		article_upload_in_progress: "Artikeluppladdning pågår",
		article_excel_document_required: "Uppladdning av Excel-dokument krävs",
		upload_bulk_article_excel: "Ladda upp massartikel (excel)",
		export_product_data: "Exportera artikel (excel)",
		download_excel_template: "Ladda ner artikelmall (excel)",
		articleLongDescription: "Lång produkt text",
		role: "Roll",
		event: "Händelse",
		fieldTitle: "Fälttitel",
		fieldMap: "Fältnamn i Ekonomiprogrammet",
		required: "Obligatorisk",
		fieldTitleHelpText:
			"Lägg till fält som kunden kan fylla i vid Orderläggning. Namnge fältet och välj sedan vilket referensfält i ekonomiprogrammet värdet ska skickas till",
		fieldMapHelpText:
			"Dessa fält är tillgängliga i Ekonomiprogrammet som referensfält",
		requiredHelpText:
			"Bocka för om ni vill att fältet ska vara obligatoriskt för kunden att fylla i vid beställning",
		companyConfigurationSuccess:
			"Konfigurationen för företagets kassa har sparats",
		companyEmailConfigurationSuccess:
			"Företagets e-postkonfigurationer har sparats",
		accountingFieldAlreadyExist: "Redovisningsfältet finns redan",
		selectAccountField: "Välj kontofält",
		emptyUploadedZip: "Uppladdad zip-fil är tom",
		invalidExcelFormat:
			"Ogiltigt excel-format, vänligen följ det excelmall som getts som exempel.",
		addedSuccess: "Posten har lagts till",
		updateArticleSpecification: "Uppdatera artikelspecifikationen",
		specificationTitle: "Specifikationens titel",
		specificationText: "Specifikationstext",
		actions: "Åtgärd",
		specification: "Produkt Specifikationer",
		text: "Text",
		discountedPrice: "Kampanjpris",
		purchase_price: "Inköpspris",
		deliveryTime: "Leveranstid",
		updateSuccess: "Uppgifterna har uppdaterats",
		allowCustomText: "Lägg till extra textfält för artikel vid beställning",
		articleCustomTextTitle: "Titel för extra textfältet",
		allowCustomTextHelpText:
			"Syftet med denna funktion är att ge köparen ett extra inmatningsfält när de lägger till produkten i varukorgen.",
		additionProductItem:
			"Skriv in informationen och avgränsa med kommatecken",
		itemUpdated: "Uppdaterat",
		campaignScheduleDate: "Kampanj period",
		uploadExcelFile: "Ladda upp excel-fil",
		add: "Lägg till ",
		customerDeliveryAddress: "Kundens leveransadress",
		addDeliveryAddress: "Lägg till leveransadress",
		use_accounting_customer_number_for_identifier:
			"Använd ekonomiprogrammets kundnummer som unik identifierare",
		use_accounting_customer_number_for_identifier_text:
			"Använd ekonomiprogrammets kundnummer som unik identifierare",
		emailSettings: "E-postinställningar",
		emailFrom: "Avsändar mejladress",
		emailSetting: "E-post inställning",
		halfLogo: "Företagslogotyp liten storlek",
		fullLogo: "Företagslogotyp stor storlek",
		estimatedDeliveryDay: "Ange beräknad leveranstid i dagar",
		articleDescriptionError: "Ange artikelbeskrivning",
		articleSalesPriceError: "Ange försäljningspris",
		zipTextMsg: "Filen måste vara i .zip-format",
		zipTextDimensionMsg: "Bilder får vara max 800 x 800 px",
		clickUploadZipText: "Här bifogar du .zip filen med produktbilder",
		ShopOwner: "Ägare",
		BuyerEmployee: "Anställd",
		EndUserCustomer: "Kund",
		ShopOwnerEmployee: "Anställd",
		excelReport: "EXCEL MASSHANTERING",
		locationName: "Platsnamn",
		streetAddress: "Gatuadress",
		processingOrder: "Bearbetar order",
		checkoutMessage: "Här kan du lämna en kommentar för ordern",
		Delivery: "Leverans",
		days: "dagar",
		estimatedDeliveryDate: "Beräknat leveransdatum",
		viewProductDetail: "Produktinformation",
		buyerNote: "Meddelande från kund",
		internalNote: "Intern anteckning",
		customerEmptyError: "Intern anteckning",
		deliveryAddressOptions: "Alternativ för leveransadress",
		country: "Land",
		enableMinimumQty: "Sätt minimum beställningskvantitet",
		minimumOrderQty: "Minimum antal",
		minimumOrderQtyError:
			"Minsta beställningskvantitet måste vara större än 1",
		customerMininmumOrderQtyError:
			"Minsta beställningskvantitet måste vara",
		minimumOrderAmount: "Minimum orderbelopp",
		customerMinimumOrderAmountError: "Minimum ordersumma måste vara ",
		tasksHandle: "Markera anteckning som hanterad",
		categoryTree: "Kategori träd",
		minimum_order_amount: "Minsta ordersumma",
		minimum_order_amount_text: "Minimum ordersumma",
		minimum_order_amount_error: "Ordern understiger minimumbeloppet",
		addFaq: "Lägg till FAQ",
		editFaq: "Uppdatera FAQ",
		faqSuccess: "FAQ har skapats",
		faqCategorySuccess: "FAQ kategori har skapats",
		question: "Fråga",
		answer: "Svar",
		removeFaq: "Radera FAQ",
		removeFaqCategory: "Radera Kategori",
		cancelled: "Makulerat",
		supplierInfoText:
			"Här hittar ni alla grossister som nu är anslutna till och även de grossister " +
			"som är verksamma i plattformen. Om ni önskar få bli återförsäljare " +
			"till en specifik grossist kan ni klicka in på denne och skicka in en ansökan. " +
			"För att logga in på en specifik grossist webbshop som ni är anslutna till," +
			" klicka på presentations boxen nedan",
		invalidExcelHeaderError:
			"Vänligen följ kolumn ordningen och titeln som är angivet i excelmallen",
		invalidFields: "ogiltiga fält",
		emptyEntry: "Lägg till en post",
		childCategorySuccess: "Underkategori har lagts till",
		emptyNodeSelected: "Tom underkategori har valts",
		subcategoryAssignedSuccess: "Underkategori är nu sparad",
		categoryTreeModification:
			"",
		selectChildCategory: "Välj Underkategori",
		categoryStructure: "Kategoristruktur",
		removeSubCategory: "Ta bort underkategori",
		assignArticleCategory: "Tilldela kategori till artikel",
		assign: "Tilldela",
		articleCategoryAssign: "Kategori har tilldelats",
		articleCategoryAssignError:
			"Fel vid tilldelning av kategori till artikel",
		additionalSubCategory: "En lager till av underkategori",
		tags: "Taggar",
		postStatus: "Status",
		sellerBackendUrl: "Backend Domän",
		sellerFrontendUrl: "Frontend Domän",
		emailHost: "E-postvärd",
		emailUsername: "Användarnamn",
		emailPassword: "Lösenord",
		emailPort: "Port",
		useSSL: "Använd SSL",
		useTLS: "Använd TLS",
		connectionType: "Kopplingstyp",
		connectionTypeHelpText:
			"Detta anger vilken smtp-anslutningstyp som ska användas för att skicka e-post",
		// renderOnlyCustomerSpecificArticle: 'Visa endast kundspecifik artikel',
		// renderOnlyCustomerSpecificCategories: 'Visa endast kundspecifika kategorier',
		// availableOnlyForSpecificCustomer: 'Gör den här kategorin synlig för endast vissa kunder',
		// customerSpecificCategoryText: 'Synliggör kategorin för enbart specifika kunder',
		renderOnlyCustomerSpecificReferenceField:
			"Visa referensfältet för specifik kund enbart",
		categorySpecificUpdate: "Kundspecifik kategori uppdaterad",
		setCustomer: "Ställ in kund",
		// applyTo: 'Synlighet',
		// applyToCustomerText: 'Specifika kunder enbart',
		allCustomer: "Alla kunder",
		customerSpecific: "Kundspecifik",
		showCustomerResalePrice: "Visa Rekommenderat Konsumentpris",
		consumer_resale_field_title: "Titel för Konsumentprisfältet",
		consumer_resale_price: "Återförsäljningspris för konsument",
		customerSpecificCheckoutText:
			"Synliggör referensfält för enbart specifika kunder",
		renderOnlyCustomerSpecificArticle:
			"Får bara se kundspecifika produkter",
		renderOnlyCustomerSpecificCategories:
			"Får bara se kundspecifika kategorier",
		availableOnlyForSpecificCustomer:
			"Synliggör kategorin för bara vissa kunder",
		// customerSpecificCategoryText: 'Ange vilka kunder som denna kategori är synlig för',
		// customerSpecificCategoryText: 'Ange vilka kunder som denna kategori är synlig för',
		permissionDenied: "Åtkomst nekad",
		productArticleCategoryAssign: "Produktkategorier",
		productEmpty: "Ingen produkt tillgänglig för närvarande",
		searchEmpty: "Din sökning matchar inte någon produkt",
		filterEmpty: "Ditt filter matchar inte någon produkt",
		hide_stock_quantity: "Dölj lagerkvantitet",
		b2bOrderNumber: "B2B ordernummer",
		delete: "Radera",
		self_allowed_create_delivery_addresses:
			"Tillåt kund att skapa egna leveransadresser",
		is_default_address: "Default Adress",
		apply_customer_specific_user_profiles:
			"Använd kundspecifik användarprofil",
		apply_customer_currency_on_orders:
			"Använd kundinställd valuta på order",
		no_company_text: "Välj en butik för att få åtkomst till dessa resurser",
		not_allowed_setting:
			"Din leverantör tillåter inte åtkomst till din leveransadress. Kontakta din leverantör",
		activeFilter: "Aktiva filter",
		removeAllCustomerFromArticle:
			"Är du säker på att ta bort alla kunder från den här artikeln",
		customerSelectionEmpty: "Välj en eller flera kunder att tilldela",
		totalImageToUpload: "Totalt antal bilder att ladda upp",
		all: "Alla",
		blogDataSuccess: "Blogginformationen har uppdaterats",
		articlePdf: "Artikeldokument (pdf)",
		clickUploadPdf: "Klicka för att ladda upp (pdf)",
		pdfSizeText: "pdf-filer med en max storlek på 10mb",
		fileToLarge: "Filen överskrider maxstorlek",
		fileExceedLimit: "Filuppladdning överskrider gränsen",
		adminEmailAddress: "Admin e-postadress",
		send_order_notifications_to_admin:
			"Skicka meddelande till admin vid beställning",
		disable_customer_order_conf_emails:
			"Inaktivera orderbekräftelser till köparen",
		show_standard_price_additional_to_customer_specific_price:
			"Visa standardpris utöver kundspecifikt pris",
		standard_price: "Standard pris",
		relevant: "Relevant",
		assignProductBlog: "Rekommendera produkt",
		searchProduct: "Sök artikel",
		linkedArticle: "Länkade artiklar",
		linkedArticleField: "Välj vilka artiklar som ska länkas till inlägget",
		linkedArticleHelpText:
			"Genom att länka produkter till ditt blogginlägg kommer de att inkluderas och markeras i blogginlägget",
		sendAsNewsLetter: "Skicka som nyhetsbrev via e-post också",
		recommendedProduct: "Rekommenderade produkter",
		recommendedAccessories: "Rekommendera tillbehör",
		accessories: "Tillbehör",
		customerInviteReminder: "Skicka inbjudan påminnelser till kund",
		customerInviteReminderHelpText:
			"Ställ in om du vill att din kund får påminnelser via email om de ännu inte har accepterat inbjudan",
		once_in_a_week: "En gång i veckan",
		twice_in_a_week: "Två gånger i veckan",
		three_time_in_a_week: "Tre gånger i veckan",
		dont_send_reminder: "Skicka inte påminnelse",
		profit_margin: "Vinstmarginal",
		totalSubscription: "Totalt abonnemang",
		totalSupplier: "Total leverantör",
		totalBuyer: "Totala köpare",
		totalUser: "Totalt antal användare",
		logo: "Logotyp",
		buyers: "Köpare",
		companies: "Företag",
		notConnectedToCompany: "Inte kopplad till något företag",
		settingUpAccount: "Vänligen vänta... Konfigurerar konto",
		accessBuyerDashboard: "Gå till kundens vy",
		permissionDeniedResource:
			"Du har för närvarande inte behörighet att komma åt denna sida",
		allow_seller_place_order:
			"Tillåt säljaren att göra en beställning för din räkning",
		settingUpdated: "Inställningen har uppdaterats",
		editDeliveryAddress: "Uppdatera leveransadress",
		productDownload: "Ladda ner Produktlista",
		orderHelpText: "Redigera beställning",
		orderManagement: "Orderhantering",
		deliveryDate: "Leveransdatum",
		updateOrder: "Uppdatera beställning",
		orderDetail: "Beställningsinformation",
		orderRows: "Beställ rader",
		customerDetail: "Kunddetaljer",
		deliveryInformation: "Leveransinformation",
		invoiceType: "Faktura",
		createOrder: "Skapa order",
		account: "Konto",
		vatIncluded: "Moms ingår",
		yourOrderNumber: "Ditt beställningsnummer",
		// yourReference: 'Er referens',
		paymentTerms: "Betalningsvillkor",
		priceList: "Prislista",
		currency: "Valuta",
		project: "Projekt",
		vatType: "Typ av moms",
		emptyRows: "Order/faktura måste innehålla en eller flera rader",
		cancelOrderInvoiceErrorText:
			"Annullerad order/faktura kan inte uppdateras",
		cancelOderInvoiceHelpText: "Avbryt beställning eller faktura",
		cancelSuccessful: "Avbröts framgångsrikt",
		unittypes: "Enhetstyper",
		addNewUnitType: "Skapa enhetstyper",
		unit: "Enhet",
		updateUnitType: "Uppdatera enhetstyp",
		removeUnitType: "Ta bort enhetstyp",
		unitUpdated: "Enheten har uppdaterats",
		unitCreated: "Enheten har skapats",
		productInUnit: "Produkten säljs som ett paket",
		productPackageText: "Hur många enheter ska gälla per paket",
		productPdf: "Produkinformationsblad",
		blogVisibilty: "Synlighet",
		generalSetting: "Allmän inställning",
		stockManagement: "Lagerhantering",
		notifications: "Aviseringar",
		invalidDimension: "Ogiltig dimension för den uppladdade bilden",
		generalMaxCreditError: "Max kredit måste vara större än 0",
		adminEmailAddressError: "Admin e-postadress krävs",
		customEmailRuleHelpText:
			"Välj om du vill att kunden ska påminnas om inbjudan efter intiala inbjudan är skickad",
		sendEmailToAdminHelpText:
			"Bocka för om du vill motta en email avisering varje gång din kund lägger en order",
		dontSendOrderConfirmationToBuyerText:
			"Bocka för om du vill att kunden inte ska motta en orderbekräftelse via email",
		allow_seller_to_see_your_orders:
			"Tillåt säljaren att se sina beställningar",
		othersInformation: "Annan information",
		productRecommendations: "Produktrekommendationer",
		priceInformation: "Prisinformation",
		productInformation: "Produktinformation",
		newsletterDate: "Datum för att skicka nyhetsbrev",
		openBlogRecommendedProduct: "Länkad produkt",
		searchArticles: "Sök artikel",
		previewEmail: "Förhandsvisa Email",
		profitMargin: "Vinstmarginal",
		sellerAllowedToPlaceOrder:
			"Grossisten får behörighet att lägga ordrar för vår räkning",
		sellerAllowedToSeeOrder:
			"Grossisten får behörighet att se vår ordrerhistorik",
		stockThresholdQuantity: "Visa lageraldo som noll vid denna saldogräns",
		notification: "Aviseringar",
		selectRule: "Ställ in intervall",
		invoice_: "Faktura",
		estimatedTime: "Beräknad uppladdningstid:",
		outOfStock: "Produkten finns ej i lager",
		textSchedule: "Nyhetsbrev utskick skedde: ",
		sync_only_webshop_articles: "Synka bara webbshops artiklar",
		connect: "Anslut",
		stockpoint: "Lagerställe",
		allow_seller_send_employee_invite:
			"Tillåt säljaren att skicka inbjudan till anställda",
		articleVariations: "Artikelvariationer",
		variations: "Variationer",
		readNotification: "Lästa aviseringar",
		unreadNotification: "Olästa aviseringar",
		notificationEmpty: "Inga aviseringar",
		microsoft: "Microsoft 365",
		other_mail_server: "Andra e-postserver",
		mail_server: "Mejl server",
		createSuccess: "Skapat",
		createOrderOrInvoiceCopyText: "Vill du skapa en kopia av denna",
		createArticle: "Skapa artikel",
		deleteSuccess: "Raderat",
		deleteArticle: "Ta bort artikel",
		createCustomer: "Skapa kund",
		otherInformation: "Annan information",
		customerInformation: "Kundinformation",
		updateCustomer: "Uppdatera kund",
		customerAddress: "Faktura adress",
		total: "Totalt",
		vat: "Varav moms",
		copyOrder: "Kopiera Order",
		copyInvoice: "Kopiera Faktura",
		loading: "Laddar info",
		editCustomer: "Redigera kund",
		revenue: "Intäkter",
		hideDetails: "Dölj detaljer",
		whiteLabelSection: {
			fullImageTextCaption: "Maxdimensioner 200 * 200. Filformat .jpeg",
			halfImageTextCaption: "Maxdimensioner 100 * 100. Filformat .jpeg",
			favIconTextCaption: "Maxdimensioner 16 * 16. Filformat .jpeg",
			favIcon: "Favicon",
		},
		productDetail: "Produktdetaljer",
		hide_sales_price: "Dölj inköpspris",
		hide_delivery_time: "Dölj leveranstid",
		hide_discounted_price: "Dölj rabatter",
		hide_recommended_price: "Dölj rek konsumentpris",
		hideDetailsHelpText:
			"Här kan du styra och dölja viss produktinformation på produktsidan. För att styra vilken info, gå till inställningar sidan. Default kommer att dölja priser enbart.",
		pinAsTopPost: "Ställ in som huvudinlägg",
		bannerBackgroundColor: "Banner Bakgrundsfärg",
		toWebshop: "Till Webbshop",
		buyer_sidebar_menu_colorHelpText:
			"Här kan ni ställa in vilken färg sidomenyn ska ha för era kunder",
		buyer_sidebar_menu_color: "Sidmeny för ÅF vy",
		documentDownloadInProgress: "Underlaget laddas ner",
		address: "Leveransadresser",
		pinCategory:
			"Vill du sätta produkter i kategori {category_name} som default i produktöversiktssidan?",
		unPinCategory:
			"Vill du plocka bort produkter i kategori {category_name} som default i produktöversiktssidan?",
		recommended: "Rekommenderad",
		clearCategoryFilter: "Nollställ",
		authorize_microsoft_365: "Autentisera Microsoft 365 appen",
		sync_only_corporate_customers:
			"Synka bara Företagskunder från ekonomiprogrammet",
		import_all_orders_from_accounting:
			"Importera samtliga ordrar från ekonomiprogrammet",
		import_all_orders_from_start_date:
			"Import ordrar från ekonomiprogrammet från detta datum",
		viewAll: "Visa alla",
		// published: 'Publiceras',
		draft: "Utkast",
		categoryUpdated: "Kategorin är uppdaterad",
		notificationUpdated: "Aviseringar är uppdaterad",
		favIconText: "",
		blogPostUpdated: "Uppdaterat",
		unpinBlog: "Blogginläggen plockas bort som huvudinlägg nu",
		productImages: "Bilder",
		delivered: "SLUTLEVERERADE",
		processing: "ICKE LEVERERADE",
		myProfile: "Min Profil",
		buyerDetails: "Kunddetaljer",
		orderItems: "Orderrader",
		notificationNowMarkAsRead: "Notifiering är markerad som läst",
		savedAddress: "Sparade Leveransadresser",
		videoGuides: "Video guider",
		noSubscriptionSelected: "Vänligen välj en prenumerationstyp",
		backToFortnoxConnect:
			"Något gick fel, vänligen återanslut igen till Fortnox",
		integrations: "Integrationer",
		subscriptionNotSelectedByCustomer:
			"Det gick inte att gå vidare eftersom prenumeration är inte vald. Vänligen navigera tillbaka och välj ett paket.",
		yet_to_connect_to_integration:
			"Bolaget är inte anslutet till ett integration ännu, vänligen klicka nedan för att komma igång",
		getStarted: "Anslut",
		productVariations: "Varianter",
		"status": {
			"processing": "Behandles",
			"received": "Mottatt",
			"delivered": "Levert"
			}
	},
};

const i18n = new VueI18n({
	locale: "se", // set locale
	fallbackLocale: "se", // set fallback locale
	messages, // set locale messages
});

export default i18n;
